import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'gpe-link-inline',
	templateUrl: './link-inline.component.html',
	styleUrls: ['./link-inline.component.scss'],
})
export class LinkInlineComponent implements OnInit {
	@Input() link: string | undefined;
	@Input() queryParams: { [key: string]: any } | undefined;
	@Input() anchorFragment: string | undefined;

	constructor() {}

	ngOnInit(): void {}
}
