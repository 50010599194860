import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { IChatMessage } from 'src/app/core/models/firebasechat/IChatMessage';
import { IProfile } from 'src/app/core/models/IProfile';
import { BlockUserService } from 'src/app/core/services/block-user.service';
import { UserService } from 'src/app/core/services/user.service';

import { Room } from '../models/room.dto';
import { RoomViewModel } from '../models/room.view-model';

import { FirebaseRoomService } from './firebase-room.service';

@Injectable({
	providedIn: 'root',
})
export class RoomService {
	constructor(
		private firebaseRoomService: FirebaseRoomService,
		private userService: UserService,
		private blockUserService: BlockUserService,
	) {
		this.firebaseRoomService.initUser();
		this.loadAllRooms();
		this.loadFavoriteRooms();
		this.loadOwnedRooms();
	}

	private selectedRoomId$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
	private selectedRoom$: Subject<Room> = new Subject<Room>();
	private usersForMessages$: BehaviorSubject<IProfile[]> = new BehaviorSubject<IProfile[]>([]);
	private recommendedRooms$ = new BehaviorSubject<RoomViewModel[] | null>(null);

	private allRooms$ = new BehaviorSubject<RoomViewModel[] | null>(null);
	private favoriteRooms$ = new BehaviorSubject<RoomViewModel[] | null>(null);
	private ownedRooms$ = new BehaviorSubject<RoomViewModel[] | null>(null);
	private roomsSearchTerm$ = new BehaviorSubject<string>('');

	public get selectedRoomId(): Observable<string | undefined> {
		return this.selectedRoomId$.asObservable();
	}

	public get selectedRoom(): Observable<Room> {
		return this.selectedRoom$.asObservable();
	}

	public get usersForMessages(): Observable<IProfile[]> {
		return this.usersForMessages$.asObservable();
	}

	public selectRoom(roomId: string): void {
		if (this.selectedRoomId$.value !== roomId) {
			this.selectedRoomId$.next(roomId);
			if (roomId) {
				this.getRoom(roomId).subscribe((room) => {
					this.selectedRoom$.next(room);
				});
			}
		}
	}

	public updateUsersForMessages(users: IProfile[]): void {
		this.usersForMessages$.next(users);
	}

	public getRoom(roomId: string): Observable<Room> {
		return this.firebaseRoomService.getRoom(roomId).pipe(
			switchMap((room) => this.getUsersForRoom(room)),
			switchMap((room) => this.filterBlockedUsersFromRoom(room)),
		);
	}

	private getUsersForRoom(room: Room): Observable<Room> {
		return this.userService.getUserBatch(room.userIds).pipe(
			map((result) => {
				room.usersInRoom = result?.map((r) => r?.user ?? []).reduce((acc, val) => acc.concat(val), []);
				return room;
			}),
		);
	}

	public getMessages(roomId: string, limit: number): Observable<IChatMessage[]> {
		return this.firebaseRoomService
			.getMessagesSnapShot(roomId, limit)
			.pipe(switchMap((messages) => this.getUsersForMessages(messages)));
	}

	private getUsersForMessages(messages: IChatMessage[]): Observable<IChatMessage[]> {
		const ids = [...new Set(messages.map((m) => m.userId))];
		if (!ids.some((newId) => !this.usersForMessages$.value.map((u) => u.user_id).includes(newId))) {
			return of(messages);
		} else {
			return this.userService.getUserBatch(ids).pipe(
				tap((users) => this.updateUsersForMessages(users.map((user) => user.user[0]))),
				map(() => messages),
			);
		}
	}

	public getRecommendedRooms(): Observable<RoomViewModel[] | null> {
		return this.recommendedRooms$.asObservable().pipe();
	}

	public loadRecommendedRooms(): Observable<RoomViewModel[]> {
		const size = 12;
		return this.firebaseRoomService.getRecommendedRooms(size).pipe(
			map((rooms) => rooms.map((r) => this.mapRoomtoRoomViewModel(r))),
			tap((rooms) => this.recommendedRooms$.next(rooms)),
		);
	}

	public searChInAllRooms(searchTerm: string): Observable<RoomViewModel[]> {
		return this.firebaseRoomService.searchRooms(searchTerm).pipe(
			map((rooms) => rooms.map((room) => this.mapRoomtoRoomViewModel(room))),
			tap((rooms) => this.recommendedRooms$.next(this.distinctRooms(rooms, this.recommendedRooms$.value ?? []))),
		);
	}

	public setRoomSeacrhTerm(searchTerm: string): void {
		this.roomsSearchTerm$.next(searchTerm);
	}

	public getAllRooms(): Observable<RoomViewModel[] | null> {
		return this.roomsSearchTerm$.asObservable().pipe(
			debounceTime(500),
			switchMap((searchTerm) => {
				return this.allRooms$
					.asObservable()
					.pipe(
						map(
							(rooms) =>
								rooms?.filter((room) => room.name.includes(searchTerm ?? '')).slice(0, 5) ?? null,
						),
					);
			}),
		);
	}

	private loadAllRooms(searchTerm?: string): void {
		this.firebaseRoomService
			.getAllRooms()
			.pipe(
				map((rooms) =>
					rooms
						.map((r) => this.mapRoomtoRoomViewModel(r))
						.filter((room) => room.name.includes(searchTerm ?? '')),
				),
				tap((rooms) => this.allRooms$.next(rooms)),
			)
			.subscribe();
	}

	public getFavoriteRooms(): Observable<RoomViewModel[] | null> {
		return this.roomsSearchTerm$.asObservable().pipe(
			debounceTime(500),
			switchMap((searchTerm) => {
				return this.favoriteRooms$
					.asObservable()
					.pipe(map((rooms) => rooms?.filter((room) => room.name.includes(searchTerm ?? '')) ?? null));
			}),
		);
	}

	public loadFavoriteRooms(searchTerm?: string): void {
		this.firebaseRoomService
			.getFavoriteRooms()
			.pipe(
				map((rooms) =>
					rooms
						.map((r) => this.mapRoomtoRoomViewModel(r))
						.filter((room) => room.name.includes(searchTerm ?? '')),
				),
				tap((rooms) => this.favoriteRooms$.next(rooms)),
			)
			.subscribe();
	}

	public getOwnedRooms(): Observable<RoomViewModel[] | null> {
		return this.roomsSearchTerm$.asObservable().pipe(
			debounceTime(500),
			switchMap((searchTerm) => {
				return this.ownedRooms$
					.asObservable()
					.pipe(map((rooms) => rooms?.filter((room) => room.name.includes(searchTerm ?? '')) ?? null));
			}),
		);
	}

	public loadOwnedRooms(searchTerm?: string): void {
		this.firebaseRoomService
			.getOwnedRooms()
			.pipe(
				map((rooms) =>
					rooms
						.map((r) => this.mapRoomtoRoomViewModel(r))
						.filter((room) => room.name.includes(searchTerm ?? '')),
				),
				tap((rooms) => this.ownedRooms$.next(rooms)),
			)
			.subscribe();
	}

	public updateRoom(room: RoomViewModel): Promise<void> {
		return this.firebaseRoomService.updateRoom(room);
	}

	public deleteRoom(roomId: string): Promise<void> {
		return this.firebaseRoomService.deleteRoom(roomId);
	}

	public async addRoomToInterestedGroup(id: string, add = true): Promise<void> {
		return await this.firebaseRoomService.markRoomInterested(id, add);
	}

	public async addRoomToFavoriteGroup(id: string, add = true): Promise<void> {
		return await this.firebaseRoomService.markRoomFavorite(id, add);
	}

	public getMessagesSnapShot(roomId: string, limit: number): Observable<IChatMessage[]> {
		return this.firebaseRoomService
			.getMessagesSnapShot(roomId, limit)
			.pipe(switchMap((messages) => this.addBlockedMessagesFlairOnChat(messages)));
	}

	private addBlockedMessagesFlairOnChat(messages: IChatMessage[]): Observable<IChatMessage[]> {
		return this.blockUserService.getBlockedUserIds().pipe(
			map((blockedUsers) =>
				messages.map((message) => ({
					...message,
					isBlocked: blockedUsers.includes(message.userId),
				})),
			),
		);
	}

	private filterBlockedUsersFromRoom(room: Room): Observable<Room> {
		return this.blockUserService.getBlockedUserIds().pipe(
			map((blockedUserIds) => ({
				...room,
				usersInRoom: room.usersInRoom?.filter((userInRoomId) => !blockedUserIds.includes(userInRoomId.user_id)),
			})),
		);
	}

	private distinctRooms(newRooms: RoomViewModel[], currentRooms: RoomViewModel[]): RoomViewModel[] {
		const distinct = newRooms.filter(
			(newRoom) => !currentRooms?.some((currentRoom) => currentRoom.id === newRoom.id),
		);
		const remove = currentRooms.filter(
			(currentRoom) => !newRooms?.some((newRoom) => currentRoom.id === newRoom.id),
		);

		return currentRooms
			?.concat(distinct)
			.filter((newRoom) => !remove.some((currentRoom) => currentRoom.id === newRoom.id));
	}

	private mapRoomtoRoomViewModel(room: Room): RoomViewModel {
		return {
			id: room.id,
			coverUrl: room.coverUrl ?? '',
			name: room.name,
			isPrivate: room.isPrivate,
			onlinePeople: room.userIdsLength,
			isInterested: room.isInterested,
			isFavorite: room.isFavorite,
			isOwned: room.isOwned,
			description: room.description,
			invitedUserIds: room.invitedUserIds,
			isSelected: room.isSelected,
		};
	}
}
