import {
	HttpEvent,
	HttpEventType,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { FirebaseService } from '../../modules/chat/services/firebase.service';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private authenticationService: AuthService,
		private firebaseService: FirebaseService,
		private authService: AuthService,
		private router: Router,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		//console.warn('ConvertInterceptor');

		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					if (event.body.error && event.body.error.length > 0) {
						setTimeout(async () => {
							for (const err of event.body.error) {
								await Swal.fire('Hiba történt', err, 'error');
							}
						}, 10);
					}
				}

				return event;
			}),
			catchError((err) => {
				if (err.status === 401) {
					this.authService.clearToken();
					this.router.navigate(['auth/login']);
					this.firebaseService.logout();
					// this.authenticationService.logout().subscribe(
					// 	(v) => {},
					// 	() => {
					// 		this.authService.clearToken();
					// 		this.router.navigate(['/login']);
					// 		this.firebaseService.logout();
					// 	},
					// 	() => {
					// 		setTimeout(() => {
					// 			this.router.navigate(['/login']);
					// 			this.firebaseService.logout();
					// 		}, 500);
					// 	},
					// );
				}

				const error = err.error.message || err.statusText;
				return throwError(error);
			}),
		);
	}
}
