import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MediaUsageType } from 'src/app/core/models/MediaUsageType';
import { MediaService } from 'src/app/core/services/media.service';
import { PostMediaService } from 'src/app/core/services/post-media.service';
import { CompanyService } from 'src/app/modules/company/company.service';
import { CreateMedia } from 'src/app/shared/models/CreateMedia';
import { FileHandle } from 'src/app/shared/models/FileHandle';

import { CompanyData, COMPANYSIZES, COMPANYTYPES, INDUSTRIES } from './create-company-model';

@Component({
	selector: 'gpe-create-company-dialog',
	templateUrl: './create-company-dialog.component.html',
	styleUrls: ['./create-company-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCompanyDialogComponent {
	@Input() modalRef: any;

	public industries: Observable<CompanyData[]> = of(INDUSTRIES);
	public companySizes: Observable<CompanyData[]> = of(COMPANYSIZES);
	public companyTypes: Observable<CompanyData[]> = of(COMPANYTYPES);

	public profileFile: FileHandle | undefined;
	public coverFile: FileHandle | undefined;

	public pageCount = 0;

	public createCompanyForm = new FormGroup({
		name: new FormControl('', Validators.required),
		location: new FormControl('', Validators.required),
		industry: new FormControl(null, Validators.required),
		size: new FormControl(null, Validators.required),
		url: new FormControl(''),
		gpeUrl: new FormControl(''),
		type: new FormControl(null, Validators.required),
		bio: new FormControl(''),
		story: new FormControl(''),
	});

	constructor(
		private readonly companyService: CompanyService,
		private readonly postMediaService: PostMediaService,
		private readonly mediaService: MediaService,
	) {
		this.createCompanyForm.valueChanges.subscribe(() => {
			console.log('profileFile: ', this.profileFile);
		});
	}

	closeModal(): void {
		this.modalRef.close();
	}

	changePage(): void {
		if (this.pageCount === 0) {
			this.pageCount++;
		} else {
			this.pageCount = 0;
		}
	}

	public async createCompany(): Promise<void> {
		if (this.createCompanyForm.valid) {
			if (this.profileFile) {
				const profileMedia: CreateMedia = {
					file: this.profileFile,
					usage_type: MediaUsageType.Company,
				};
				let profileMediaId = 0;
				try {
					profileMediaId = await this.postMediaService.uploadMediaToPost(0, profileMedia);
				} catch {}

				if (this.coverFile) {
					const coverMedia: CreateMedia = {
						file: this.coverFile,
						usage_type: MediaUsageType.Company,
					};
					let coverMediaId = 0;
					try {
						coverMediaId = await this.postMediaService.uploadMediaToPost(0, coverMedia);
					} catch {}

					forkJoin([
						this.mediaService.getMediaUrl(profileMediaId),
						this.mediaService.getMediaUrl(coverMediaId),
					])
						.pipe(
							switchMap(([profileFileId, coverFileId]) =>
								this.companyService.createCompany({
									...this.createCompanyForm.value,
									profilPicId: profileFileId,
									coverPicId: coverFileId,
								}),
							),
						)
						.subscribe();
				} else {
					this.mediaService
						.getMediaUrl(profileMediaId)
						.pipe(
							switchMap((profileFileId) =>
								this.companyService.createCompany({
									...this.createCompanyForm.value,
									profilPicId: profileFileId,
								}),
							),
						)
						.subscribe();
				}
			} else if (this.coverFile) {
				const coverMedia: CreateMedia = {
					file: this.coverFile,
					usage_type: MediaUsageType.Company,
				};
				let coverMediaId = 0;
				try {
					coverMediaId = await this.postMediaService.uploadMediaToPost(0, coverMedia);
				} catch {}
				this.mediaService
					.getMediaUrl(coverMediaId)
					.pipe(
						switchMap((coverFileId) =>
							this.companyService.createCompany({
								...this.createCompanyForm.value,
								coverPicId: coverFileId,
							}),
						),
					)
					.subscribe();
			} else {
				this.companyService.createCompany(this.createCompanyForm.value).subscribe();
			}
		}
	}
}
