<div class="gpe-modal-title">
	<div class="title-text">
		{{ title }}
	</div>
	<div class="title-btn">
		<img src="assets/icons/close-gray.svg" (click)="closeModal()" />
	</div>
</div>
<div
	class="scrollable-container"
	infiniteScroll
	[scrollWindow]="false"
	[infiniteScrollDistance]="2"
	[infiniteScrollThrottle]="10"
	(scrolled)="onProfileListUp()"
>
	<div class="members-list" *ngFor="let member of joinedMembers">
		<div class="d-flex align-items-center">
			<gpe-profile-picture [pictureUrl]="member.avatar"></gpe-profile-picture>
			<div class="name">
				{{ member.name }}
			</div>
		</div>

		<div class="actions">
			<img
				class="navigate-btn cursor-pointer"
				src="assets/icons/arrow-right-blue.svg"
				(click)="navigateToProfile(member.id)"
			/>
		</div>
	</div>

	<div *ngIf="!joinedMembers" class="text-center mt-2">
		<div class="loading-animation-container">
			<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
		</div>
	</div>
</div>
