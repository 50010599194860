import { UtilityService } from './../../services/utility.service';
import { StaticPageService } from './../../../modules/pages/services/static-page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { UserService } from 'src/app/core/services/user.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
	selector: 'gpe-report-problem',
	templateUrl: './report-problem.component.html',
	styleUrls: ['./report-problem.component.scss'],
})
export class ReportProblemComponent implements OnInit {
	page_id = 0;
	is_loading = false;
	myUserId: number = 0;

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	constructor(
		private userService: UserService,
		private _Activatedroute: ActivatedRoute,
		private staticPageService: StaticPageService,
		private router: Router,
		private utilityService: UtilityService,
	) {}

	subject = '';
	where = '';
	description = '';

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();
	}

	send(): void {
		this.is_loading = true;
		this.utilityService.reportProblem(this.subject, this.where, this.description).subscribe((val) => {
			this.subject = '';
			this.where = '';
			this.description = '';
			Swal.fire({
				icon: 'success',
				text: 'You have successfully submitted!',
			});
			this.is_loading = false;
		});
	}

	pageLoadError() {
		this.is_loading = false;
	}
}
