import 'firebase/auth';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

import { ILoginCompanyTokens, ILoginResponse } from '../../modules/authentication/models/ILoginResponse';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { IUserType } from '../models/IUserBase';
import { IUserRegisterStatus, IUserRegisterStatusGet } from '../models/IUserRegisterStatus';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private currentUserSubject: BehaviorSubject<User>;
	private storage: Storage = localStorage;
	public currentUser: Observable<User>;

	private currentCompaniesSubject: BehaviorSubject<ILoginCompanyTokens>;

	private apiUrl = environment.apiURL;

	constructor(private http: HttpClient) {
		if (sessionStorage.getItem('currentUser')) {
			this.storage = sessionStorage;
		}

		this.currentUserSubject = new BehaviorSubject<User>(
			JSON.parse(this.storage.getItem('currentUser') ?? sessionStorage.getItem('currentUser')!),
		);
		this.currentUser = this.currentUserSubject.asObservable();

		const storedCompany = JSON.parse(
			this.storage.getItem('currentCompanies') ?? sessionStorage.getItem('currentCompanies')!,
		) as ILoginCompanyTokens;
		const t: ILoginCompanyTokens = {
			mainProfile: storedCompany && storedCompany.mainProfile ? storedCompany.mainProfile : undefined,
			tokens: [],
		};
		this.currentCompaniesSubject = new BehaviorSubject<ILoginCompanyTokens>(t);
	}

	public get currentCompanies(): Observable<ILoginCompanyTokens> {
		return this.currentCompaniesSubject.asObservable();
	}

	public initialize = () => {
		return new Promise<boolean>((resolve) => {
			const storedCompany = JSON.parse(
				this.storage.getItem('currentCompanies') ?? sessionStorage.getItem('currentCompanies')!,
			) as ILoginCompanyTokens;
			if (
				this.currentUserSubject &&
				this.currentUserSubject.value &&
				this.currentUserSubject.value.profile &&
				this.currentUserSubject.value.profile.user_type
			) {
				if (this.currentUserSubject.value.profile.user_type == IUserType.USER) {
					this.refreshCompanies().then((val) => {
						//...
					});
				} else {
					this.currentCompaniesSubject.next(storedCompany);
				}
			}
			resolve(true);
		});
	};

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	login(user: FormData): Observable<any> {
		return this.http.post<ApiResponse<ILoginResponse>>(`${this.apiUrl}/auth/login`, user).pipe(
			map(async (res) => {
				if (res.success) {
					if (user.get('remember') !== 'true') {
						this.storage = sessionStorage;
					}
					if (res.data && res.data.usertoken) {
						this.storage.setItem('currentUser', JSON.stringify(res.data));
						this.currentUserSubject.next(res.data);

						this.getMyCompanyTokens().subscribe((val) => {
							val.mainProfile = res.data;
							this.storage.setItem('currentCompanies', JSON.stringify(val));
							this.currentCompaniesSubject.next(val);
						});
					}
					return res.data;
				} else {
					for (const error of res.error) {
						await new Promise((resolve) => {
							Swal.fire({
								title: 'Error happened',
								text: error.toString(),
								icon: 'error',
								showCloseButton: true,
							}).then((value) => {
								resolve(1);
							});
						});
					}
				}
				return null;
			}),
		);
	}

	public changeProfile(login: ILoginResponse) {
		this.storage.setItem('currentUser', JSON.stringify(login));
		window.location.reload();
	}

	refreshCompanies(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (this.currentUserSubject.value.profile.user_type == IUserType.USER) {
				// this.logoutCompanies();
				this.getMyCompanyTokens().subscribe(
					(val) => {
						val.mainProfile = this.currentCompaniesSubject.value.mainProfile;
						this.storage.setItem('currentCompanies', JSON.stringify(val));
						this.currentCompaniesSubject.next(val);
						resolve(true);
					},
					(err) => {
						reject(false);
					},
				);
			} else {
				reject(false);
			}
		});
	}
	logoutCompanies() {
		const storedCompany = JSON.parse(this.storage.getItem('currentCompanies')!) as ILoginCompanyTokens;
		if (storedCompany && storedCompany.tokens) {
			for (const token of storedCompany.tokens) {
				if (token.usertoken) {
					this.logoutCompany(token.usertoken).subscribe((val) => {});
				}
			}
		}

		this.storage.removeItem('currentCompanies');
	}
	signup(user: any) {
		return this.http.post(`${this.apiUrl}/auth/register`, user).pipe(
			map((res) => {
				return res;
			}),
		);
	}

	logout(): Observable<any> {
		return new Observable((sub) => {
			const myHeaders = new HttpHeaders();
			myHeaders.append('Authorization', `Bearer${this.currentUserValue.usertoken}`);
			this.http
				.get(`${this.apiUrl}/auth/logout`, { headers: myHeaders })
				.pipe(
					map((res) => {
						return res;
					}),
				)
				.subscribe(
					(val) => {},
					(err) => {},
					() => {
						this.clearToken();
						this.logoutCompanies();
					},
				);
			sub.complete();
		});
	}

	clearToken() {
		this.storage.removeItem('currentUser');

		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.storage.getItem('currentUser')!));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	logoutCompany(token: string) {
		const inn = environment.apiKey + '|' + token;

		return this.http.get(`${this.apiUrl}/auth/logout`, { headers: { Authorization: `Bearer ${inn}` } });
	}

	resetPassword(passwordResetEmail: any) {
		return this.http.post(`${this.apiUrl}/auth/lostpassword`, passwordResetEmail);
	}

	checkEmailUnique(email: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const obj = {
				email: email,
			};
			const res = this.http.post<ApiResponse<boolean>>(`${this.apiUrl}/auth/check_email`, obj).pipe(
				map((_res) => {
					return _res;
				}),
			);
			return res.subscribe(
				(_val) => {
					resolve(_val.success == 1);
				},
				(error) => {
					reject(false);
				},
			);
		});
	}

	checkUserRegister(
		token_facebook: string,
		token_google: string,
		token_apple: string,
	): Observable<IUserRegisterStatus> {
		const obj = new FormData();
		obj.append('token_facebook', token_facebook);
		obj.append('token_google', token_google);
		obj.append('token_apple', '0');
		obj.append('token_apple_web', token_apple);

		return this.http.post<ApiResponse<IUserRegisterStatusGet>>(`${this.apiUrl}/auth/checkUserRegister`, obj).pipe(
			map((res) => {
				return res.data.status;
			}),
		);
	}

	getMyCompanyTokens(): Observable<ILoginCompanyTokens> {
		const url = `${this.apiUrl}/company/my_company_tokens`;

		return this.http.get<ApiResponse<ILoginCompanyTokens>>(url).pipe(
			map((res) => {
				return res.data;
			}),
		);
	}

	public canUseCompanyManagement(): Observable<boolean> {
		const url = `${this.apiUrl}/company-management/canuse/general`;

		return this.http.get<ApiResponse<{ companyManagement: boolean; charityCampaigns: boolean }>>(url).pipe(
			map((res) => {
				return res.data.companyManagement;
			}),
		);
	}
}
