import { ICityItemResult } from './../models/ICity';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { IApiVersion } from '../models/IApiVersion';
import { ICity, ICityResult } from '../models/ICity';
import { ICountry, ICountryGet } from '../models/ICountry';
import { IWebappCanuse } from '../models/IWebappCanuse';

@Injectable({
	providedIn: 'root',
})
export class UtilityService {
	private apiUrl = environment.apiURL;
	constructor(private http: HttpClient) {}

	getCountryBySearch(searchStr: string): Observable<ICountry[]> {
		const searchEncoded = encodeURIComponent(searchStr);

		const url = `${this.apiUrl}/utility/country?search=${searchEncoded}`;

		return this.http.get<ApiResponse<ICountryGet>>(url).pipe(
			map((res) => {
				return res.data.country;
			}),
		);
	}

	getCityById(id: number): Observable<ICity> {
		const url = `${this.apiUrl}/utility/city/${id}`;

		return this.http.get<ApiResponse<ICityItemResult>>(url).pipe(
			map((res) => {
				return res.data.cities;
			}),
		);
	}
	getCityBySearch(searchStr: string): Observable<ICity[]> {
		const searchEncoded = encodeURIComponent(searchStr);

		const url = `${this.apiUrl}/utility/city?search=${searchEncoded}`;

		return this.http.get<ApiResponse<ICityResult>>(url).pipe(
			map((res) => {
				return res.data.city;
			}),
		);
	}
	getVersion(): Observable<IApiVersion> {
		const url = `${this.apiUrl}/version`;

		return this.http.get<ApiResponse<IApiVersion>>(url).pipe(
			map((res) => {
				return res.data;
			}),
		);
	}

	getCanUse(): Observable<boolean> {
		const url = `${this.apiUrl}/webapp/canuse`;

		return this.http.get<ApiResponse<IWebappCanuse>>(url).pipe(
			map((res) => {
				return res.data.canUse;
			}),
		);
	}

	reportProblem(subject: string, where: string, description: string): Observable<Boolean> {
		const url = `${this.apiUrl}/report/problem`;

		const data = new FormData();
		data.append('subject', subject);
		data.append('where', where);
		data.append('description', description);

		return this.http.post<ApiResponse<Boolean>>(url, data).pipe(
			map((res) => {
				return res.data;
			}),
		);
	}

	suggestIdea(subject: string, description: string): Observable<Boolean> {
		const url = `${this.apiUrl}/report/suggest_idea`;

		const data = new FormData();
		data.append('subject', subject);
		data.append('description', description);

		return this.http.post<ApiResponse<Boolean>>(url, data).pipe(
			map((res) => {
				return res.data;
			}),
		);
	}
}
