import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IProfile } from 'src/app/core/models/IProfile';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'gpe-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
	constructor(private router: Router) {}

	@Input() usersInRoom: IProfile[] = [];

	navigateToUser(userId: number): void {
		this.router.navigate(['/profile/' + userId]);
	}
}
