import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth.service';

import { RequestOptions } from './api.models';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(
		private client: HttpClient,
		private router: Router,
		private authService: AuthService,
		// private notificationService: NotificationService,
		private translateService: TranslateService,
	) {}

	public get<T>(url: string, options?: RequestOptions): Observable<T> {
		const headers = { ...options?.headers, Accept: 'application/json' };
		return this.request('GET', url, { ...options, headers });
	}

	public post<T>(url: string, body: unknown, options?: RequestOptions): Observable<T> {
		const headers = { ...options?.headers, Accept: 'application/json' };
		return this.request('POST', url, { body, ...options, headers });
	}

	public put<T>(url: string, body: unknown, options?: RequestOptions): Observable<T> {
		const headers = { ...options?.headers, Accept: 'application/json' };
		return this.request('PUT', url, { body, ...options, headers });
	}

	public patch<T>(url: string, body: unknown, options?: RequestOptions): Observable<T> {
		const headers = { ...options?.headers, Accept: 'application/json' };
		return this.request<T>('PATCH', url, { body, ...options, headers });
	}

	public delete<T>(url: string, options?: RequestOptions): Observable<T> {
		return this.request<T>('DELETE', url, options);
	}

	//TODO: ErrorMessages
	private request<T>(method: string, url: string, options?: RequestOptions): Observable<T> {
		return this.client.request(method, this.createUrl(url), options).pipe(
			tap(() => {
				if (method !== 'GET' && !options?.skipNotificaton) {
					// this.notificationService.showSuccessToast(
					// 	this.translateService.instant('common.success'),
					// 	options?.customNotificationMessage,
					// );
				}
			}),
			catchError((error: HttpErrorResponse) => {
				switch (error.status) {
					//TODO: 403 hibákat hogyan kezeljük?
					case 401:
						this.authService.clearToken();
						this.router.navigateByUrl('/auth/login');
						break;
					case 403:
						this.router.navigateByUrl('/auth/login');
						break;
					case 404:
						// this.notificationService.showErrorToast(
						// 	this.translateService.instant('common.errors.notFound'),
						// );
						break;
					default:
						break;
				}

				return throwError(error);
			}),
		);
	}

	private createUrl(url: string): string {
		return url.startsWith('http') ? url : new URL(url, `${environment.apiURL}/`).href;
	}
}
