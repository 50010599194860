import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/modules/chat/services/firebase.service';

import { AuthService } from '../../services/auth.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'gpe-settings-sidebar',
	templateUrl: './settings-sidebar.component.html',
	styleUrls: ['./settings-sidebar.component.scss'],
})
export class SettingsSidebarComponent {
	constructor(private authService: AuthService, private router: Router, private firebaseService: FirebaseService) {}

	logout(): void {
		this.authService.logout().subscribe(
			(v) => {},
			(e) => {},
			() => {
				setTimeout(() => {
					this.firebaseService.logout();
					this.router.navigate(['/login']);
				}, 500);
			},
		);
	}
}
