import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'gpe-report-problem-base',
	templateUrl: './report-problem-base.component.html',
	styleUrls: ['./report-problem-base.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportProblemBaseComponent implements OnInit {
	@Input() modalRef: any;

	is_loading = false;
	myUserId = 0;
	subject = '';
	where = '';
	description = '';

	constructor(private utilityService: UtilityService, private userService: UserService) {}

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();
	}

	send(): void {
		this.is_loading = true;
		this.utilityService.reportProblem(this.subject, this.where, this.description).subscribe(() => {
			this.subject = '';
			this.where = '';
			this.description = '';
			Swal.fire({
				icon: 'success',
				text: 'You have successfully submitted!',
			});
			this.is_loading = false;
			this.closeModal();
		});
	}

	closeModal(): void {
		this.modalRef?.close();
	}
}
