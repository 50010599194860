<span *ngIf="actualUserData">
	<ng-template #popContent>
		<div (mouseover)="openTooltip(t1)" (mouseleave)="closeTooltip(t1)" class="popover-container">
			<div class="popover-row">
				<gpe-profile-picture [pictureUrl]="actualUserData.user_avatar" [width]="100"></gpe-profile-picture>

				<span class="user-name">{{ actualUserData.user_first_name }} {{ actualUserData.user_last_name }}</span>
				<ng-container *ngIf="userFollowing">
					<a
						class="btn btn-sm btn-success"
						*ngIf="!actualUserData.is_followed"
						(click)="userFollowing(actualUserData.user_id, true)"
						>Follow</a
					>
					<a
						class="btn btn-sm btn-secondary"
						*ngIf="actualUserData.is_followed"
						(click)="userFollowing(actualUserData.user_id, false)"
						>Unfollow</a
					>
				</ng-container>
			</div>

			<div class="popover-row">
				<button class="btn btn-primary w-100" (click)="messageUser(actualUserData.user_id)">Message</button>
			</div>
		</div>
	</ng-template>

	<a
		class="hover cursor-pointer"
		[ngbPopover]="popContent"
		(mouseover)="openTooltip(t1)"
		(mouseleave)="closeTooltip(t1)"
		(click)="navigateToUser(actualUserData.user_id)"
		#t1="ngbPopover"
		>{{ actualUserData.user_first_name }} {{ actualUserData.user_last_name }}</a
	>
</span>
<span *ngIf="!actualUserData"> @ </span>
