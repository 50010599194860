import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { IProfile, IProfileGet } from '../models/IProfile';

import { ApiService } from './../api/api.service';
import { ICover, ICoverGet } from './../models/ICover';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class UserProfileService {
	private apiUrl = environment.apiURL;
	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private userService: UserService,
		private apiService: ApiService,
	) {}

	getProfile(id?: number): Observable<IProfile> {
		return this.userService.getUser(id ?? 0);
	}

	changeProfile(formData: FormData): Observable<any> {
		return this.http.put<any>(`${this.apiUrl}/user/profile`, formData).pipe(map((res) => {}));
	}
	changeProfilePicture(avatarHref: string | null): Observable<ApiResponse<IProfileGet>> {
		const url = `${this.apiUrl}/user/profile`;

		const body = new FormData();
		if (avatarHref) body.append('user_avatar', avatarHref);

		return this.http.put<ApiResponse<IProfileGet>>(url, body).pipe(
			map((res) => {
				return res;
			}),
		);
	}

	changePassword(formData: FormData): Observable<any> {
		const currentUserValue = this.authService.currentUserValue;
		const myHeaders = new HttpHeaders();

		myHeaders.append('Authorization', `Bearer${currentUserValue.usertoken}`);
		return this.http
			.put<any>(`${this.apiUrl}/user/password`, formData, { headers: myHeaders })
			.pipe(map((res) => {}));
	}

	getCovers(type: number): Observable<ICover[]> {
		const url = 'cover/' + type;

		return this.apiService.get<ApiResponse<ICoverGet>>(url).pipe(
			map((res) => {
				return res.data.covers;
			}),
		);
	}

	setBasicCovers(id: number): Observable<boolean> {
		const url = 'cover/user';
		const body = new FormData();
		body.append('cover_id', id.toString());

		return this.apiService.put<ApiResponse<boolean>>(url, body).pipe(
			map((res) => {
				return res.success == 1;
			}),
		);
	}

	clearProfilePicture(): Observable<ApiResponse<void>> {
		const url = `profile/clear_avatar`;

		return this.apiService.post<ApiResponse<void>>(url, undefined);
	}
}
