<gpe-header></gpe-header>
<div *ngIf="is_loading" class="text-center mt-2">
	<div class="loading-animation-container">
		<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
	</div>
</div>

<div *ngIf="!is_loading">
	<div class="container mb-5">
		<div class="row">
			<gpe-suggest-idea-base class="col pt-5" [class.col-md-8]="this.myUserId > 0"></gpe-suggest-idea-base>
			<div class="col-md-4" *ngIf="this.myUserId > 0">
				<gpe-sidebar-default></gpe-sidebar-default>
			</div>
		</div>
	</div>
</div>
