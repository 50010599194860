import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'gpe-company-management-layout',
	templateUrl: './company-management-layout.component.html',
	styleUrls: ['./company-management-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyManagementLayoutComponent {
	constructor(private readonly router: Router) {}

	public backtoWebsite(): void {
		this.router.navigate(['home']);
	}
}
