import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BlockUserService } from 'src/app/core/services/block-user.service';

import { ApiService } from '../../../core/api/api.service';
import { IChatRoom } from '../../../core/models/firebasechat/IChatRoom';
import { ISidebarChatRoom } from '../../../core/models/firebasechat/ISidebarChatRoom';
import { IProfileMinimal } from '../../../core/models/IProfileMinimal';
import { UserService } from '../../../core/services/user.service';

import { FirebaseService } from './firebase.service';

@Injectable({
	providedIn: 'root',
})
export class ChatRoomService {
	private unreadRoomCount$ = new BehaviorSubject<number>(0);
	private chatRooms$ = new BehaviorSubject<ISidebarChatRoom[]>([]);

	constructor(
		private userService: UserService,
		private firebaseService: FirebaseService,
		private apiService: ApiService,
		private blockUserService: BlockUserService,
	) {}

	private get currentUserId(): number {
		return this.userService.getLoginedUserId();
	}
	private currentlyHighlighted: BehaviorSubject<string> = new BehaviorSubject<string>('-1');

	public getChatRooms(searchTerm = ''): Observable<ISidebarChatRoom[]> {
		return searchTerm.length > 0 ? this.searchUsers(searchTerm) : this.chatRooms$.asObservable();
	}

	public loadChatRooms(limit = 40): Observable<ISidebarChatRoom[]> {
		return this.firebaseService.getChatRoomsSnapShot(limit).pipe(
			map((rooms) =>
				rooms.map((room) =>
					this.getUserForChatRoom(room, +room.userIds.filter((u) => +u != this.currentUserId)[0]),
				),
			),
			switchMap((rooms$) => forkJoin(rooms$)),
			switchMap((rooms) => this.filterBlockedUsersFromISidebarChatRooms(rooms)),
			tap((rooms) => {
				this.chatRooms$.next(rooms);
				this.unreadRoomCount$.next(rooms.filter((room) => room.unreadTextAmount > 0).length);
			}),
		);
	}

	public getUnreadCount(): Observable<number> {
		return this.unreadRoomCount$.asObservable();
	}

	private searchUsers(searchTerm: string): Observable<ISidebarChatRoom[]> {
		return this.userService.searchUser(searchTerm, 1).pipe(
			switchMap((users) => this.getChatRoomsForUsers(users)),
			switchMap((rooms) => this.filterBlockedUsersFromISidebarChatRooms(rooms)),
		);
	}

	public blockUser(userId: string): void {
		const formData = new FormData();
		formData.append('user_id', userId);
		this.apiService.post('user/blocked', formData).subscribe();
	}

	public setHiglighted(ind: string): void {
		this.currentlyHighlighted.next(ind);
	}

	public getHiglighted(): Observable<string> {
		return this.currentlyHighlighted.asObservable();
	}
	// TODO: extend to multiple users
	// TODO: user cache
	private getUserForChatRoom(room: IChatRoom, userId: number): Observable<ISidebarChatRoom> {
		return this.userService.getUser(userId).pipe(
			map((user) => {
				return {
					chatRoom: room,
					userName: `${user.user_first_name} ${user.user_last_name}`,
					profilePictureSource: user.data.user_avatar,
					userId: user.user_id,
					latestText: room.lastMessage_message,
					unreadTextAmount: room.unseenMessages[`user_${this.currentUserId}`],
				} as ISidebarChatRoom;
			}),
		);
	}

	private getChatRoomsForUsers(users: IProfileMinimal[]): Observable<ISidebarChatRoom[]> {
		return this.firebaseService.getChatRooms().pipe(
			map((rooms) => {
				return users.map(
					(u) =>
						({
							chatRoom: rooms.filter((r) => r.userIds.some((uid) => +uid == u.user_id))[0],
							userName: `${u.user_first_name} ${u.user_last_name}`,
							profilePictureSource: u.data.user_avatar,
							userId: u.user_id,
						} as ISidebarChatRoom),
				);
			}),
		);
	}

	private filterBlockedUsersFromISidebarChatRooms(rooms: ISidebarChatRoom[]): Observable<ISidebarChatRoom[]> {
		return this.blockUserService.getBlockedUserIds().pipe(
			map((blockedUsers) => {
				return rooms.filter((room) => !blockedUsers.includes(room.userId));
			}),
		);
	}
}
