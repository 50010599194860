<div class="w-100">
	<form [formGroup]="createRoomForm">
		<div class="d-flex align-items-center justify-content-between">
			<input
				type="text"
				class="form-control input-fields input-text w-50"
				placeholder="Room name"
				formControlName="roomName"
			/>

			<div>
				<input
					type="checkbox"
					class="form-check-input"
					id="public"
					[checked]="!createRoomForm.value.isPrivate"
					(change)="setIsPrivate()"
				/>
				<label for="public">Public room</label><br />
			</div>

			<div>
				<input
					#privateCheckbox
					type="checkbox"
					class="form-check-input"
					id="private"
					formControlName="isPrivate"
				/>
				<label for="private">Private room</label><br />
			</div>
		</div>

		<textarea
			class="form-control mt-2"
			id="description"
			rows="5"
			placeholder="Room description"
			formControlName="roomDescription"
		></textarea>
	</form>

	<div class="private-info" *ngIf="createRoomForm.value.isPrivate">
		<img src="/assets/icons/info.svg" />
		<div class="info-text">
			Csak meghívott emberek léphetnek be a csoportba! Meghívást később is végre hajthatsz miután létrehoztad a
			szobát.
		</div>
	</div>
	<div class="d-flex align-items-center justify-content-between mt-2">
		<button
			type="button"
			class="dropdown-toggle btn btn-block btn-outline-primary room-modal-wide-btn"
			id="dropdownForm6"
			(click)="fileInput.click()"
		>
			<img src="assets/icons/add-photos.svg" /> Add cover
		</button>

		<input
			#fileInput
			type="file"
			name="file"
			(change)="handleFileInput($event)"
			id="file"
			single
			style="display: none"
		/>

		<div ngbDropdown>
			<button
				type="button"
				class="dropdown-toggle btn btn-block btn-outline-primary room-modal-wide-btn"
				id="dropdownForm4"
				ngbDropdownToggle
			>
				<img src="/assets/icons/invite-peoples.svg" /> Invite ({{ invitedSelected.length }})
			</button>
			<div ngbDropdownMenu aria-labelledby="dropdownForm4">
				<gpe-create-post-user-selector
					[getSearch]="invitedSearch"
					[onChanged]="invitedChanged"
					[selectedUsers]="invitedSelected"
					[refNumber]="2"
					[buttonActivateText]="'Add'"
					[buttonDeactivateText]="'Remove'"
				>
				</gpe-create-post-user-selector>
			</div>
		</div>
	</div>
	<div class="image-cover" *ngIf="coverFile || coverUrl">
		<div>Cover</div>
		<div class="image-cover-border"></div>
	</div>
	<ng-container *ngIf="!coverFile && coverUrl">
		<gpe-profile-picture [pictureUrl]="coverUrl" [width]="60"></gpe-profile-picture>
	</ng-container>
	<ng-container *ngIf="coverFile">
		<gpe-profile-picture [pictureUrl]="coverFile.url" [width]="60"></gpe-profile-picture>
	</ng-container>
</div>

<div class="d-flex align-items-center justify-content-between mt-2 w-100 end">
	<button class="btn btn-primary create-btn" (click)="inputRoomData ? editRoom() : createRoom()">
		{{ inputRoomData ? 'Edit the room' : 'Create the room' }}
	</button>
	<button class="btn btn-cancel d-block" (click)="cancelEditing.emit()">Cancel</button>
</div>
<div class="loading-div" *ngIf="isLoading === true">
	<gpe-loading></gpe-loading>
</div>
