<div
	class="card post"
	[style.background-color]="post?.post_content_bgcolor != '#ffffff' ? post?.post_content_bgcolor : '#01b8ba'"
	*ngIf="post"
>
	<div class="row">
		<div class="col-10">
			<div class="d-flex">
				<div class="post-play-for-audio-wrap cursor-pointer">
					<img
						class="post-play-for-audio"
						src="assets/icons/play.svg"
						[style.display]="!sound?.playing() ? 'block' : 'none'"
						(click)="sound?.play()"
					/>
					<img
						class="post-play-for-audio"
						src="assets/icons/pause.svg"
						[style.display]="sound?.playing() ? 'block' : 'none'"
						(click)="sound?.pause()"
					/>
				</div>
				<ng-container *ngFor="let media of post.medias">
					<ng-container *ngIf="media.type === PostMediaType.Sound">
						<div class="post-content">
							<h3 class="cursor-pointer" (click)="navigateToPost()">
								{{ post?.post_content_title }}
							</h3>
							<div class="media-timeline">
								<div class="media-timeline-state" *ngIf="percent > 0" [style.width.%]="percent"></div>
							</div>
							<div class="media-times">
								<div class="media-time-state">
									<span>{{ seek | minuteSeconds }}</span>
								</div>
								{{ sound?.duration() | minuteSeconds }}
							</div>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<p class="post-location" *ngIf="post && post.location_id > 0">
				<b>Location:</b> {{ post?.location_title }}
			</p>
		</div>
		<div class="col-2 post-action-buttons">
			<ng-container *ngIf="post && likePost">
				<div
					*ngIf="!post.post_liked"
					class="post-action-button cursor-pointer"
					(click)="likePost(post.post_id, !post.post_liked)"
				>
					<img src="assets/icons/hand-white.svg" />
					{{ post?.post_like_count }}
				</div>
				<div
					*ngIf="post.post_liked"
					class="post-action-button active cursor-pointer"
					(click)="likePost(post.post_id, !post.post_liked)"
				>
					<img src="assets/icons/hand-color.svg" />
					{{ post?.post_like_count }}
				</div>
			</ng-container>
			<div class="post-action-button cursor-pointer" (click)="showCommentsClicked()">
				<img src="assets/icons/comment.svg" />
				{{ post?.post_comment_count }}
			</div>
			<div class="post-action-button cursor-pointer" (click)="showShareClicked()">
				<img src="assets/icons/share.svg" />
				Share
			</div>
			<div ngbDropdown class="post-action-button">
				<div ngbDropdownToggle class="cursor-pointer" id="dropdownMore1">
					<img src="assets/icons/more.svg" />
					More
				</div>
				<div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMore1">
					<ng-container *ngIf="post?.post_user_id != myUserId">
						<button ngbDropdownItem (click)="reportPost()">
							<img src="assets/icons/report-user.svg" height="14" class="float-start" />Report post
						</button>
						<button ngbDropdownItem (click)="reportUser()">
							<img src="assets/icons/report-user.svg" height="14" class="float-start" />Report user
						</button>
						<button ngbDropdownItem (click)="hidePost()">
							<img src="assets/icons/fi_eye-off.svg" height="14" class="float-start" />Hide post
						</button>
						<button ngbDropdownItem (click)="blockUser()">
							<img src="assets/icons/block-user.svg" height="14" class="float-start" />Block user
						</button>
					</ng-container>
					<ng-container *ngIf="post?.post_user_id == myUserId">
						<button ngbDropdownItem (click)="editPost()">
							<img src="assets/icons/edit-room.svg" height="14" class="float-start" />Edit post
						</button>
						<button ngbDropdownItem (click)="deletePost()">
							<img src="assets/icons/remove-room-list.svg" height="14" class="float-start" />Delete post
						</button>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
