<div class="custom-search-box">
	<div class="search-firstline">
		<div class="search-icon"><img src="assets/icons/search-black.svg" /></div>
		<div class="search-input-box">
			<input
				type="text"
				class="form-control"
				placeholder="What are you looking for? Events, users or companies?"
				[(ngModel)]="searchInput"
				(input)="inputChanged()"
			/>
		</div>
		<a class="search-close" (click)="closeSearchbar()">
			<img src="assets/icons/close-icon-black.svg" />
		</a>
	</div>
	<div class="search-result">
		<ng-container *ngIf="!is_loading">
			<ng-container *ngIf="resultPost.length > 0">
				<div
					class="search-result-row cursor-pointer"
					*ngFor="let element of resultPost"
					(click)="navigateToPost(element.post_id)"
				>
					<ng-container
						*ngIf="element.post_type === PostType.Event || element.post_type === PostType.Picture"
					>
						<div class="title-container">
							<div class="avatar-image">
								<ng-container *ngIf="element.medias.length > 0">
									<ng-container *ngFor="let actMedia of element.medias; index as i">
										<ng-container *ngIf="i < 1">
											<ng-container *ngIf="actMedia.type === PostMediaTypes.Image">
												<img class="avatar-image" [src]="actMedia.picture.file" />
											</ng-container>
										</ng-container>
									</ng-container>
								</ng-container>
							</div>
							<span>{{ element.post_event_title }}</span>
						</div>
						<div class="icon-container">
							<img src="assets/icons/calendar-blue.svg" />
						</div>
					</ng-container>
					<ng-container
						*ngIf="
							element.post_type === PostType.Sound ||
							element.post_type === PostType.Text ||
							element.post_type === PostType.Video
						"
					>
						<div class="title-container">
							<div class="avatar-image"><i class="fas fa-music"></i></div>
							<span>{{ element.post_content_title }}</span>
						</div>
						<div class="icon-container">
							<img src="assets/icons/calendar-blue.svg" />
						</div>
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="resultUser.length > 0">
				<div
					class="search-result-row cursor-pointer"
					*ngFor="let element of resultUser"
					(click)="navigateToUser(element.user_id)"
				>
					<div class="title-container">
						<img class="avatar-image" [src]="element.data.user_avatar" />
						<span>{{ element.user_first_name }} {{ element.user_last_name }}</span>
					</div>
					<div class="icon-container">
						<img src="assets/icons/arrow-right-blue.svg" />
					</div>
				</div>
			</ng-container>

			<!-- disabled in first release
      <a class="search-result-row"  *ngIf="searchInput != ''" (click)="jumpToSearch()">
        <div class="result-icon">
          <img src="assets/icons/search-green.svg">
        </div>
        <div class="result-more-text">
          <div class="result-more-text-content">
            <span>Detailed search:</span> {{searchInput}}
          </div>
        </div>
      </a> -->
		</ng-container>

		<div *ngIf="is_loading" class="text-center mt-2">
			<div class="loading-animation-container-sm">
				<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
			</div>
		</div>
	</div>
</div>
