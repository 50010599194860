import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PostMediaType } from 'src/app/core/models/PostMediaType';
import { FileHandle } from 'src/app/shared/models/FileHandle';

@Component({
	selector: 'gpe-upload-image-input',
	templateUrl: './upload-image-input.component.html',
	styleUrls: ['./upload-image-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadImageInputComponent implements OnChanges {
	@Input() displayTitle = 'Upload image';
	@Input() inputFile?: FileHandle;
	@Output() fileSelected = new EventEmitter<FileHandle | undefined>();

	public coverFile: FileHandle | undefined;

	constructor(private sanitizer: DomSanitizer) {
		this.coverFile = this.inputFile;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.inputFile) {
			this.coverFile = this.inputFile;
		}
	}

	handleFileInput(event: Event): void {
		const element = event.currentTarget as HTMLInputElement;
		const fileList: FileList | null = element.files;
		const file = fileList?.item(0);

		if (!file) {
			//TODO: Toast
			return;
		}

		if (!file.type.match('image.*')) {
			//TODO: Toast
			return;
		}

		this.coverFile = {
			file: file,
			id: 0,
			url: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file)),
			type: PostMediaType.Image,
		};
		this.fileSelected.emit(this.coverFile);
	}

	removeImage(): void {
		this.coverFile = undefined;
		this.fileSelected.emit(this.coverFile);
	}
}
