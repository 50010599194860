<div class="accordion" id="eventsAccordion">
	<div class="upcoming-events-accordion">
		<div id="upcomingEventsHeading" class="title-container">
			<h6>Upcoming events</h6>
			<img
				src="assets/icons/arrow-right-blue.svg"
				data-bs-toggle="collapse"
				data-bs-target="#collapseUpcomingEvents"
				aria-expanded="true"
				aria-controls="collapseUpcomingEvents"
				class="cursor-pointer"
			/>
		</div>
		<div
			id="collapseUpcomingEvents"
			class="accordion-collapse collapse show"
			aria-labelledby="upcomingEventsHeading"
			data-bs-parent="#eventsAccordion"
			*ngIf="events$ | async as events"
		>
			<div class="events-container" *ngIf="events.length > 0; else emptyState">
				<div
					class="event-item cursor-pointer"
					[routerLink]="['/home/' + event.post_id]"
					*ngFor="let event of events"
				>
					<h6>{{ event.post_event_title }}</h6>
					<span class="date">
						{{ event.post_event_date_from_epoch | date: 'yyyy.MM.dd. HH:mm' }} -
						{{ event.post_event_date_to_epoch | date: 'yyyy.MM.dd. HH:mm' }}
					</span>
					<div class="location-container">
						<span>{{ event.location_title }}</span>
						<img src="assets/icons/arrow-right-blue.svg" />
					</div>
				</div>
			</div>
			<ng-template #emptyState>
				<div class="empty-state">There are no events near you :(</div>
			</ng-template>
		</div>
	</div>
</div>
