import { writeErrorToLogFile } from '@angular/cli/utilities/log-file';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Tagify, TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';

import { IUserMinimal } from '../../../core/models/IUserMinimal';
import { UserService } from '../../../core/services/user.service';

interface TagData {
	value: string;
	[key: string]: any;
}

@Component({
	selector: 'gpe-complex-text-input',
	templateUrl: './complex-text-input.component.html',
	styleUrls: ['./complex-text-input.component.scss'],
})
export class ComplexTextInputComponent implements OnInit, OnChanges {
	tagifyName = 'democomplex';
	isDropdown = false;
	isLoading = false;
	firstLoaded = false;

	showComment = '';

	userData: IUserMinimal[] = [];

	@Input()
	inputStr = '';

	@Input()
	contentId = 0;

	@Input()
	inputUsers: IUserMinimal[] = [];

	searchStr = '';

	settings: TagifySettings = {
		placeholder: 'Start typing...',
		blacklist: [],
		mode: 'mix',
		pattern: /@|#/,
		keepInvalidTags: false,
		editTags: false,
		maxTags: 50,
		enforceWhitelist: true,
		duplicates: true,
		templates: {
			dropdown(settings) {
				const _sd = settings.dropdown,
					isManual = false,
					className = `tagify__dropdown`;

				return `<div class="${isManual ? '' : className} ${
					_sd ? _sd.classname : ''
				}" role="listbox" aria-labelledby="dropdown">
                          <div data-selector='tagify-dropdown-wrapper' class="${
								settings && settings.classNames ? settings.classNames.dropdownWrapper : ''
							}">aaa</div>
                      </div>`;
			},

			dropdownItem(this: Tagify, item: TagData): string {
				return `<div ${this.getAttributes(item)}
                        class=' tagify__dropdown__item  ${item.class ? item.class : ''}'
                        tabindex="0"
                        role="option" style="line-height: 30px"><div style="background-image: url('${
							item.avatar
						}'); background-size: cover; width: 30px; height: 30px; background-position: center; display: inline-block; border-radius: 100%"></div> ${
					item.value
				}</div>`;
			},
		},

		dropdown: {
			position: 'text',
			maxItems: 15,
		},
		callbacks: {
			click: (e: any) => {},
			'dropdown:show': (event) => {
				this.isDropdown = true;
			},
			'dropdown:updated': (event) => {},
			'dropdown:noMatch': (event1) => {
				this.isDropdown = true;
			},
			'dropdown:hide': (event) => {
				this.isDropdown = false;
			},

			input: (event1) => {
				this.searchStr = event1.detail.value;
				if (event1.detail.value && event1.detail.value != '') {
					this.isDropdown = true;
				} else {
					this.isDropdown = false;
				}
			},
		},
	};

	whitelist$ = new BehaviorSubject<TagData[]>([{ value: '' }]);

	readonly = false;

	constructor(private tagifyService: TagifyService, private userService: UserService) {
		const d = new Date();
		this.tagifyName = 'tagify-' + d.getMilliseconds() + '-' + Math.round(Math.random() * 100000);
	}

	onAdd(tagify: any) {
		this.whitelist$.next([{ value: '' }]);
	}

	onRemove(tags: any) {
		this.whitelist$.next([{ value: '' }]);
	}

	ngOnInit() {
		if (this.inputStr && this.inputStr != '') {
			this.loadDefaultString(this.inputStr.toString(), this.inputUsers);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.inputStr && !changes.inputStr.firstChange) {
			if (changes.inputStr.previousValue !== changes.inputStr.currentValue) {
				this.loadDefaultString(this.inputStr.toString(), this.inputUsers);
			}
		}
		if (changes.inputUsers && !changes.inputUsers.firstChange) {
			if (changes.inputUsers.previousValue !== changes.inputUsers.currentValue) {
				this.loadDefaultString(this.inputStr.toString(), this.inputUsers);
			}
		}
	}

	debounceTimeout: any;
	onInput(e: any) {
		if (this.isDropdown || this.isLoading) {
			if (this.debounceTimeout) {
				clearTimeout(this.debounceTimeout);
			}

			this.isLoading = true;
			this.tagifyService.get(this.tagifyName).dropdown.hide();

			this.whitelist$.next([{ value: '' }]);
			this.tagifyService.get(this.tagifyName).loading(true);

			this.debounceTimeout = setTimeout(() => {
				this.userService.searchCommentMention(this.searchStr, 1, 15).subscribe(
					(value) => {
						const result: TagData[] = [];
						for (const userObj of value) {
							result.push({
								id: userObj.user_id,
								value: userObj.user_first_name + ' ' + userObj.user_last_name,
								avatar: userObj.data.user_avatar,
							});
						}

						this.whitelist$.next(result);

						this.tagifyService.get(this.tagifyName).loading(false);
						this.tagifyService.get(this.tagifyName).dropdown.show();
						this.isLoading = false;
						this.isDropdown = true;
					},
					(error) => {},
					() => {
						this.tagifyService.get(this.tagifyName).dropdown.hide();
					},
				);
			}, 1200);
		}
	}

	public loadDefaultString(defaultStr: string, markedUsers: IUserMinimal[]) {
		const tempWhiteList: any = [];

		if (markedUsers) {
			this.userData.push(...markedUsers);
		}

		const re = /({@USER-\d+})/g;
		let m;

		let s = defaultStr;

		do {
			m = re.exec(s);
			if (m) {
				const temp = m[0].split('-')[1];

				const userId = Number.parseInt(temp.substring(0, temp.length - 1));
				const strObj = {
					id: userId,
					value: 'user-' + userId,
				};

				for (const u of markedUsers) {
					if (u.user_id == userId) {
						strObj.value = u.user_first_name + ' ' + u.user_last_name;
					}
				}
				tempWhiteList.push(strObj);

				s = this.replaceRange(s, m.index, m.index + m[0].length, '[[' + JSON.stringify(strObj) + ']]');
			}
		} while (m);

		this.whitelist$.next(tempWhiteList);
		setTimeout(() => {
			this.showComment = s;
			this.firstLoaded = true;
			this.tagifyService.get(this.tagifyName).loadOriginalValues(s);
		});
		setTimeout(() => {
			this.whitelist$.next([{ value: '' }]);
		}, 500);
	}

	public getClearStr(): string {
		let s = this.tagifyService.get(this.tagifyName).getInputValue();

		const re = /(\[\[{.+?}\]\])/g;
		let m;

		do {
			m = re.exec(s);
			if (m) {
				let f = m[0].split('"').join('"');
				f = f.substring(2, f.length - 2);

				const userMark = JSON.parse(f);
				let newStr = '';
				if (Number.parseInt(userMark.id.toString()) > 0) {
					newStr = '{@USER-' + userMark.id + '}';
				}

				s = this.replaceRange(s, m.index, m.index + m[0].length, newStr);
			}
		} while (m);

		return s;
	}

	replaceRange(s: string, start: number, end: number, substitute: string) {
		return s.substring(0, start) + substitute + s.substring(end);
	}
}
