import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'gpe-social-buttons',
	templateUrl: './social-buttons.component.html',
	styleUrls: ['./social-buttons.component.scss'],
})
export class SocialButtonsComponent implements OnInit {
	@Input() appleClick: (() => void) | undefined;

	@Input() facebookClick: (() => void) | undefined;

	@Input() googleClick: (() => void) | undefined;

	constructor() {}

	ngOnInit(): void {}

	appleClickButton() {
		if (this.appleClick) {
			this.appleClick();
		}
	}

	facebookClickButton() {
		if (this.facebookClick) {
			this.facebookClick();
		}
	}

	googleClickButton() {
		if (this.googleClick) {
			this.googleClick();
		}
	}
}
