<div *ngIf="loading" class="text-center mt-2">
	<div class="loading-animation-container">
		<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
	</div>
</div>

<div
	*ngIf="notifications$ | async as notifications"
	class="notification-list"
	infiniteScroll
	[scrollWindow]="false"
	[infiniteScrollUpDistance]="2"
	[infiniteScrollThrottle]="8"
	(scrolled)="changeActualPage(1)"
>
	<div class="not-have-notification" *ngIf="notifications.length === 0">You have no notifications yet.</div>
	<div *ngFor="let notify of notifications">
		<div class="notification-line" (click)="notoficationAction(notify)">
			<div class="d-flex">
				<div>
					<div
						class="avatar-image"
						(click)="navigateToUser(notify.sender.id)"
						[style.background-image]="'url(' + notify.sender.avatar + ')'"
					></div>
				</div>
				<div class="px-2 notification-text">
					<div class="notification-title">{{ notify.language_text }}</div>
					<div class="notification-subtitle" *ngIf="notify.post.title">{{ notify.post.title }}</div>
				</div>
				<div class="notification-date-container">
					<span class="notification-date">
						{{ (notify.sent_date_timestamp * 1000).toString() | dateAgoPipeMinimal }}
					</span>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<div *ngIf="notify.notify_confirm_type.has_buttons && notify.notify_confirm_type.reacted === '0'">
					<!-- ALREADY REACTED-- -->
					<div *ngIf="notify.notify_confirm_type.reacted !== '0'">
						<span
							class="btn btn-sm btn-already-reacted"
							*ngFor="let btn of notify.notify_confirm_type.buttons"
							[style.color]="btn.text_color"
							[style.background-color]="btn.bg_color"
							>{{ btn.lang_text }}</span
						>
					</div>

					<!--NOT REACTED-->
					<div *ngIf="notify.notify_confirm_type.reacted === '0'">
						<span
							class="btn btn-sm notify-reactbutton"
							*ngFor="let btn of notify.notify_confirm_type.buttons"
							[style.color]="btn.text_color"
							[style.background-color]="btn.bg_color"
							(click)="reactNotify(notify.id, btn.id)"
							>{{ btn.lang_text }}</span
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div *ngIf="actualPage > 1">
	<a (click)="changeActualPage(-1)">Előző</a>
</div>

<div *ngIf="actualPage > 1 && latestNotifications.length >= limitPerPage">
	<a (click)="changeActualPage(1)">Következő</a>
</div> -->
