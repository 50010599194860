import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';

import { IComment } from '../../../../core/models/IComment';
import { IFollowBatch } from '../../../../core/models/IFollowBatch';
import { IPost } from '../../../../core/models/IPost';
import { IPostCommentLikeBatch } from '../../../../core/models/IPostCommentLikeBatch';
import { IProfile } from '../../../../core/models/IProfile';
import { PostCommentsService } from '../../../../core/services/post-comments.service';
import { UserFollowService } from '../../../../core/services/user-follow.service';
import { UserService } from '../../../../core/services/user.service';
import { ComplexTextInputComponent } from '../../complex-text-input/complex-text-input.component';

@Component({
	selector: 'gpe-base-comment',
	templateUrl: './base-comment.component.html',
	styleUrls: ['./base-comment.component.scss'],
})
export class BaseCommentComponent implements OnInit {
	myUserId = 0;
	myUser: IProfile | null = null;

	@Input()
	post: IPost | undefined = undefined;
	@Input()
	firstReplyInput = false;

	@ViewChild(ComplexTextInputComponent) replyText: ComplexTextInputComponent | undefined;

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	actualPage = 1;
	limitPerPage = 15;
	is_loading = false;
	hasMorePage = true;
	comments: IComment[] = [];

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.post.firstChange) {
			if (changes.post.previousValue?.post_id !== changes.post.currentValue.post_id) {
				this.hasMorePage = true;
				this.actualPage = 1;
				this.comments.length = 0;
				this.loadNextPage();
				this.cdr.detectChanges();
			}
		}
	}

	constructor(
		private userService: UserService,
		private userFollowService: UserFollowService,
		private postCommentService: PostCommentsService,
		private router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private postCommentsService: PostCommentsService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();
		this.userService.getUser(0).subscribe((value) => {
			this.myUser = value;
		});
		this.loadNextPage();
	}

	alreadyLoadedIds = [];

	loadNextPage() {
		if (!this.is_loading && this.hasMorePage && this.post) {
			this.is_loading = true;
			this.postCommentService.getComments(this.post.post_id, this.actualPage, this.limitPerPage).subscribe(
				(value) => {
					if (value.length > 0) {
						this.fetchFollowAndLikes(value).subscribe(
							(value1) => {},
							(error) => {},
							() => {
								this.comments.push(...value);
								this.is_loading = false;
								this.actualPage++;
								this.cdr.detectChanges();
							},
						);
					} else {
						this.hasMorePage = false;
						this.is_loading = false;
					}
				},
				(error) => {},
				() => {
					this.is_loading = false;
				},
			);
		}
	}

	private fetchFollowAndLikes(comments: IComment[]): Observable<boolean> {
		return new Observable<boolean>((subscriber) => {
			const followReq = new Promise((resolve) => {
				const ids = this._createIdsArrFromComments(comments, this.alreadyLoadedIds);
				if (ids.length > 0) {
					//get user followed pairs
					this.userFollowService.getIsFollowedBatch(ids).subscribe(
						(value1) => {
							this._processFollowedForComments(comments, value1);
						},
						(error) => {},
						() => {
							resolve(true);
						},
					);
				} else {
					resolve(false);
				}
			});
			const likesReq = new Promise((resolve) => {
				const ids = this._createCommentIdsFromComments(comments, this.alreadyLoadedIds);
				if (ids.length > 0) {
					//get user followed pairs
					this.postCommentService.getCommentsLikes(ids).subscribe(
						(value1) => {
							this._processLikesForComments(comments, value1);
						},
						(error) => {},
						() => {
							resolve(true);
						},
					);
				} else {
					resolve(false);
				}
			});
			Promise.all([followReq, likesReq]).then((value) => {
				subscriber.next(true);
				subscriber.complete();
			});
		});
	}
	private _createCommentIdsFromComments(comments: IComment[], ids: number[] = []) {
		for (const va of comments) {
			const foundArr = ids.filter((value) => value == va.post_comment_id);
			if (foundArr.length == 0) {
				ids.push(va.post_comment_id);
			}

			if (va.childs) {
				this._createCommentIdsFromComments(va.childs, ids);
			}
		}
		return ids;
	}
	private _processLikesForComments(comments: IComment[], likesBatch: IPostCommentLikeBatch[]) {
		for (const va of comments) {
			for (const likes of likesBatch) {
				if (va.post_comment_id == likes.id) {
					va.post_comment_liked = likes.liked;
				}
			}
			if (va.childs) {
				this._processLikesForComments(va.childs, likesBatch);
			}
		}
	}

	private _createIdsArrFromComments(comments: IComment[], ids: number[] = []) {
		for (const va of comments) {
			if (va.user) {
				const uId = va.user.user_id;
				const foundArr = ids.filter((value) => value == uId);
				if (foundArr.length == 0) {
					ids.push(va.user.user_id);
				}
			}
			if (va.marked_users) {
				for (const marked of va.marked_users) {
					const uId = marked.user_id;
					const foundArr = ids.filter((value) => value == uId);
					if (foundArr.length == 0) {
						ids.push(marked.user_id);
					}
				}
			}

			if (va.childs) {
				this._createIdsArrFromComments(va.childs, ids);
			}
		}

		return ids;
	}

	private _processFollowedForComments(comments: IComment[], followBatches: IFollowBatch[]) {
		for (const va of comments) {
			if (va.user) {
				for (const followPair of followBatches) {
					if (va.user && va.user.user_id === followPair.id) {
						va.user.is_followed = followPair.is_followed;
					}
				}
			}

			if (va.marked_users) {
				for (const marked of va.marked_users) {
					for (const followPair of followBatches) {
						if (marked.user_id === followPair.id) {
							marked.is_followed = followPair.is_followed;
						}
					}
				}
			}

			if (va.childs) {
				this._processFollowedForComments(va.childs, followBatches);
			}
		}
	}

	navigateToUser(userId: number) {
		this.router.navigate(['/profile/' + userId]);
	}

	userFollowing = (userId: number, nextState: boolean, comments: IComment[] | undefined = undefined) => {
		this.userFollowingInArrays(userId, nextState, comments);

		this.userFollowService.followUser(userId, nextState).subscribe(
			(value) => {
				if (!value) {
					this.userFollowingInArrays(userId, !nextState, comments);
				}
			},
			(error) => {
				this.userFollowingInArrays(userId, !nextState, comments);
			},
			() => {},
		);
	};
	userFollowingInArrays(userId: number, nextState: boolean, comments: IComment[] | undefined = undefined) {
		if (comments == undefined) {
			comments = this.comments;
		}
		for (const va of comments) {
			if (va.user && va.user.user_id == userId) {
				va.user.is_followed = nextState;
			}

			if (va.marked_users) {
				for (const marked of va.marked_users) {
					if (marked.user_id === userId) {
						marked.is_followed = nextState;
					}
				}
			}

			if (va.childs) {
				this.userFollowingInArrays(userId, nextState, va.childs);
			}
		}
	}

	addNewComment(parentId: number) {
		if (this.replyText && this.post) {
			this.postCommentsService.addComment(this.post.post_id, parentId, this.replyText.getClearStr()).subscribe(
				(value) => {
					this.comments.push(value);
					if (this.replyText) {
						this.replyText.loadDefaultString('', []);
					}
					this.cdr.markForCheck();
				},
				(error) => {},
				() => {},
			);
		}
	}
}
