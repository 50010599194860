<div class="upload-container select" *ngIf="!coverFile" (click)="fileInput.click()">
	<div class="upload-image primary">
		<img src="/assets/icons/upload-icon.svg" />
	</div>
	<span class="upload-title">{{ displayTitle }}</span>
	<span class="upload-description"> Click to upload media, or drag files here </span>
</div>

<div *ngIf="coverFile" class="upload-container uploaded">
	<img class="background" [src]="coverFile.url" />
	<div class="upload-image primary" (click)="fileInput.click()">
		<img src="/assets/icons/upload-icon.svg" title="Change image" />
	</div>
	<div class="upload-image danger" (click)="removeImage()">
		<img src="/assets/icons/upload-icon.svg" title="Remove image" />
	</div>
</div>

<input #fileInput type="file" name="file" (change)="handleFileInput($event)" id="file" single style="display: none" />
