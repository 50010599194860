import { IInterestsStatus } from './IInterests';

export enum IUserType {
	UNDEFINED = 0,
	USER = 1,
	COMPANY = 2,
}
export interface IUserBase {
	user_id: number;
	user_type: number;
	user_first_name: string;
	user_last_name: string;
	user_date_added?: string;
	is_followed?: boolean;

	interests?: IInterestsStatus[];
}
