import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberSuffix',
})
export class NumberSuffixPipe implements PipeTransform {
	transform(input: number): string {
		const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];

		if (Number.isNaN(input) || (input < 1000 && input >= 0) || (input < 0 && input > -1000)) {
			return input.toString();
		}

		const exp = Math.floor(Math.log(input) / Math.log(1000));

		return (input / Math.pow(1000, exp)).toFixed(1) + suffixes[exp - 1];
	}
}
