import { Injectable } from '@angular/core';
import { getAuth, onAuthStateChanged, signInWithCustomToken } from '@angular/fire/auth';
import {
	collection,
	collectionData,
	collectionSnapshots,
	doc,
	DocumentData,
	DocumentReference,
	Firestore,
	FirestoreDataConverter,
	limitToLast,
	orderBy,
	query,
	runTransaction,
	serverTimestamp,
	Transaction,
	where,
} from '@angular/fire/firestore';
import { limit } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { IChatMessage, IChatReaction } from '../../../core/models/firebasechat/IChatMessage';
import { IChatRoom } from '../../../core/models/firebasechat/IChatRoom';
import { UserService } from '../../../core/services/user.service';

@Injectable({
	providedIn: 'root',
})
export class FirebaseService {
	constructor(private fireStore: Firestore, private userService: UserService) {}

	private get currentUserId(): number {
		return this.userService.getLoginedUserId();
	}

	private usersCollection = collection(this.fireStore, environment.firestoreChat.collectionKeys.users);
	private chatRoomsCollection = collection(this.fireStore, environment.firestoreChat.collectionKeys.chatrooms);

	public login(): void {
		if (!this.currentUserId) return;
		const auth = getAuth();

		onAuthStateChanged(auth, (user) => {
			if (!user)
				if (this.currentUserId)
					this.userService.getFirebaseToken().subscribe((token) => {
						signInWithCustomToken(auth, token.firebase_token);
					});
		});
	}

	public async logout(): Promise<void> {
		const auth = getAuth();

		return auth.signOut();
	}

	public getChatRoomsSnapShot(size: number): Observable<IChatRoom[]> {
		return collectionSnapshots(
			query(
				this.chatRoomsCollection,
				where(
					environment.firestoreChat.propertyKeys.chatRoomUserIds,
					QueryParams.ArrayContains,
					this.userService.getLoginedUserId().toString(),
				),
				orderBy(
					environment.firestoreChat.propertyKeys.chatRoomLastMessageServerTimeStamp,
					QueryParams.Descending,
				),
				limit(size),
			),
		).pipe(
			map((actions) => {
				return actions.map((r) => {
					const room = { ...r.data() } as IChatRoom;
					room.id = r.id;
					return room;
				});
			}),
		);
	}

	public getMessagesSnapShot(roomId: string, limit: number): Observable<IChatMessage[]> {
		return collectionSnapshots(
			query(
				collection(
					this.fireStore,
					environment.firestoreChat.collectionKeys.chatrooms,
					roomId,
					environment.firestoreChat.collectionKeys.messages,
				),
				orderBy(environment.firestoreChat.propertyKeys.chatMessageServerTimeStamp, QueryParams.Ascending),
				limitToLast(limit),
			),
		).pipe(
			map((actions) => {
				return actions.map((r) => {
					const message = { ...r.data() } as IChatMessage;
					message.id = r.id;
					return message;
				});
			}),
		);
	}

	public getChatRooms(): Observable<IChatRoom[]> {
		return collectionData(
			query(
				this.chatRoomsCollection.withConverter<IChatRoom>(this.chatRoomConverter),
				where(
					environment.firestoreChat.propertyKeys.chatRoomUserIds,
					QueryParams.ArrayContains,
					this.currentUserId.toString(),
				),
			),
		);
	}

	public async updateUser(): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			this.updateUserInTransaction(transaction);
		});
	}

	public async markMessagesSeen(roomId: string): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const roomRef = doc(this.chatRoomsCollection.withConverter<IChatRoom>(this.chatRoomConverter), roomId);

			await this.markMessagesSeenInTransaction(transaction, roomRef);
			this.updateUserInTransaction(transaction);
		});
	}

	public async addChatRoom(otherUserId: number, message: string): Promise<string> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const newRoomRef = this.addRoomInTransaction(transaction, otherUserId, message);

			this.addMessageInTransaction(transaction, newRoomRef, message);

			this.updateUserInTransaction(transaction);

			return newRoomRef.id;
		});
	}

	public async sendMessage(roomId: string, message: string, reply?: IChatMessage): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const roomRef = doc(this.chatRoomsCollection, roomId);

			await this.updateRoomInTransaction(transaction, roomRef, message);

			this.addMessageInTransaction(transaction, roomRef, message, reply);

			this.updateUserInTransaction(transaction);
		});
	}

	public async likeMessage(roomId: string, messageId: string, reactions: IChatReaction[]): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const messageRef = doc(
				this.chatRoomsCollection,
				roomId,
				environment.firestoreChat.collectionKeys.messages,
				messageId,
			);

			this.likeMessageInTransaction(transaction, messageRef, reactions);

			this.updateUserInTransaction(transaction);
		});
	}

	private async likeMessageInTransaction(
		transaction: Transaction,
		messageRef: DocumentReference<DocumentData>,
		reactions: IChatReaction[],
	) {
		transaction.update(messageRef, { reactions: reactions });
	}

	private async updateRoomInTransaction(
		transaction: Transaction,
		roomRef: DocumentReference<DocumentData>,
		message: string,
	) {
		const roomData = (await transaction.get(roomRef.withConverter<IChatRoom>(this.chatRoomConverter))).data();
		if (roomData) {
			const unseenMessages = this.calculateUnseenMessages(roomData);
			transaction.update(roomRef, {
				lastMessage_message: message,
				lastMessage_timeStampInSec: serverTimestamp(),
				lastMessage_userId: this.currentUserId,
				unseenMessages: unseenMessages,
			});
		}
	}

	private async markMessagesSeenInTransaction(transaction: Transaction, roomRef: DocumentReference<IChatRoom>) {
		const roomData = (await transaction.get(roomRef)).data();
		if (roomData) {
			const unseenMessages = roomData.unseenMessages;
			unseenMessages[`user_${this.currentUserId}`] = 0;
			transaction.update(roomRef, {
				unseenMessages: unseenMessages,
			});
		}
	}

	private addRoomInTransaction(
		transaction: Transaction,
		otherUserId: number,
		message: string,
	): DocumentReference<DocumentData> {
		const newRoomRef = doc(this.chatRoomsCollection);
		transaction.set(newRoomRef, {
			created: serverTimestamp(),
			isOnCustomServer: environment.firestoreChat.isOnCustomServer,
			lastMessage_message: message,
			lastMessage_timeStampInSec: serverTimestamp(),
			lastMessage_userId: this.currentUserId,
			unseenMessages: {
				[`user_${this.currentUserId}`]: 0,
				[`user_${otherUserId}`]: 1,
			},
			userIds: [this.currentUserId.toString(), otherUserId.toString()],
			users: `${this.currentUserId}_${otherUserId}`,
		} as IChatRoom);
		return newRoomRef;
	}

	private addMessageInTransaction(
		transaction: Transaction,
		roomRef: DocumentReference<DocumentData>,
		message: string,
		reply?: IChatMessage,
	): DocumentReference<DocumentData> {
		const newMessage = {
			clientTimestamp: new Date().getTime(),
			isOnCustomServer: environment.firestoreChat.isOnCustomServer,
			message: message,
			serverTimestampInSec: serverTimestamp(),
			userId: this.currentUserId,
		} as IChatMessage;

		if (reply) newMessage.reply = reply;

		const newMessageRef = doc(collection(roomRef, environment.firestoreChat.collectionKeys.messages));
		transaction.set(newMessageRef, newMessage);
		return newMessageRef;
	}

	private updateUserInTransaction(transaction: Transaction) {
		transaction.set(doc(this.usersCollection, this.currentUserId.toString()), {
			uid: this.currentUserId.toString(),
			lastAction: serverTimestamp(),
		});
	}

	private calculateUnseenMessages(room: IChatRoom): { [k: string]: number } {
		const otherUserIds = room?.userIds.filter((u) => u.toString() !== this.currentUserId.toString());
		const unseenMessages: { [k: string]: number } = { [`user_${this.currentUserId}`]: 0 };
		otherUserIds?.forEach((id) => (unseenMessages[`user_${id}`] = room.unseenMessages[`user_${id}`] + 1));
		return unseenMessages;
	}

	private readonly chatRoomConverter: FirestoreDataConverter<IChatRoom> = {
		fromFirestore: (snapshot) => {
			const room = { ...snapshot.data() } as IChatRoom;
			room.id = snapshot.id;
			return room;
		},
		toFirestore: (r: any) => r,
	};
}

export enum QueryParams {
	ArrayContains = 'array-contains',
	Equals = '==',
	GreaterOrEquals = '<=',
	LessOrEquals = '>=',
	Ascending = 'asc',
	Descending = 'desc',
}
