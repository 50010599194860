import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

import { ApiService } from '../api/api.service';
import { IUserMinimal } from '../models/IUserMinimal';

import { ApiResponse } from './../../shared/models/ApiResponse';
import { AttendanceType, EventJoinedMembers, EventLikedMembersResponse } from './../models/IEvent';
import { IPost, IPostGetList } from './../models/IPost';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class EventService {
	private apiUrl = environment.apiURL;
	constructor(private authService: AuthService, private apiService: ApiService, private userService: UserService) {}

	private upcomingEvents$ = new BehaviorSubject<IPost[]>([]);

	public get upcomingEvents(): Observable<IPost[]> {
		return this.upcomingEvents$.asObservable();
	}

	public loadUpcomingEvents(): void {
		this.apiService
			.get<ApiResponse<IPostGetList>>(`${this.apiUrl}/post/event/upcoming`)
			.pipe(
				map((res) => {
					this.upcomingEvents$.next(res.data.posts);
				}),
			)
			.subscribe();
	}

	getUpcomingEvents(): Observable<IPost[]> {
		const url = `${this.apiUrl}/post/event/upcoming`;

		return this.apiService.get<ApiResponse<IPostGetList>>(url).pipe(
			map((res) => {
				return res.data.posts;
			}),
		);
	}

	getTopEvents(): Observable<IPost[]> {
		const url = `${this.apiUrl}/post/event/top`;

		return this.apiService.get<ApiResponse<IPostGetList>>(url).pipe(
			map((res) => {
				return res.data.posts;
			}),
		);
	}

	public getJoinedMembers(eventId: number, status: AttendanceType): Observable<EventJoinedMembers[]> {
		return this.apiService
			.get<ApiResponse<{ joins: IUserMinimal[] }>>(`post/event/${eventId}/join`, {
				params: { status: status.toString() },
			})
			.pipe(
				map((userResp): EventJoinedMembers[] => this.mapIUserMinimalToEventjoinedMembers(userResp.data.joins)),
			);
	}

	public getLikedMembers(postId: number, page: number, limit: number): Observable<EventJoinedMembers[]> {
		return this.apiService
			.get<ApiResponse<EventLikedMembersResponse>>(`post/like/${postId}`, {
				params: {
					page: page.toString(),
					limit: limit.toString(),
				},
			})
			.pipe(
				map((likedResp): EventJoinedMembers[] =>
					this.mapEventLikedMembersResponseToEventJoinMembers(likedResp.data),
				),
			);
	}

	private mapIUserMinimalToEventjoinedMembers(users: IUserMinimal[]): EventJoinedMembers[] {
		return users.map(
			(user): EventJoinedMembers => ({
				id: user.user_id,
				avatar: user.user_avatar ?? '',
				name: `${user.user_last_name} ${user.user_first_name}`,
			}),
		);
	}

	private mapEventLikedMembersResponseToEventJoinMembers(users: EventLikedMembersResponse): EventJoinedMembers[] {
		return users.likes.map((user) => ({
			avatar: user.user.data.user_avatar,
			id: user.user.user_id,
			name: `${user.user.user_last_name} ${user.user.user_first_name}`,
		}));
	}
}
