import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from './../../shared/models/ApiResponse';
import { ApiService } from './../api/api.service';

@Injectable({
	providedIn: 'root',
})
export class ReportService {
	constructor(private apiService: ApiService) {}

	reportUser(userId: number, reason: string): Observable<boolean> {
		const url = `user/report/${userId}`;

		const data = new FormData();
		data.append('text', reason);

		return this.apiService.post<ApiResponse<boolean>>(url, data).pipe(
			map((res) => {
				return res.success == 1;
			}),
		);
	}

	reportPost(postId: string, reason: string): Observable<boolean> {
		const url = 'post/report';

		const data = new FormData();
		data.append('report_text', reason);
		data.append('post_id', postId);

		return this.apiService.post<ApiResponse<boolean>>(url, data).pipe(map((res) => res.success == 1));
	}

	reportComment(commentId: number, reason: string): Observable<boolean> {
		const url = `post/comment/report/${commentId}`;

		const data = new FormData();
		data.append('report_text', reason);

		return this.apiService.post<ApiResponse<boolean>>(url, data).pipe(map((res) => res.success == 1));
	}
}
