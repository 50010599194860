import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/api/api.service';
import { CreateCompanyCommand } from 'src/app/core/sidebars/chat-messages-sidebar/dialogs/create-company-dialog/create-company-model';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class CompanyService {
	private companyStatus$ = new BehaviorSubject<{ status: string } | undefined>(undefined);

	constructor(private apiService: ApiService) {}

	public get companyStatus(): Observable<{ status: string } | undefined> {
		return this.companyStatus$.asObservable();
	}

	public loadCompanyStatus(company_id: string): Observable<void> {
		return this.apiService
			.get<ApiResponse<{ status: string; role: unknown }>>(`company/company_status`, {
				params: { company_id },
			})
			.pipe(
				map((res) => {
					this.companyStatus$.next({ status: res.data.status });
				}),
			);
	}

	public createCompany(company: CreateCompanyCommand): Observable<void> {
		const formData = new FormData();
		formData.append('company_name', company.name);
		formData.append('website', company.url);
		formData.append('gpeUrl', company.gpeUrl);
		formData.append('industry', company.industry.id);
		formData.append('companySize', company.size.id);
		formData.append('companyType', company.type.id);
		formData.append('slogen', company.bio);
		formData.append('story', company.story);

		return this.apiService.post<void>('company', formData);
	}
}
