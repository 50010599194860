<a (click)="closeModal()" class="modal-close-button">Close</a>
<div class="modal-content">
	<div class="left-navbar">
		<gpe-profile-picture [pictureUrl]="profilePic" class="modal-profile" [width]="54"></gpe-profile-picture>
		<ul class="nav modal-navbar" id="addRoomTab" role="tablist">
			<li class="nav-item" role="presentation">
				<button
					class="modal-nav-item"
					[ngClass]="!selectedRoom ? 'active' : 'inactive'"
					id="addroom-tab"
					data-bs-toggle="tab"
					data-bs-target="#addroom"
					type="button"
					role="tab"
					aria-controls="addroom"
					aria-selected="true"
				>
					<img src="/assets/icons/add-room-green.svg" class="nav-item-icon" />
					<div class="nav-item-text">Add room</div>
				</button>
			</li>
			<li class="nav-item" role="presentation">
				<button
					class="modal-nav-item"
					[ngClass]="selectedRoom ? 'active' : 'inactive'"
					id="editroom-tab"
					data-bs-toggle="tab"
					data-bs-target="#editroom"
					type="button"
					role="tab"
					aria-controls="editroom"
					aria-selected="false"
				>
					<img src="/assets/icons/add-room-green.svg" class="nav-item-icon" />
					<div class="nav-item-text">Edit room</div>
				</button>
			</li>
		</ul>
	</div>
	<div class="tab-content" id="addRoomTabContent">
		<div
			class="tab-pane fade"
			[ngClass]="!selectedRoom ? 'active show' : ''"
			id="addroom"
			role="tabpanel"
			aria-labelledby="map-tab"
		>
			<div class="title">Create room</div>
			<gpe-create-room (cancelEditing)="closeModal()"></gpe-create-room>
		</div>
		<div
			class="tab-pane fade"
			[ngClass]="selectedRoom ? 'active show' : ''"
			id="editroom"
			role="tabpanel"
			aria-labelledby="map-tab"
		>
			<div class="title">Edit room</div>
			<gpe-edit-room-list
				[myRooms]="myRooms$ | async"
				[profilePic]="profilePic"
				[selectedRoom]="selectedRoom"
			></gpe-edit-room-list>
		</div>
	</div>
</div>
