<div class="title">Add event</div>

<div *ngIf="is_loading" class="text-center mt-2">
	<div class="loading-animation-container">
		<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
	</div>
</div>
<div [class.disableall]="is_loading">
	<div class="row mt-4">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-6">
					<input
						type="text"
						class="form-control input-fields input-text"
						placeholder="Event name"
						[class.input-error]="submitted && hasErrorColumn('title')"
						(input)="checkRequiredColumns()"
						[(ngModel)]="newPostObj.title"
					/>

					<div *ngIf="submitted && hasErrorColumn('title')" class="invalid-feedback">
						<div class="error-message" translate>
							This field is required and a minimum of 3 characters is required. Please fill it out to
							proceed.
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<app-location-selector
						[appliedClasses]="'form-control input-fields input-text'"
						[startLocation]="post?.location_title"
						[locationChanged]="locationChanged"
						[placeholder]="'Event location'"
						[disabled]="newPostObj.event_online !== undefined && newPostObj.event_online"
					></app-location-selector>
				</div>
				<div class="col-md-12 mt-2">
					<div class="row">
						<div class="col-md-6 p-0">
							<label>
								<div
									class="custom-selector-holder"
									[class.custom-selector-holder-selected]="newPostObj.event_private"
								>
									<img src="/assets/icons/check-02.svg" width="10" *ngIf="newPostObj.event_private" />
								</div>
								<input type="checkbox" [(ngModel)]="newPostObj.event_private" class="hidden-checkbox" />
								Private event</label
							>
						</div>
						<div class="col-md-6 p-0">
							<label>
								<div
									class="custom-selector-holder"
									[class.custom-selector-holder-selected]="newPostObj.event_online"
								>
									<img src="/assets/icons/check-02.svg" width="10" *ngIf="newPostObj.event_online" />
								</div>
								<input type="checkbox" [(ngModel)]="newPostObj.event_online" class="hidden-checkbox" />
								Worldwide</label
							>
						</div>
					</div>
				</div>
				<div class="col-md-12 mt-2">
					<textarea
						class="form-control input-fields input-textarea"
						placeholder="Event description"
						[class.input-error]="submitted && hasErrorColumn('text')"
						(input)="checkRequiredColumns()"
						rows="5"
						[(ngModel)]="newPostObj.text"
					></textarea>

					<div *ngIf="submitted && hasErrorColumn('text')" class="invalid-feedback">
						<div class="error-message" translate>
							This field is required and a minimum of 3 characters is required. Please fill it out to
							proceed.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-2 d-flex align-items-center">
		<div class="col-md-4">
			<div ngbDropdown class="d-block datepicker-dropdown datepicker-dropdownc" #dropdown1>
				<button
					type="button"
					class="btn btn-block btn-outline-primary date-selector-button"
					id="dropdownForm1"
					ngbDropdownToggle
					#toogle222411
					[class.input-error]="submitted && hasErrorColumn('from')"
					(input)="checkRequiredColumns()"
				>
					From
					<span *ngIf="this.newPostObj.event_date_from">{{
						this.newPostObj.event_date_from | date: 'yyyy / MM / dd'
					}}</span>
					<span *ngIf="!this.newPostObj.event_date_from">YYYY / MM / DD</span>
				</button>
				<div ngbDropdownMenu aria-labelledby="dropdownForm1">
					<div class="bg-white p-3">
						<dl-date-time-picker
							class="gpe-datepicker"
							startView="day"
							maxView="year"
							minView="minute"
							[minuteStep]="5"
							[(ngModel)]="this.newPostObj.event_date_from"
							(ngModelChange)="changedDate(toogle222411)"
						>
						</dl-date-time-picker>
					</div>
				</div>

				<div *ngIf="submitted && hasErrorColumn('from')" class="invalid-feedback">
					<div class="error-message" translate>
						This field is required and you must enter a date at least 5 minutes later than the current time.
						Please fill it out to proceed.
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div ngbDropdown class="d-block datepicker-dropdown datepicker-dropdownc" #dropdown2>
				<button
					type="button"
					class="btn btn-block btn-outline-primary date-selector-button"
					id="dropdownForm2"
					[class.input-error]="submitted && hasErrorColumn('to')"
					(input)="checkRequiredColumns()"
					ngbDropdownToggle
					#toogle222412
				>
					To
					<span *ngIf="this.newPostObj.event_date_to">{{
						this.newPostObj.event_date_to | date: 'yyyy / MM / dd'
					}}</span>
					<span *ngIf="!this.newPostObj.event_date_to">YYYY / MM / DD</span>
				</button>
				<div ngbDropdownMenu aria-labelledby="dropdownForm2">
					<div class="bg-white p-3">
						<dl-date-time-picker
							startView="day"
							maxView="year"
							minView="minute"
							[minuteStep]="5"
							[(ngModel)]="this.newPostObj.event_date_to"
							(ngModelChange)="changedDate(toogle222412)"
						>
						</dl-date-time-picker>
					</div>
				</div>
				<div *ngIf="submitted && hasErrorColumn('to')" class="invalid-feedback">
					<div class="error-message" translate>
						This field is required. You must enter a date later than the start date and at least 5 minutes
						later than the current time. Please fill it out to proceed.
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-2">
			<input
				type="number"
				value="1"
				class="form-control input-fields input-text"
				[(ngModel)]="newPostObj.event_max_people"
				[class.input-error]="submitted && hasErrorColumn('max_people')"
				(input)="checkRequiredColumns()"
				[disabled]="!newPostObj.guestLimit"
			/>

			<div *ngIf="submitted && hasErrorColumn('max_people')" class="invalid-feedback">
				<div class="error-message" translate>
					This field is required. The value must be at least 1. Please fill it out to proceed.
				</div>
			</div>
		</div>
		<div class="col-md-2 p-0">
			<label>
				<div class="custom-selector-holder" [class.custom-selector-holder-selected]="newPostObj.guestLimit">
					<img src="/assets/icons/check-02.svg" width="10" *ngIf="newPostObj.guestLimit" />
				</div>
				<input
					type="checkbox"
					[(ngModel)]="newPostObj.guestLimit"
					(ngModelChange)="newPostObj.event_max_people = 0"
					value="1"
					class="hidden-checkbox"
				/>
				Guest limit</label
			>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-4">
					<div ngbDropdown class="d-block datepicker-dropdown" (openChange)="toggled($event, 3)">
						<button
							type="button"
							class="btn btn-block btn-outline-primary post-selector-button"
							id="dropdownForm3"
							ngbDropdownToggle
						>
							<img src="assets/icons/add-an-organizer.svg" /> Organizers
							<ng-container *ngIf="organizersSelected.length > 0"
								>({{ organizersSelected.length }})</ng-container
							>
						</button>
						<div ngbDropdownMenu aria-labelledby="dropdownForm3">
							<gpe-create-post-user-selector
								[getSearch]="oraganizerSearch"
								[onChanged]="organizerChanged"
								[selectedUsers]="organizersSelected"
								[refNumber]="3"
								[buttonActivateText]="'Add'"
								[buttonDeactivateText]="'Remove'"
							></gpe-create-post-user-selector>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="d-block datepicker-dropdown">
						<button
							type="button"
							class="btn btn-block btn-outline-primary post-selector-button"
							id="dropdownForm6"
							(click)="openMediaSelector()"
						>
							<img src="assets/icons/add-photos.svg" /> Attach media
						</button>

						<gpe-media-selector
							[classList]="'d-none'"
							[uploadedFiles]="selectedMedias"
							[fileChanged]="selectedMediasChanged"
						></gpe-media-selector>
					</div>
				</div>
				<div class="col-md-4">
					<div ngbDropdown class="d-block datepicker-dropdown" (openChange)="toggled($event, 2)">
						<button
							type="button"
							class="btn btn-block btn-outline-primary post-selector-button"
							id="dropdownForm4"
							ngbDropdownToggle
						>
							<img src="assets/icons/invite-peoples.svg" /> Invite
							<ng-container *ngIf="invitedSelected.length > 0"
								>({{ invitedSelected.length }})</ng-container
							>
						</button>
						<div ngbDropdownMenu aria-labelledby="dropdownForm4">
							<gpe-create-post-user-selector
								[getSearch]="invitedSearch"
								[onChanged]="invitedChanged"
								[selectedUsers]="invitedSelected"
								[refNumber]="2"
								[buttonActivateText]="'Add'"
								[buttonDeactivateText]="'Remove'"
							></gpe-create-post-user-selector>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-2"></div>
	</div>

	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row" *ngIf="selectedMedias.length > 0">
				<div class="col-md-12">
					<div class="subtitle"><span>Photos</span></div>
				</div>
				<div class="col-md-4" *ngFor="let file of selectedMedias">
					<div class="image-preview">
						<a class="btn btn-success" (click)="setToMain(file)"
							><img src="/assets/icons/check-02.svg" width="14" *ngIf="file.primary"
						/></a>
						<a class="btn btn-danger pull-right" (click)="removeMedia(file)">x</a>
						<ng-container *ngIf="file.process">
							<ngb-progressbar
								type="success"
								textType="white"
								[value]="file.process"
								[showValue]="true"
							></ngb-progressbar>
						</ng-container>
						<img class="previewImg" [src]="file.previewUrl" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-10">
					<a class="btn btn-primary d-block" (click)="addEvent()">{{
						postId > 0 ? 'Edit post' : 'Add post'
					}}</a>
				</div>
				<div class="col-md-2 p-0">
					<a class="btn post-button-cancel d-block" (click)="closeModal()">Cancel</a>
				</div>
			</div>
		</div>
		<div class="col-md-2"></div>
	</div>
</div>
