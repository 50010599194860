import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';

import { SharedModule } from '../shared/shared.module';

import { ReportProblemComponent } from './common/report-problem/report-problem.component';
import { SuggestIdeaComponent } from './common/suggest-idea/suggest-idea.component';
import { HeaderComponent } from './header/header.component';
import { SearchBarComponent } from './header/search-bar/search-bar.component';
import { CompanyManagementLayoutComponent } from './layouts/company-management-layout/company-management-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NotfoundComponent } from './notfound/components/notfound.component';
import { ChatMessagesSidebarComponent } from './sidebars/chat-messages-sidebar/chat-messages-sidebar.component';
import { NotificationsComponent } from './sidebars/chat-messages-sidebar/components/notifications/notifications.component';
import { CreateCompanyDialogComponent } from './sidebars/chat-messages-sidebar/dialogs/create-company-dialog/create-company-dialog.component';
import { RoomsOnlineSidebarComponent } from './sidebars/rooms-online-sidebar/rooms-online-sidebar.component';
import { UserListComponent } from './sidebars/rooms-online-sidebar/user-list/user-list.component';
import { CreateRoomComponent } from './sidebars/rooms-sidebar/dialogs/create-room/create-room.component';
import { EditRoomListComponent } from './sidebars/rooms-sidebar/dialogs/edit-room-list/edit-room-list.component';
import { RoomModalsContainerComponent } from './sidebars/rooms-sidebar/dialogs/rooms-modals-container/rooms-modals-container.component';
import { RoomListComponent } from './sidebars/rooms-sidebar/room-list/room-list.component';
import { RoomsSidebarComponent } from './sidebars/rooms-sidebar/rooms-sidebar.component';
import { SettingsSidebarComponent } from './sidebars/settings-sidebar/settings-sidebar.component';
import { UpcomingEventsSidebarComponent } from './sidebars/upcoming-events-sidebar/upcoming-events-sidebar.component';

@NgModule({
	declarations: [
		HeaderComponent,
		NotfoundComponent,
		SearchBarComponent,
		MainLayoutComponent,
		ReportProblemComponent,
		SuggestIdeaComponent,
		UpcomingEventsSidebarComponent,
		SettingsSidebarComponent,
		RoomsSidebarComponent,
		RoomsOnlineSidebarComponent,
		ChatMessagesSidebarComponent,
		RoomListComponent,
		UserListComponent,
		CompanyManagementLayoutComponent,
		RoomModalsContainerComponent,
		CreateRoomComponent,
		EditRoomListComponent,
		CreateCompanyDialogComponent,
		NotificationsComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		SharedModule,
		RouterModule,
		FormsModule,
		LottieModule,
		NgbDropdownModule,
		InfiniteScrollModule,
		SvgIconsModule,
	],
	exports: [HeaderComponent, NotfoundComponent],
})
export class CoreModule {}
