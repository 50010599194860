import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';

import { IPost } from '../../../../../../core/models/IPost';
import { IProfileMinimal } from '../../../../../../core/models/IProfileMinimal';

@Component({
	selector: 'gpe-create-post-event-selector',
	templateUrl: './event-selector.component.html',
	styleUrls: ['./event-selector.component.scss'],
})
export class EventSelectorComponent implements OnInit {
	@Input()
	getSearch: ((searchStr: string, page: number, limit: number) => Observable<IPost[]>) | undefined;

	@Input()
	onChanged: ((selected: IPost | undefined) => void) | undefined;

	@Input()
	eventSelected: IPost | undefined;

	@Input()
	refNumber: number | undefined;

	@Input()
	buttonActivateText: string | undefined;

	@Input()
	buttonDeactivateText: string | undefined;

	@ViewChild('eventSelectorInput') inputElement: ElementRef | undefined;

	@Output()
	focusInput() {
		setTimeout(() => {
			if (this.inputElement) {
				this.inputElement.nativeElement.focus();
				this.inputElement.nativeElement.select();
			}
		}, 50);
	}

	@Output()
	clearInput() {
		this.searchInput = '';
		this.result = [];
	}

	result: IPost[] = [];

	searchInput = '';
	debounceTimeout: any = undefined;

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	is_loading = false;

	constructor() {}

	ngOnInit(): void {
		if (!this.result) {
			this.result = [];
		}
	}

	ngOnDestroy() {
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}
	}

	inputKeyDown(e: Event) {
		if (e instanceof KeyboardEvent) {
			if (e.key == 'Enter') {
				this.inputChanged();
			}
		}
	}

	inputChanged() {
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
			this.is_loading = false;
		}
		this.result = [];
		if (this.searchInput.length > 2) {
			this.is_loading = true;
			this.debounceTimeout = setTimeout(() => {
				if (this.getSearch) {
					const v = '' + this.searchInput;

					this.getSearch(v, 1, 15).subscribe(
						(value) => {
							if (this.searchInput == v) {
								this.result = value;
							}
						},
						(error) => {},
						() => {
							this.is_loading = false;
						},
					);
				} else {
					this.is_loading = false;
				}
			}, 1300);
		}
	}

	isEventAdded(postId: number) {
		if (this.eventSelected) {
			return this.eventSelected.post_id == postId;
		}
		return false;
	}
	addEvent(user: IPost) {
		this.eventSelected = user;

		if (this.onChanged) {
			this.onChanged(this.eventSelected);
		}
	}
	removeEvent(userId: number) {
		if (this.eventSelected) {
			this.eventSelected = undefined;

			if (this.onChanged) {
				this.onChanged(this.eventSelected);
			}
		}
	}
}
