import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { IProfileMinimal } from 'src/app/core/models/IProfileMinimal';
import { MediaUsageType } from 'src/app/core/models/MediaUsageType';
import { PostMediaType } from 'src/app/core/models/PostMediaType';
import { MediaService } from 'src/app/core/services/media.service';
import { PostMediaService } from 'src/app/core/services/post-media.service';
import { UserService } from 'src/app/core/services/user.service';
import { CreateMedia } from 'src/app/shared/models/CreateMedia';
import { FileHandle } from 'src/app/shared/models/FileHandle';

import { CreateRoomModel } from '../../../../../modules/rooms/models/room.dto';
import { RoomViewModel } from '../../../../../modules/rooms/models/room.view-model';
import { FirebaseRoomService } from '../../../../../modules/rooms/services/firebase-room.service';

@Component({
	selector: 'gpe-create-room',
	templateUrl: './create-room.component.html',
	styleUrls: ['./create-room.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateRoomComponent implements OnChanges {
	public isLoading = false;
	createRoomForm = new FormGroup({
		roomName: new FormControl('', Validators.required),
		isPrivate: new FormControl(false),
		roomDescription: new FormControl('', Validators.required),
	});
	@Input() inputRoomData: RoomViewModel | undefined;
	@Output() cancelEditing = new EventEmitter<void>();

	public coverFile: FileHandle | undefined;
	public coverUrl: SafeUrl | undefined;
	public invitedSelected: IProfileMinimal[] = [];

	constructor(
		private firebaseRoomService: FirebaseRoomService,
		private postMediaService: PostMediaService,
		private sanitizer: DomSanitizer,
		private cdr: ChangeDetectorRef,
		private mediaService: MediaService,
		private userService: UserService,
	) {}

	ngOnChanges(): void {
		if (this.inputRoomData) {
			this.createRoomForm.setValue({
				roomName: this.inputRoomData.name,
				isPrivate: this.inputRoomData.isPrivate,
				roomDescription: this.inputRoomData.description,
			});
			this.coverUrl = this.sanitizer.bypassSecurityTrustUrl(this.inputRoomData.coverUrl);
		}
	}

	setIsPrivate(): void {
		this.createRoomForm.controls.isPrivate.setValue(!this.createRoomForm.value.isPrivate);
	}

	handleFileInput(event: Event): void {
		const element = event.currentTarget as HTMLInputElement;
		const fileList: FileList | null = element.files;
		const file = fileList?.item(0);

		if (!file) {
			//TODO: Toast
			return;
		}

		if (!file.type.match('image.*')) {
			//TODO: Toast
			return;
		}

		this.coverFile = {
			file: file,
			id: 0,
			url: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file)),
			type: PostMediaType.Image,
		};
	}

	public async createRoom(): Promise<void> {
		if (this.createRoomForm.valid) {
			if (this.coverFile) {
				const media: CreateMedia = {
					file: this.coverFile,
					usage_type: MediaUsageType.Chat,
				};
				let mediaId = 0;
				try {
					this.isLoading = true;

					mediaId = await this.postMediaService.uploadMediaToPost(0, media);
				} catch {}

				this.mediaService.getMediaUrl(mediaId).subscribe((url) => {
					const command: CreateRoomModel = {
						invitedUserIds: this.invitedSelected.map((user) => user.user_id),
						isPrivate: this.createRoomForm.value.isPrivate,
						name: this.createRoomForm.value.roomName,
						coverUrl: url,
						description: this.createRoomForm.value.roomDescription,
					};

					this.firebaseRoomService.addRoom(command);

					this.isLoading = false;
					this.cdr.detectChanges();
					this.createRoomForm.setValue({
						roomName: '',
						roomDescription: '',
						isPrivate: this.createRoomForm.value.isPrivate,
					});
					this.cancelEditing.emit();
				});
			}
		}
	}

	public async editRoom(): Promise<void> {
		if (this.inputRoomData) {
			const editRoomData: RoomViewModel = {
				...this.inputRoomData,
				description: this.createRoomForm.value.roomDescription,
				name: this.createRoomForm.value.roomName,
				isPrivate: this.createRoomForm.value.isPrivate,
				invitedUserIds: this.invitedSelected.map((user) => user.user_id),
			};

			if (this.createRoomForm.valid) {
				if (this.coverFile) {
					const media: CreateMedia = {
						file: this.coverFile,
						usage_type: MediaUsageType.Chat,
					};
					let mediaId = 0;
					try {
						this.isLoading = true;

						mediaId = await this.postMediaService.uploadMediaToPost(0, media);
					} catch {}

					this.mediaService.getMediaUrl(mediaId).subscribe((url) => {
						editRoomData.coverUrl = url;

						this.firebaseRoomService.updateRoom(editRoomData);

						this.isLoading = false;
						this.cdr.detectChanges();
						this.cancelEditing.emit();
					});
				} else {
					try {
						this.isLoading = true;
						await this.firebaseRoomService.updateRoom(editRoomData);
					} finally {
						this.isLoading = false;
						this.cdr.detectChanges();
						this.cancelEditing.emit();
					}
				}
			}
		}
	}

	invitedSearch = (searchStr: string, page: number, limit = 15): Observable<IProfileMinimal[]> => {
		const holder = this.userService.searchPossibleInvite(searchStr, page, limit);
		this.cdr.detectChanges();
		return holder;
	};
	invitedChanged = (selected: IProfileMinimal[]): void => {
		this.invitedSelected = selected;
		this.cdr.detectChanges();
	};
}
