import { map } from 'rxjs/operators';
import { ApiResponse } from './../../shared/models/ApiResponse';
import { Observable } from 'rxjs';
import { ILocation, ILocationPut, ILocationGet } from './../models/ILocation';
import { environment } from './../../../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocationService {
	private apiUrl = environment.apiURL;
	constructor(private authService: AuthService, private http: HttpClient) {}

	getLocationId(locationObject: google.maps.places.PlaceResult): Promise<ILocation> {
		return new Promise<ILocation>((resolve, reject) => {
			let loc: ILocationPut = {
				location_key: '',
				location_lat: 0,
				location_long: 0,
				location_title: '',
			};
			if (locationObject.place_id) {
				loc.location_key = locationObject.place_id;
			}

			if (locationObject.geometry?.location.lat) {
				loc.location_lat = locationObject.geometry?.location.lat();
			}

			if (locationObject.geometry?.location.lng) {
				loc.location_long = locationObject.geometry?.location.lng();
			}

			if (locationObject.name) {
				loc.location_title = locationObject.name;
			}

			this.putLocation(loc).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => {
					console.error(error);
					reject();
				},
			);
		});
	}

	putLocation(loc: ILocationPut): Observable<ILocation> {
		const url = `${this.apiUrl}/utility/location`;

		const body = new FormData();
		body.append('location_key', loc.location_key);
		body.append('location_lat', loc.location_lat.toString());
		body.append('location_long', loc.location_long.toString());
		body.append('location_title', loc.location_title);

		return this.http.put<ApiResponse<ILocationGet>>(url, body).pipe(
			map((res) => {
				return res.data.location;
			}),
		);
	}
}
