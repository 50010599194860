import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { RoomViewModel } from 'src/app/modules/rooms/models/room.view-model';
import { RoomService } from 'src/app/modules/rooms/services/room.service';

@Component({
	selector: 'gpe-rooms-modals-container',
	templateUrl: './rooms-modals-container.component.html',
	styleUrls: ['./rooms-modals-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomModalsContainerComponent {
	@Input() modalRef: any;
	@Input() profilePic: string | undefined;
	@Input() selectedRoom: RoomViewModel | undefined;

	public myRooms$: Observable<RoomViewModel[] | null> = this.roomService.getOwnedRooms();

	constructor(private roomService: RoomService) {}

	closeModal(): void {
		this.modalRef.close();
	}
}
