<div class="company-management-header">
	<img src="assets/images/gpe-logo-white.png" class="navbar-logo" (click)="backtoWebsite()" />
	<div class="back-to-website" (click)="backtoWebsite()">
		<img src="assets/icons/back-to-website.svg" class="back-arrow" />
		<span>Back to website</span>
	</div>
</div>
<div class="company-management-content">
	<router-outlet></router-outlet>
</div>
