import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'gpe-company-table-checkbox',
	templateUrl: './company-table-checkbox.component.html',
	styleUrls: ['./company-table-checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyTableCheckboxComponent {
	@Input() public title = '';

	@Output() private isChecked = new EventEmitter<boolean>();

	public checkboxForm = new FormGroup({
		checkbox: new FormControl(false),
	});

	constructor() {
		this.checkboxForm.valueChanges.subscribe((change: { checkbox: boolean }) =>
			this.isChecked.emit(change.checkbox),
		);
	}
}
