<div class="left-sidebar" [ngClass]="{ 'keep-left-sidebar': keepLeftSideBar }">
	<img class="site-logo" src="assets/images/gpe-logo-colored.png" alt="good people everywhere logo" />
	<gpe-upcoming-events-sidebar *ngIf="currentSidebar === SideBarType.UpcomingEvents"></gpe-upcoming-events-sidebar>
	<gpe-settings-sidebar *ngIf="currentSidebar === SideBarType.Settings"></gpe-settings-sidebar>
	<gpe-rooms-sidebar *ngIf="currentSidebar === SideBarType.Rooms"></gpe-rooms-sidebar>
	<gpe-rooms-online-sidebar *ngIf="currentSidebar === SideBarType.RoomsOnline"></gpe-rooms-online-sidebar>
</div>
<div class="main-container">
	<gpe-header class="main-header"></gpe-header>
	<router-outlet></router-outlet>
</div>
<div class="right-sidebar">
	<gpe-chat-messages-sidebar class="d-flex flex-row h-100"></gpe-chat-messages-sidebar>
</div>
