<ng-container *ngIf="post">
	<!-- uploaded: {{ post.post_date_added_timestamp_ms.toString() | dateAgoPipe }} -->
	<gpe-post-type-audio
		*ngIf="post.post_type == PostType.Sound"
		[post]="post"
		[showCommentsClicked]="showCommentsClicked"
		[showShareClicked]="showShareClicked"
		[likePost]="likePost"
		[reportPost]="reportPost"
		[reportUser]="reportUser"
		[hidePost]="hidePost"
		[blockUser]="blockUser"
		[editPost]="editPost"
		[deletePost]="deletePost"
		[navigateToPost]="navigateToPost"
	>
	</gpe-post-type-audio>
	<gpe-post-type-media
		*ngIf="post.post_type == PostType.Media"
		[post]="post"
		[showCommentsClicked]="showCommentsClicked"
		[showShareClicked]="showShareClicked"
		[likePost]="likePost"
		[reportPost]="reportPost"
		[reportUser]="reportUser"
		[hidePost]="hidePost"
		[blockUser]="blockUser"
		[editPost]="editPost"
		[deletePost]="deletePost"
		[navigateToPost]="navigateToPost"
	>
	</gpe-post-type-media>
	<gpe-post-type-text
		*ngIf="post.post_type == PostType.Text"
		[post]="post"
		[showCommentsClicked]="showCommentsClicked"
		[showShareClicked]="showShareClicked"
		[likePost]="likePost"
		[reportPost]="reportPost"
		[reportUser]="reportUser"
		[hidePost]="hidePost"
		[blockUser]="blockUser"
		[editPost]="editPost"
		[deletePost]="deletePost"
		[navigateToPost]="navigateToPost"
	>
	</gpe-post-type-text>

	<div
		*ngIf="
			post.post_type != PostType.Sound &&
			post.post_type != PostType.Picture &&
			post.post_type != PostType.Text &&
			post.post_type != PostType.Video
		"
	>
		<div class="card">
			<div class="alert alert-warning">Unrecognized post format.</div>
		</div>
	</div>
</ng-container>
