import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';

import { IPost, PostType } from '../../models/IPost';
import { IProfileMinimal } from '../../models/IProfileMinimal';
import { PostMediaType } from '../../models/PostMediaType';
import { PostService } from '../../services/post.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'gpe-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnDestroy {
	@Input()
	searchbarActivate: ((nextState: boolean) => void) | undefined;

	searchInput = '';
	debounceTimeout: any = undefined;

	resultUser: IProfileMinimal[] = [];
	resultPost: IPost[] = [];

	PostMediaTypes = PostMediaType;
	PostType = PostType;

	loadingAnimationOptions: AnimationOptions = {
		path: '/assets/lottie/100931-loading.json',
	};

	is_loading = false;

	constructor(
		private router: Router,
		private postService: PostService,
		private userService: UserService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnDestroy(): void {
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}
	}

	inputChanged(): void {
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}
		this.is_loading = false;

		if (this.searchInput != '') {
			this.is_loading = true;
			const v = '' + this.searchInput;
			this.debounceTimeout = setTimeout(() => {
				this.resultPost = [];
				this.resultUser = [];

				const postPromise = new Promise((resolve) => {
					this.postService.getEventsBySearch(v, 1, 5).subscribe(
						(value) => {
							if (v == this.searchInput) {
								this.resultPost = value;
							}
						},
						() => {},
						() => {
							resolve(true);
						},
					);
				});

				const userPromise = new Promise((resolve) => {
					this.userService.searchUser(v, 1, 5).subscribe(
						(value) => {
							if (v == this.searchInput) {
								this.resultUser = value;
							}
						},
						() => {},
						() => {
							resolve(true);
						},
					);
				});

				Promise.all([postPromise, userPromise]).then(() => {
					this.is_loading = false;
					this.cdr.markForCheck();
				});
			}, 100);
		} else {
			this.resultPost = [];
			this.resultUser = [];
		}
	}

	closeSearchbar(): void {
		if (this.searchInput == '') {
			if (this.searchbarActivate) {
				this.searchbarActivate(false);
			}
		}
		this.resultPost = [];
		this.resultUser = [];
		this.searchInput = '';
	}

	jumpToSearch(): void {
		if (this.searchbarActivate) {
			this.searchbarActivate(false);
		}
		const inp = '' + this.searchInput;
		this.searchInput = '';
		this.router.navigate(['/profile/search'], { queryParams: { str: inp } });
	}

	navigateToUser(userId: number): void {
		this.closeSearchbar();
		if (this.searchbarActivate) {
			this.searchbarActivate(false);
		}

		this.router.navigate(['/profile/' + userId]);
	}

	navigateToPost(postId: number): void {
		this.closeSearchbar();
		if (this.searchbarActivate) {
			this.searchbarActivate(false);
		}

		this.router.navigate(['/home/' + postId]);
	}
}
