<div class="title">Share post</div>
You can share post via social platforms.<br /><br />
<share-buttons
	theme="modern-dark"
	[include]="['copy', 'facebook', 'email', 'pinterest', 'viber', 'whatsapp']"
	[showIcon]="true"
	[showText]="true"
	[url]="url"
	description="Share Buttons"
	class="pt-5 mb-5"
>
</share-buttons>
