import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { IPost } from '../../models/IPost';
import { EventService } from '../../services/event.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'gpe-upcoming-events-sidebar',
	templateUrl: './upcoming-events-sidebar.component.html',
	styleUrls: ['./upcoming-events-sidebar.component.scss'],
})
export class UpcomingEventsSidebarComponent implements OnInit {
	constructor(private eventService: EventService) {}

	events$: Observable<IPost[]> = this.eventService.upcomingEvents;

	ngOnInit(): void {
		this.eventService.loadUpcomingEvents();
	}
}
