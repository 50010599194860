import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { EventJoinedMembers } from 'src/app/core/models/IEvent';

@Component({
	selector: 'gpe-event-joined-members-modal',
	templateUrl: './event-joined-members-modal.component.html',
	styleUrls: ['./event-joined-members-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventJoinedMembersModalComponent {
	@Input() joinedMembers: EventJoinedMembers[] | undefined | null;
	@Input() modalRef: any;
	@Input() title: string | undefined;
	@Output() public profileListScrolled = new EventEmitter<void>();

	loadingAnimationOptions: AnimationOptions = {
		path: '/assets/lottie/100931-loading.json',
	};

	constructor(private router: Router) {}

	closeModal(): void {
		this.modalRef.close();
	}

	navigateToProfile(profileId: number): void {
		this.modalRef.close();
		this.router.navigate(['/profile/' + profileId]);
	}

	onProfileListUp(): void {
		this.profileListScrolled.emit();
	}
}
