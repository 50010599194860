import { AttendanceType } from './IEvent';
import { Sex } from './IProfile';
import { IUserMinimal } from './IUserMinimal';
import { PostMedia } from './PostMedia';

export interface IPostGetList {
	posts: IPost[];
}
export interface IPostGetLista {
	list: IPost[];
}
export interface IPostGet {
	post: IPost;
}

export interface IPost {
	post_id: number;
	post_type_id: number;
	post_content_id: number;
	post_user_id: number;
	post_status: number;
	post_like_count: number;
	post_comment_count: number;
	post_join_count: string;
	post_deleted: string;
	post_date_added: string;
	post_date_added_timestamp_ms: number;
	post_modified_date: string;
	post_type_name: string;
	post_content_text: string;
	post_content_title: string;
	post_content_bgcolor: string;
	post_content_date_added: string;
	post_event_id: number;
	post_event_post_id: number;
	post_event_title: string;
	post_event_text: string;
	post_event_subtitle: string;
	post_event_private: string;
	post_event_max_people: number;
	post_event_date_to_epoch?: number;
	post_event_date_from_epoch?: number;
	post_event_image: string;
	post_event_online: string;
	post_event_location_id: number;
	post_event_date_added: string;
	post_event_join_count: number;
	post_link_post_id: number;
	post_location_id: number;
	location_deleted: number;
	location_gps_lat: number;
	location_gps_long: number;
	location_id: number;
	location_title: string;
	post_share_count: number;
	post_type: number;
	user_first_name: string;
	user_last_name: string;
	user_status: string;
	user_deleted: string;
	user_data_avatar: string;
	user_data_sex: Sex;
	share_link: string;
	medias: PostMedia[];
	marked_users: IUserMinimal[];
	marked_users_invited?: IUserMinimal[];
	joined_members: {
		approved: number;
		interested: number;
		nextTime: number;
		invited: number;
		all: number;
	};
	event_join_status?: AttendanceType;
	post_liked?: boolean;
	top_user_liked?: IUserMinimal[];

	status?: {
		icon: string; // URI
		title: string; // Rövid leírás
		description?: string; // Hosszú leírás ami popupban jelenhetne meg
		bg_color?: string;
		text_color?: string;
	};

	isDummy?: boolean;
}

export interface IVideo {
	post_video_id: number;
	post_video_post_id: number;
	post_video_file: string;
	post_video_date_added: string;
}

export interface ISound {
	post_sound_id: number;
	post_sound_post_id: number;
	post_sound_file: string;
	post_sound_date_added: string;
}

export interface IPicture {
	post_picture_id: number;
	post_picture_post_id: number;
	post_picture_file: string;
	post_picture_text: string;
	post_picture_deleted: string;
	post_picture_date_added: string;
}

export enum PostType {
	Text = 1,
	Video = 2,
	Media = 2,
	Picture = 2,
	Sound = 3,
	Event = 4,
}

export interface IPostCreate {
	title: string;
	text: string;
	linked_post_id: number;
	location_id: number;
	bgcolor: string;
	comment_enabled?: boolean;
	marked_users?: string;
	city_id?: number;
	deedbuds?: string;
	has_media?: boolean;
	post_type?: number;
	guestLimit?: number;
}
