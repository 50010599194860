import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MONTH_STRINGS } from 'src/app/modules/calendar/components/calendar-month-stepper/calendar-month-stepper.models';

@Component({
	selector: 'gpe-calendar-month-select',
	templateUrl: './calendar-month-select.component.html',
	styleUrls: ['./calendar-month-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarMonthSelectComponent {
	@Input() public startYear = new Date().getFullYear();
	@Output() private monthSelected = new EventEmitter<Date>();

	public months = MONTH_STRINGS;

	constructor(private datePipe: DatePipe) {}

	public changeYear(amount: 1 | -1): void {
		this.startYear += amount;
	}

	public selectMonth(month: number): void {
		const dateToEmit = new Date(`${this.startYear}.${month + 1}.01`);
		this.monthSelected.emit(dateToEmit);
	}
}
