import { DynamicScriptLoaderService } from './../../services/dynamic-script-loader-service.service';

import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Component, OnInit, Renderer2, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-location-selector',
	templateUrl: './location-selector.component.html',
	styleUrls: ['./location-selector.component.scss'],
})
export class LocationSelectorComponent implements OnInit {
	place: any;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private renderer2: Renderer2,
		private dynamicScriptLoader: DynamicScriptLoaderService,
	) {}

	ngOnInit(): void {
		if (this.startLocation) {
			this.locationStr = this.startLocation;
		}
		this.loadAutoComplete();
	}
	private loadAutoComplete() {
		this.dynamicScriptLoader
			.load('googleplaces')
			.then((data) => {
				this.initAutocomplete();
			})
			.catch((error) => console.log(error));
	}

	SearchPlacesForm!: NgForm;

	@Input()
	appliedClasses: string = '';

	@Input()
	disabled: boolean = false;

	@Input()
	placeholder: string = '';

	@Input()
	startLocation: string | undefined = '';

	@Input()
	locationChanged: ((newLocationObject: google.maps.places.PlaceResult | null) => void) | null = null;

	locationStr: string = '';

	initAutocomplete() {
		const input = document.getElementById('txtSearchPlaces') as HTMLInputElement;

		const autocomplete = new google.maps.places.Autocomplete(input);
		autocomplete.setFields(['place_id', 'geometry', 'icon', 'name']);

		autocomplete.addListener('place_changed', () => {
			this.place = autocomplete.getPlace();
			if (!this.place.geometry) {
				// User entered the name of a Place that was not suggested and
				// pressed the Enter key, or the Place Details request failed.
				alert('No details available for input:' + input.value);
				return;
			} else {
				if (this.locationChanged) {
					this.locationChanged(this.place);
				}
				return;
			}
		});
	}
}
