<div class="right-sidebar d-flex flex-column">
	<form [formGroup]="profileSelectorForm">
		<div class="d-flex" ngbDropdown container="body">
			<button class="notification-button d-flex align-items-center" id="notificationDropdown" ngbDropdownToggle>
				<img class="img" src="/assets/icons/notification.svg" />
				<div class="notification-dot">!</div>
				<div ngbDropdownMenu class="notification-dropdown-container" aria-labelledby="notificationDropdown">
					<gpe-notifications></gpe-notifications>
				</div>
			</button>
			<ng-select
				class="gpe-select mb-2"
				style="width: 100%"
				[items]="profiles | async"
				[clearable]="false"
				formControlName="selectedProfileId"
				bindValue="profile.user_id"
			>
				<ng-template ng-label-tmp let-item="item">
					<span>{{ currentProfileName }}</span>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
					<span>{{ displayProfileName(item) }}</span>
				</ng-template>
			</ng-select>
		</div>
	</form>
	<div class="d-flex flex-row title-wrapper">
		<div class="d-flex align-items-center messages-title">
			Messages
			<div *ngIf="unreadChatCount$ | async as count" class="dot">
				{{ count }}
			</div>
		</div>
		<div class="d-flex flex-row title-btns justify-content-end">
			<div class="search-wrapper">
				<form [formGroup]="searchForm" class="search-input-box">
					<input
						class="search-input form-control"
						type="text"
						placeholder="Search"
						formControlName="searchTerm"
					/>
					<button class="title-btn d-flex align-items-center">
						<img class="img" src="/assets/icons/search-green.svg" />
					</button>
				</form>
			</div>
			<!-- <button class="title-btn d-flex align-items-center">
					<img class="img" src="/assets/icons/add-green.svg" />
				</button> -->
		</div>
	</div>
	<div *ngIf="chatRoomList$ | async as sidebarChatRooms">
		<div *ngIf="sidebarChatRooms.length > 0; else emptySideBar" class="d-flex flex-column">
			<div
				*ngFor="let room of sidebarChatRooms; index as i"
				infiniteScroll
				[scrollWindow]="false"
				[infiniteScrollDistance]="2"
				[infiniteScrollThrottle]="10"
				(scrolled)="onChatListUp()"
				class="d-flex flex-row justify-content-center mt-2"
			>
				<div
					[ngClass]="
						(currentlyHighlighted$ | async) === room.chatRoom?.id ? 'selected-wrapper' : 'text-wrapper'
					"
					class="d-flex flex-column h-100 w-100"
					(click)="chatSelected(room.userId, i, room.chatRoom?.id)"
				>
					<div class="d-flex flex-row name-wrapper align-items-center justify-content-between">
						<div class="title d-flex flex-row w-100 align-items-center">
							<gpe-profile-picture
								[width]="35"
								[pictureUrl]="room.profilePictureSource"
							></gpe-profile-picture>
							<div class="d-flex flex-row name-and-notify align-items-center">
								{{ room.userName }}
								<div class="d-flex flex-row notification">
									<img *ngIf="room.unreadTextAmount > 0" src="/assets/icons/notification-dot.svg" />
								</div>
							</div>
						</div>
						<!-- <div class="d-flex flex-row chat-options">
							<img clas="option-img" *ngIf="true" src="/assets/icons/3-dot.svg" alt="" />
						</div> -->
					</div>
					<div *ngIf="!searchForm.value.searchTerm" class="d-flex flex-row w-100 justify-content-between">
						<div class="last-text-wrapper text-truncate d-flex flex-row">
							{{ room.latestText }}
						</div>
						<div class="d-flex flex-row mr-2 text-ago align-items-center">
							{{ getTimeStamp(room.chatRoom?.lastMessage_timeStampInSec?.seconds) | dateAgoPipeMinimal }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-template #emptySideBar>
			<div *ngIf="!searchForm.value.searchTerm" class="d-flex flex-column align-items-center right-sidebar">
				<div class="d-flex no-msg">There are no messages</div>
				<!-- <button class="d-flex empty-chat-btn align-items-center" (click)="navigateToChat()">
					Start chatting with your buddies
				</button> -->
			</div>
			<div *ngIf="searchForm.value.searchTerm" class="d-flex flex-column align-items-center right-sidebar">
				<div class="d-flex no-msg">No search result found</div>
			</div>
		</ng-template>
	</div>
</div>

<ng-template #createCompanyDialog let-modal>
	<gpe-create-company-dialog [modalRef]="modal"></gpe-create-company-dialog>
</ng-template>
