import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Room } from 'src/app/modules/rooms/models/room.dto';
import { RoomService } from 'src/app/modules/rooms/services/room.service';

import { UserService } from '../../services/user.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'gpe-rooms-online-sidebar',
	templateUrl: './rooms-online-sidebar.component.html',
	styleUrls: ['./rooms-online-sidebar.component.scss'],
})
export class RoomsOnlineSidebarComponent {
	constructor(private roomService: RoomService, private userService: UserService) {}

	selectedRoom$ = this.roomService.selectedRoom;

	private get currentuserId() {
		return +this.userService.getLoginedUserId();
	}

	public isPartOf(room: Room): boolean {
		return (
			room.userIds.some((userId) => userId === this.currentuserId) ||
			room.anonymousUserIds.some((userId) => userId === this.currentuserId)
		);
	}
}
