import { IPost } from './../../../core/models/IPost';
import { EventService } from './../../../core/services/event.service';
import { Component, OnInit } from '@angular/core';
import { PostMediaType } from 'src/app/core/models/PostMediaType';

@Component({
	selector: 'gpe-sidebar-default',
	templateUrl: './sidebar-default.component.html',
	styleUrls: ['./sidebar-default.component.scss'],
})
export class SidebarDefaultComponent implements OnInit {
	constructor(private eventService: EventService) {}

	upcomingEvents: IPost[] = [];
	topEvents: IPost[] = [];

	ngOnInit(): void {
		this.eventService.getUpcomingEvents().subscribe((val) => {
			for (let v of val) {
				this.upcomingEvents.push(v);
				return;
			}
		});
		this.eventService.getTopEvents().subscribe((val) => {
			let added = 0;
			for (let v of val) {
				this.topEvents.push(v);
				added++;
				if (added > 1) {
					return;
				}
			}
		});
	}

	getBackground(event: IPost) {
		let str = '';
		if (event && event.medias && event.medias.length > 0) {
			if (event.medias[0].type == PostMediaType.Image) {
				str = event.medias[0].picture.file;
			}
			if (event.medias[0].type == PostMediaType.Video) {
				str = event.medias[0].video.thumbnail ? event.medias[0].video.thumbnail : '';
			}
		}
		return '#00c19c url("' + str + '")  no-repeat center center / cover';
	}

	getDayLeft(event: IPost) {
		if (event.post_event_date_from_epoch && event.post_event_date_to_epoch) {
			let now = new Date();
			let date1 = new Date(Math.round(event.post_event_date_from_epoch / 1000));
			let date2 = new Date(Math.round(event.post_event_date_to_epoch / 1000));

			let tDiff = date1.getTime() - now.getTime();
			let days = Math.floor(tDiff / (1000 * 3600 * 24));

			if (days > 1) {
				return days + ' day left';
			} else if (days > 0) {
				return 'Start soon';
			} else if (date2.getTime() > now.getTime()) {
				return 'Just going';
			} else {
				return 'Is over';
			}
		}
		return '';
	}
}
