import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AnimationOptions } from 'ngx-lottie';

import { ICity } from '../../../../core/models/ICity';
import { ICountry } from '../../../../core/models/ICountry';
import { UtilityService } from '../../../../core/services/utility.service';
import { IGenderEnum } from '../../../models/IGenderEnum';

export enum InputType {
	email = 'email',
	password = 'password',
	passwordAgain = 'passwordAgain',
	firstName = 'firstName',
	lastName = 'lastName',
	birthDate = 'birthDate',
	sex = 'sex',
	city = 'city',
	language = 'language',
	country = 'country',
	city_country = 'city_country',
}

@Component({
	selector: 'gpe-auth-input-field',
	templateUrl: './auth-input-field.component.html',
	styleUrls: ['./auth-input-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthInputFieldComponent implements OnInit {
	@Input() inputType!: InputType;
	@Input() _formGroup!: FormGroup;
	@Input() submitted: boolean;

	hide = true;
	displayPassConfError = false;

	maleClicked = false;
	femaleClicked = false;
	otherClicked = false;
	readonly InputType = InputType;

	countryResult: ICountry[] = [];
	cityResult: ICity[] = [];
	debounceTimeout: number | undefined = undefined;

	is_loading = false;
	loadingAnimationOptions: AnimationOptions = {
		path: '/assets/lottie/100931-loading.json',
	};
	searchSelectorOpen = false;

	languageList = [
		{
			id: 'en',
			name: 'auth.english',
		} /*,
		{
			id: 'hu',
			name: 'auth.hungarian',
		},*/,
	];

	constructor(private utilityService: UtilityService, private cdr: ChangeDetectorRef) {
		this.submitted = false;
	}

	ngOnInit(): void {}

	focusInput: boolean = false;
	onBlur() {
		this.focusInput = false;
	}
	onFocus() {
		this.focusInput = true;
	}
	maleButtonClick() {
		this.maleClicked = true;
		this.femaleClicked = false;
		this.otherClicked = false;
		this._formGroup?.controls?.sex?.setValue(IGenderEnum.MALE.toString());
	}

	femaleButtonClick() {
		this.maleClicked = false;
		this.femaleClicked = true;
		this.otherClicked = false;
		this._formGroup?.controls?.sex?.setValue(IGenderEnum.FEMALE.toString());
	}

	otherButtonClick() {
		this.maleClicked = false;
		this.femaleClicked = false;
		this.otherClicked = true;
		this._formGroup?.controls?.sex?.setValue(IGenderEnum.OTHER.toString());
	}

	getAge(dateString: string) {
		const today = new Date();
		const birthDate = new Date(dateString);
		let age = today.getFullYear() - birthDate.getFullYear();
		const m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	//TODO: make new component for city and country input
	cityInput() {
		this.searchSelectorOpen = true;
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
			this.debounceTimeout = undefined;
		}

		if (this._formGroup.controls.city.value != '') {
			this.is_loading = true;
			const v = '' + this._formGroup.controls.city.value;
			this.debounceTimeout = setTimeout(() => {
				this.utilityService.getCityBySearch(v).subscribe(
					(value) => {
						if (v == this._formGroup.controls.city.value) {
							this.cityResult = value;
						}
					},
					(error) => {},
					() => {
						this.is_loading = false;
						this.cdr.detectChanges();
					},
				);
			}, 1500);
		} else {
			this.searchSelectorOpen = false;
			this.countryResult = [];
			this.is_loading = false;
			this.cdr.detectChanges();
		}
	}
	selectCity(city: ICity) {
		this.searchSelectorOpen = false;
		this._formGroup.controls.cityId.setValue(city.city_id);
		this._formGroup.controls.city.setValue(city.city_name);
		this._formGroup.controls.country.setValue(city.country_name);
		this._formGroup.controls.countryId.setValue(city.country_id);
	}
	countryInput() {
		this.searchSelectorOpen = true;
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
			this.debounceTimeout = undefined;
		}
		if (this._formGroup.controls.country.value != '') {
			this.is_loading = true;
			const v = '' + this._formGroup.controls.country.value;
			this.debounceTimeout = setTimeout(() => {
				this.utilityService.getCountryBySearch(v).subscribe(
					(value) => {
						if (v == this._formGroup.controls.country.value) {
							this.countryResult = value;
						}
					},
					(error) => {},
					() => {
						this.is_loading = false;
						this.cdr.detectChanges();
					},
				);
			}, 1200);
		} else {
			this.searchSelectorOpen = false;
			this.countryResult = [];
			this.is_loading = false;
			this.cdr.detectChanges();
		}
	}
	selectCountry(country: ICountry) {
		this.searchSelectorOpen = false;
		//TODO: !!!
		this._formGroup.controls.countryId.setValue(country.country_id);
		this._formGroup.controls.country.setValue(country.country_name);
	}
}
