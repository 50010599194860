import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
	selector: 'gpe-profile-picture',
	templateUrl: './profile-picture.component.html',
	styleUrls: ['./profile-picture.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePictureComponent {
	@Input() public pictureUrl: string | undefined | SafeUrl;
	@Input() public width = 35;
	@Input() public isHighlighted = false;
	@Input() public notificationCount = 0;
	@Input() public isBlocked: boolean | undefined;

	public defaultProfileUrl = '/assets/icons/user.svg';
}
