import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IComment } from '../../../core/models/IComment';
import { IUserMinimal } from '../../../core/models/IUserMinimal';

@Component({
	selector: 'gpe-user-inline',
	templateUrl: './user-inline.component.html',
	styleUrls: ['./user-inline.component.scss'],
})
export class UserInlineComponent implements OnInit {
	@Input()
	userId: number | undefined;

	@Input()
	userData: IUserMinimal[] | undefined;

	@Input()
	userFollowing: ((userId: number, nextState: boolean, comments?: IComment[] | undefined) => void) | undefined;

	actualUserData: IUserMinimal | undefined;

	private isTooltipOpen = false;

	constructor(private router: Router) {}

	ngOnInit(): void {
		if (this.userId && this.userData) {
			const filtered = this.userData.filter((value) => value.user_id == this.userId);
			if (filtered.length > 0) {
				this.actualUserData = filtered.pop();
			}
		}
	}

	closeTimeout: any;
	openTooltip(tooltip: any): void {
		if (!this.isTooltipOpen) {
			this.closeTimeout = setTimeout(() => {
				tooltip.open();
				this.isTooltipOpen = true;
			}, 500);
		} else {
			clearTimeout(this.closeTimeout);
		}
	}

	closeTooltip(tooltip: any): void {
		if (!this.isTooltipOpen) {
			clearTimeout(this.closeTimeout);
		} else {
			this.closeTimeout = setTimeout(() => {
				tooltip.close();
				this.isTooltipOpen = false;
			}, 1000);
		}
	}

	navigateToUser(userId: number): void {
		this.router.navigate(['/profile/' + userId]);
	}

	messageUser(userId: number): void {
		this.router.navigate(['/chat', { userId }]);
	}
}
