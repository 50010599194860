import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'minuteSeconds',
})
export class MinuteSecondsPipe implements PipeTransform {
	transform(value: number | undefined): string {
		if (value) {
			value = Math.round(value);
			const minutes: number = Math.floor(value / 60);
			const seconds: number = value - minutes * 60;
			return String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');
		} else {
			return '00:00';
		}
	}
}
