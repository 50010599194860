<div>
	<a class="settings-menu" routerLink="/settings/about-us" routerLinkActive="active"
		><span class="settings-menu-point">•</span><span class="settings-menu-label">About Us</span></a
	>
	<a class="settings-menu" routerLink="/settings/invite" routerLinkActive="active"
		><span class="settings-menu-point">•</span><span class="settings-menu-label">Invite friends</span></a
	>
	<a class="settings-menu" routerLink="/settings/blocked-users" routerLinkActive="active"
		><span class="settings-menu-point">•</span><span class="settings-menu-label">Blocked users</span></a
	>
	<div class="settings-menu" (click)="logout()">
		<span class="settings-menu-point">•</span><span class="settings-menu-logout">Logout</span>
	</div>
</div>
