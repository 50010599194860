<a *ngIf="modalRef" (click)="closeModal()" class="modal-close-button">Bezár</a>
<h1 class="title">Suggest idea</h1>
<div class="text mt-3">
	Do you have an idea how we could improve the Good People app? Suggest an idea to us, and we will consider making it
	real for you. :)
	<br />
	<input type="text" class="form-control mt-5" placeholder="Subject" [(ngModel)]="subject" />
	<textarea class="form-control mt-2" placeholder="Description" rows="6" [(ngModel)]="description"></textarea>

	<span class="btn post-button-add cursor-pointer mt-2" (click)="send()">Send</span>
</div>
