import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../services/auth.service';

import { PostService } from './../services/post.service';

@Component({
	selector: 'gpe-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	constructor(
		private postService: PostService,
		private modalService: NgbModal,
		private authService: AuthService,
		private router: Router,
	) {}

	searchBarActivated = false;

	newPostId = 0;

	@ViewChild('createPostModal', { read: TemplateRef }) createPostModal!: TemplateRef<any>;

	ngOnInit(): void {
		this.postService.openNewPostModal.subscribe((value) => {
			this.openCreateNewPost(this.createPostModal, value);
		});
	}

	openCreateNewPost(content: any, newPostId: number): void {
		this.newPostId = newPostId;
		this.modalService.open(content, {
			backdropClass: 'xlmodal-backdrop-style',
			size: 'xl',
			centered: true,
			scrollable: false,
			modalDialogClass: 'xlmodal-class',
			beforeDismiss: () => {
				return false;
			},
		});
	}

	activateSearchBar = (nextState: boolean): void => {
		this.searchBarActivated = nextState;
	};
}
