import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { user } from '@angular/fire/auth';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ChatRoomService } from 'src/app/modules/chat/services/chat-room.service';
import { FirebaseService } from 'src/app/modules/chat/services/firebase.service';
import { User } from 'src/app/shared/models/user';

import { ISidebarChatRoom } from '../../models/firebasechat/ISidebarChatRoom';
import { AuthService } from '../../services/auth.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'gpe-chat-messages-sidebar',
	templateUrl: './chat-messages-sidebar.component.html',
	styleUrls: ['./chat-messages-sidebar.component.scss'],
})
export class ChatMessagesSidebarComponent implements OnInit {
	private chatLimit = 40;

	public chatRoomList$: Observable<ISidebarChatRoom[]> = this.chatRoomService.getChatRooms();
	public unreadChatCount$ = this.chatRoomService.getUnreadCount();

	public currentlyHighlighted$: Observable<string> = this.chatRoomService.getHiglighted();

	public searchForm = new FormGroup({
		searchTerm: new FormControl(''),
	});

	public profileSelectorForm = new FormGroup({
		selectedProfileId: new FormControl(this.authService.currentUserValue.logined_userid),
	});

	public selectedProfile = `${this.authService.currentUserValue.profile?.user_first_name} ${this.authService.currentUserValue.profile?.user_last_name}`;

	public profiles = this.authService.currentCompanies.pipe(
		map((companies) => {
			const users = companies.tokens;
			if (companies.mainProfile && users[0]?.logined_userid !== companies.mainProfile.logined_userid) {
				users.unshift(companies.mainProfile);
			}
			return users;
		}),
	);

	@ViewChild('createCompanyDialog', { read: TemplateRef }) createCompanyModal!: TemplateRef<any>;

	constructor(
		private chatRoomService: ChatRoomService,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private authService: AuthService,
		private readonly modalService: NgbModal,
		private readonly fireBaseService: FirebaseService,
	) {
		this.profileSelectorForm.valueChanges.subscribe((change) => {
			if (change.selectedProfileId) {
				this.fireBaseService.logout();
				this.profiles.subscribe((profiles) => {
					this.authService.changeProfile(
						profiles.find((p) => p.profile.user_id === change.selectedProfileId) ?? ({} as User),
					);
					this.fireBaseService.login();
				});
			} else if (change.selectedProfileId === 0) {
				this.createNewCompany();
			}
		});
	}

	ngOnInit(): void {
		this.chatRoomService.loadChatRooms(this.chatLimit).subscribe();

		this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe((change: { searchTerm: string }) => {
			if (change.searchTerm) {
				this.chatRoomList$ = this.chatRoomService.getChatRooms(change.searchTerm);
			} else {
				this.chatRoomList$ = this.chatRoomService.getChatRooms();
			}

			this.cdr.detectChanges();
		});
		this.currentlyHighlighted$ = this.chatRoomService.getHiglighted();
	}

	public onChatListUp(): void {
		this.chatLimit += 10;
		this.chatRoomList$ = this.chatRoomService.loadChatRooms(this.chatLimit);
	}

	public count(chats: ISidebarChatRoom[]): number {
		let counter = 0;
		chats.forEach((element) => {
			if (element.unreadTextAmount > 0) {
				counter++;
			}
		});
		return counter;
	}

	public chatSelected(userId: number, ind: number, roomId?: string): void {
		if (roomId) {
			this.chatRoomService.setHiglighted(roomId);
		} else {
			this.chatRoomService.setHiglighted('-1');
		}

		this.searchForm.controls.searchTerm.setValue('');

		this.router.navigate(['/chat', { userId }]);
	}

	public navigateToChat(): void {
		this.router.navigate(['/chat']);
	}

	public getTimeStamp(seconds?: number): string {
		if (seconds) {
			return seconds.toString();
		}
		return '0';
	}

	public displayProfileName(user: User): string {
		return `${user.profile?.user_first_name} ${user.profile?.user_last_name}`;
	}

	public get currentProfileName(): string {
		return `${this.authService.currentUserValue.profile.user_first_name} ${this.authService.currentUserValue.profile.user_last_name}`;
	}

	private createNewCompany(): void {
		this.modalService.open(this.createCompanyModal, {
			backdropClass: 'lgmodal-backdrop-style',
			size: 'lg',
			centered: true,
			scrollable: false,
			modalDialogClass: 'lgmodal-class',
			beforeDismiss: () => {
				return false;
			},
		});
	}
}
