import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { MultiSelectInputModel } from './multi-select-with-search.model';

@Component({
	selector: 'gpe-multi-select-with-search',
	templateUrl: './multi-select-with-search.component.html',
	styleUrls: ['./multi-select-with-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectWithSearchComponent {
	@Input() public title = '';
	@Input() public items: MultiSelectInputModel[] = [];

	@Output() searchTerm = new EventEmitter<string>();
	@Output() selectionChange = new EventEmitter<string[]>();

	public searchForm = new FormGroup({
		searchTerm: new FormControl(''),
	});

	private selectedItems: string[] = [];

	constructor() {
		this.searchForm.valueChanges
			.pipe(debounceTime(500))
			.subscribe((change: { searchTerm: string }) => this.searchTerm.emit(change.searchTerm));
	}

	public selectItem(itemId: string): void {
		if (this.selectedItems.includes(itemId)) {
			this.selectedItems = this.selectedItems.filter((item) => item != itemId);
		} else {
			this.selectedItems.push(itemId);
		}

		this.selectionChange.emit(this.selectedItems);
	}
}
