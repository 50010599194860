<div class="header">
	<a class="header-link" routerLink="/home" routerLinkActive="active-link"
		><img src="assets/icons/home-black.svg" class="header-link-icon" />
		<span routerLinkActive="active-link-label" class="header-link-label">Home</span></a
	>
	<a class="header-link" (click)="activateSearchBar(true)"
		><img src="assets/icons/search-black.svg" class="header-link-icon"
	/></a>
	<a class="header-link" routerLink="/rooms" routerLinkActive="active-link"
		><img src="assets/icons/chat-bubbles-black.svg" class="header-link-icon" /><span
			routerLinkActive="active-link-label"
			class="header-link-label"
			>Rooms</span
		></a
	>

	<div class="add-link-container">
		<img src="assets/icons/add.svg" class="header-link-icon" (click)="openCreateNewPost(createPostModal, 0)" />
	</div>

	<a class="header-link" routerLink="/calendar" routerLinkActive="active-link"
		><img src="assets/icons/calendar-black.svg" class="header-link-icon" /><span
			routerLinkActive="active-link-label"
			class="header-link-label"
			>Events</span
		></a
	>

	<a class="header-link" routerLink="/profile" routerLinkActive="active-link"
		><img src="assets/icons/user-black.svg" class="header-link-icon" /><span
			routerLinkActive="active-link-label"
			class="header-link-label"
			>Profile</span
		></a
	>

	<a class="header-link" routerLink="/settings" routerLinkActive="active-link"
		><img src="assets/icons/settings-black.svg" class="header-link-icon" /><span
			routerLinkActive="active-link-label"
			class="header-link-label"
			>Settings</span
		></a
	>

	<gpe-search-bar
		class="searchbar"
		[ngClass]="{ visible: searchBarActivated }"
		[searchbarActivate]="activateSearchBar"
	></gpe-search-bar>
</div>

<ng-template #createPostModal let-modal>
	<gpe-create-post-base-component [postId]="newPostId" [modalRef]="modal"></gpe-create-post-base-component>
</ng-template>
