import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';
import { IPost } from 'src/app/core/models/IPost';

import { IProfile } from '../../../../../core/models/IProfile';
import { MediaUsageType } from '../../../../../core/models/MediaUsageType';
import { PostMediaService } from '../../../../../core/services/post-media.service';
import { UserProfileService } from '../../../../../core/services/user-profile.service';
import { CreateMedia } from '../../../../models/CreateMedia';

import { PostType } from './../../../../../core/models/IPost';
import { PostService } from './../../../../../core/services/post.service';

@Component({
	selector: 'gpe-create-post-base-component',
	templateUrl: './base-component.component.html',
	styleUrls: ['./base-component.component.scss'],
})
export class BaseComponentComponent implements OnInit {
	@Input()
	modalRef: any;

	@Input()
	postId!: number;

	post: IPost | undefined;

	bgColors: string[] = [];

	profile: IProfile | undefined;

	activeTab = 'post';

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	is_loading = true;

	constructor(
		private userProfileService: UserProfileService,
		private postMediaService: PostMediaService,
		private sanitizer: DomSanitizer,
		private postService: PostService,
	) {}

	ngOnInit(): void {
		const userget = new Promise<boolean>((resolve) => {
			this.userProfileService.getProfile().subscribe((value) => {
				this.profile = value;
				resolve(true);
			});
		});

		const colorget = new Promise<boolean>((resolve) => {
			this.postMediaService.getColors().subscribe((value) => {
				this.bgColors = value;
				resolve(true);
			});
		});
		const promisesArr = [userget, colorget];

		if (this.postId > 0) {
			const postGet = new Promise<boolean>((resolve) => {
				this.postService.getMyPostById(this.postId).subscribe((value) => {
					this.post = value;
					switch (value.post_type) {
						case PostType.Event:
							this.activeTab = 'event';
							break;
						case PostType.Media:
							this.activeTab = 'post';
							break;
						case PostType.Text:
							this.activeTab = 'text';
							break;
						case PostType.Sound:
							this.activeTab = 'voice';
							break;
					}

					resolve(true);
				});
			});
			promisesArr.push(postGet);
		}

		Promise.all(promisesArr).finally(() => {
			this.is_loading = false;
		});
	}

	uploadAttachments = async (postId: number, postMedia: CreateMedia[]): Promise<boolean> => {
		let uploadedNum = 0;

		for (const media of postMedia) {
			if (media.file.id && media.file.id > 0) {
				uploadedNum++;
				media.mediaId = media.file.id;
			} else {
				const upRes = await this.postMediaService.uploadMediaToPost(postId, media);
				if (upRes > 0) {
					uploadedNum++;
					media.mediaId = upRes;

					let strImage: string | null = null;
					if (media.file.thumbnailBase64) {
						strImage = media.file.thumbnailBase64;
					}

					await new Promise<boolean>((resolve) => {
						if (media.mediaId) {
							this.postMediaService.mediaUploaded(media.mediaId, strImage).subscribe(
								(value) => {
									resolve(true);
								},
								(error) => {
									resolve(false);
								},
							);
						}
					});
				}
			}
		}

		const ids: number[] = [];

		for (const media of postMedia) {
			if (media.mediaId && media.file.primary) {
				ids.push(media.mediaId);
			}
		}
		for (const media of postMedia) {
			if (media.mediaId && !media.file.primary) {
				ids.push(media.mediaId);
			}
		}

		await new Promise<boolean>((resolve) => {
			this.postMediaService.joinMedia(ids, postId, MediaUsageType.Post).subscribe(
				(value) => {
					resolve(true);
				},
				(error) => {
					resolve(false);
				},
			);
		});

		return uploadedNum == postMedia.length;
	};
	setTab(newTab: string) {
		this.activeTab = newTab;
	}

	closeModal = () => {
		this.modalRef.close('Close click');
	};
}
