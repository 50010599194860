import { BaseLoginProvider, SocialUser } from 'angularx-social-login';
import { LoginComponent } from '../components/login/login.component';
import { IAppleLoginSuccess } from '../models/IAppleLoginSuccess';

declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {
	public static readonly PROVIDER_ID: string = 'APPLE';

	protected auth2: any;

	constructor(private clientId: string, private scope: string, private redirectURI: string) {
		super();
	}

	initialize(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.loadScript(
				AppleLoginProvider.PROVIDER_ID,
				'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
				() => {
					this.auth2 = AppleID.auth.init({
						clientId: this.clientId,
						scope: this.scope,
						redirectURI: this.redirectURI,
						usePopup: true,
					});
					resolve();
				},
			);
		});
	}
	soc = new SocialUser();

	signIn(opt?: LoginComponent): Promise<SocialUser> {
		return new Promise(async (resolve, reject) => {
			try {
				let rawData = await AppleID.auth.signIn();
				// Handle successful response.
				let data = rawData as IAppleLoginSuccess;

				this.soc.provider = AppleLoginProvider.PROVIDER_ID;
				this.soc.idToken = data.authorization.id_token;
				this.soc.authToken = data.authorization.code;

				if (data.user) {
					if (data.user.email) {
						this.soc.email = data.user.email;
					} else {
						this.soc.email = '';
					}
					if (data.user.name) {
						if (data.user.name.firstName) {
							this.soc.firstName = data.user.name.firstName;
						} else {
							this.soc.firstName = '';
						}
						if (data.user.name.lastName) {
							this.soc.lastName = data.user.name.lastName;
						} else {
							this.soc.lastName = '';
						}
					}
				}

				resolve(this.soc);
			} catch (error) {
				reject(error);
			}
		});
	}

	getLoginStatus(): Promise<SocialUser> {
		return new Promise((resolve, reject) => {
			if (this.soc.idToken && this.soc.idToken.length > 0) {
				resolve(this.soc);
			} else {
				reject('not have login');
			}
		});
	}
	signOut(revoke?: boolean): Promise<any> {
		return new Promise((r) => {
			this.soc = new SocialUser();
			r(true);
		});
	}
}
