<a (click)="closeModal()" class="modal-close-button">Close</a>
<div class="gpe-modal-content">
	<span class="title">Create new company</span>
	<div class="section" *ngIf="pageCount === 0">
		<span class="section-title">Media</span>
		<div class="gpe-modal-row">
			<gpe-upload-image-input
				displayTitle="Upload profile picture"
				(fileSelected)="profileFile = $event"
				[inputFile]="profileFile"
			></gpe-upload-image-input>
			<gpe-upload-image-input
				displayTitle="Upload cover image"
				(fileSelected)="coverFile = $event"
			></gpe-upload-image-input>
		</div>
	</div>
	<div class="section">
		<span class="section-title">Basic Details</span>
		<form [formGroup]="createCompanyForm" class="gpe-modal-form">
			<div *ngIf="pageCount === 0; else secondPage">
				<input
					type="text"
					class="form-control input-fields input-text"
					placeholder="Company name"
					formControlName="name"
				/>
				<input
					type="text"
					class="form-control input-fields input-text"
					placeholder="Location"
					formControlName="location"
				/>
				<ng-select
					class="gpe-select quarter-dropdown"
					[items]="industries | async"
					placeholder="Industry"
					[clearable]="false"
					formControlName="industry"
					bindLabel="name"
					bindValue="id"
				>
				</ng-select>
				<ng-select
					class="gpe-select quarter-dropdown"
					[items]="companySizes | async"
					placeholder="Company size"
					[clearable]="false"
					formControlName="size"
					bindLabel="name"
					bindValue="id"
				>
				</ng-select>
				<input
					type="text"
					class="form-control input-fields input-text"
					placeholder="Website URL"
					formControlName="url"
				/>
				<input
					type="text"
					class="form-control input-fields input-text"
					placeholder="GPE Public URL"
					formControlName="gpeUrl"
				/>
				<ng-select
					class="gpe-select quarter-dropdown"
					[items]="companyTypes | async"
					placeholder="Company type"
					[clearable]="false"
					formControlName="type"
					bindLabel="name"
					bindValue="id"
				>
				</ng-select>
			</div>
			<ng-template #secondPage>
				<div class="section-title">Company specific interests</div>
				<gpe-select-interests></gpe-select-interests>
				<div class="section-title">Introduction</div>
				<textarea class="form-control" placeholder="Your bio" formControlName="bio" rows="5"></textarea>
				<textarea class="form-control" placeholder="Your story" formControlName="story" rows="5"></textarea>
			</ng-template>

			<div class="actions-row">
				<button class="btn btn-primary" (click)="changePage()">
					{{ pageCount === 0 ? 'Next Page' : 'Previus Page' }}
				</button>
				<button class="btn btn-primary" [disabled]="!createCompanyForm.valid" (click)="createCompany()">
					Create Company
				</button>
			</div>
		</form>
	</div>
</div>
