import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FileHandle } from '../../../models/FileHandle';
import { MediaSelectorComponent } from '../../posts/create/common/media-selector/media-selector.component';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { IUserType } from '../../../../core/models/IUserBase';
import { UserProfileService } from '../../../../core/services/user-profile.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ICover } from 'src/app/core/models/ICover';
import Swal from 'sweetalert2';

@Component({
	selector: 'gpe-edit-cover',
	templateUrl: './edit-cover.component.html',
	styleUrls: ['./edit-cover.component.scss'],
})
export class EditCoverComponent implements OnInit {
	constructor(private userProfileService: UserProfileService, private router: Router, private http: HttpClient) {}

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	@ViewChild(MediaSelectorComponent) mediaSelector: MediaSelectorComponent | undefined;

	@Input()
	userType: IUserType = IUserType.USER;

	@Input()
	vertical: boolean = true;

	actualIndex = 0;
	is_loading = false;
	covers: ICover[] = [];
	selectedMedias: FileHandle[] = [];

	ngOnInit(): void {
		this.userProfileService.getCovers(this.userType).subscribe((val) => {
			this.covers = val;
		});
	}

	next() {
		if (this.covers.length - 1 > this.actualIndex) {
			this.actualIndex++;
		}
	}
	prev() {
		if (this.actualIndex > 0) {
			this.actualIndex--;
		}
	}
	saveCover() {
		this.userProfileService.setBasicCovers(this.covers[this.actualIndex].covers_id).subscribe((val) => {
			window.location.reload();
		});
	}

	saveCustomCover() {
		if (this.selectedMedias.length > 0) {
			this.is_loading = true;

			const formData: FormData = new FormData();
			formData.append('image', this.selectedMedias[0].file, '' + new Date().getTime() + '.jpg');
			const headers: HttpHeaders = new HttpHeaders();
			headers.set('Content-Type', 'multipart/form-data');

			let url = `${environment.apiURL}/user/set_cover`;
			this.http.post(url, formData, { headers: headers }).subscribe(
				(val) => {
					window.location.reload();
				},
				(err) => {
					this.is_loading = false;
				},
			);
		} else {
			Swal.fire({
				icon: 'error',
				text: 'Select image first.',
			});
		}
	}

	removeCustomCover() {
		this.selectedMedias.length = 0;
	}

	selectedMediasChanged = () => {};

	openMediaSelector() {
		if (this.mediaSelector) {
			this.mediaSelector.openSelector();
		}
	}
}
