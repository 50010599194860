<div class="title">Add text</div>

<div *ngIf="is_loading" class="text-center mt-2">
	<div class="loading-animation-container">
		<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
	</div>
</div>

<div [class.disableall]="is_loading">
	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-6">
					<input
						type="text"
						class="form-control input-fields input-text"
						[class.input-error]="submitted && hasErrorColumn('title')"
						(input)="checkRequiredColumns()"
						placeholder="Post title"
						[(ngModel)]="newPostObj.title"
					/>

					<div *ngIf="submitted && hasErrorColumn('title')" class="invalid-feedback">
						<div class="error-message" translate>
							This field is required and a minimum of 3 characters is required. Please fill it out to
							proceed.
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<app-location-selector
						[appliedClasses]="'form-control input-fields input-text'"
						[startLocation]="post?.location_title"
						[locationChanged]="locationChanged"
						[placeholder]="'Location'"
					></app-location-selector>
				</div>
				<div class="col-md-12 mt-2">
					<textarea
						class="form-control input-fields input-textarea"
						placeholder="Description"
						[class.input-error]="submitted && hasErrorColumn('text')"
						(input)="checkRequiredColumns()"
						rows="5"
						[(ngModel)]="newPostObj.text"
					></textarea>

					<div *ngIf="submitted && hasErrorColumn('text')" class="invalid-feedback">
						<div class="error-message" translate>
							This field is required and a minimum of 3 characters is required. Please fill it out to
							proceed.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-4">
					<div ngbDropdown class="d-block datepicker-dropdown" (openChange)="toggled($event, 1)">
						<button
							type="button"
							class="btn btn-block btn-outline-primary post-selector-button"
							id="dropdownForm2"
							ngbDropdownToggle
						>
							<img src="assets/icons/invite-peoples.svg" /> Deedbuds
							<ng-container *ngIf="deedbudsSelected.length > 0"
								>({{ deedbudsSelected.length }})</ng-container
							>
						</button>
						<div ngbDropdownMenu aria-labelledby="dropdownForm2">
							<gpe-create-post-user-selector
								[getSearch]="deedbudsSearch"
								[onChanged]="deedbudsChanged"
								[selectedUsers]="deedbudsSelected"
								[refNumber]="1"
								[buttonActivateText]="'Add'"
								[buttonDeactivateText]="'Remove'"
							></gpe-create-post-user-selector>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-4">
					<div class="row">
						<div class="col-md-12 mt-2">
							<label class="p-0">
								<div
									class="custom-selector-holder"
									[class.custom-selector-holder-selected]="linkToEvent"
								>
									<img src="/assets/icons/check-02.svg" width="10" *ngIf="linkToEvent" />
								</div>
								<input type="checkbox" [(ngModel)]="linkToEvent" class="hidden-checkbox" /> Link to
								event</label
							>
						</div>
						<div class="col-md-12">
							<ng-container *ngIf="linkToEvent">
								<div
									ngbDropdown
									class="d-block datepicker-dropdown"
									(openChange)="toggledEventDropdown($event, 5)"
								>
									<button
										type="button"
										class="btn btn-block btn-outline-primary post-selector-button"
										id="dropdownForm5"
										ngbDropdownToggle
									>
										<img src="assets/icons/event.svg" /> Choose event
										<ng-container *ngIf="eventSelected !== undefined">(selected)</ng-container>
									</button>
									<div ngbDropdownMenu aria-labelledby="dropdownForm5">
										<gpe-create-post-event-selector
											[getSearch]="eventSearch"
											[onChanged]="eventChanged"
											[eventSelected]="eventSelected"
											[refNumber]="5"
											[buttonActivateText]="'Select'"
											[buttonDeactivateText]="'Remove'"
										></gpe-create-post-event-selector>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="pt-2">
						<label class="p-0">
							<div
								class="custom-selector-holder"
								[class.custom-selector-holder-selected]="newPostObj.comment_enabled"
							>
								<img src="/assets/icons/check-02.svg" width="10" *ngIf="newPostObj.comment_enabled" />
							</div>
							<input type="checkbox" [(ngModel)]="newPostObj.comment_enabled" class="hidden-checkbox" />
							Enable comments</label
						>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-10">
					<a class="btn btn-primary d-block" (click)="addPost()">{{
						postId > 0 ? 'Edit post' : 'Add post'
					}}</a>
				</div>
				<div class="col-md-2">
					<a class="btn post-button-cancel d-block" (click)="closeModal()">Cancel</a>
				</div>
			</div>
		</div>
	</div>
</div>
