import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'gpe-select-interests',
	templateUrl: './select-interests.component.html',
	styleUrls: ['./select-interests.component.scss'],
})
export class SelectInterestsComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
