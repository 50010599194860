// export interface User {
//     uid: number;
//     username: string;
//     email: string;
//     password: string;
//     firstName: string;
//     lastName: string;
//     photoURL: string;
//     emailVerified: boolean;
//     token: string;
// }

export interface User {
	logined_userid: number;
	firebase_token?: string;
	profile: {
		user_id: number;
		user_type: number;
		user_first_name: string;
		user_last_name: string;
		user_email: string;
		data?: {
			user_avatar: string;
			user_cover: string;
		};
	};
	usertoken?: string;
}

export interface TempRegisterUser {
	email: string;
	password: string;
	passwordAgain: string;
}

export interface RegisterUserRequest {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	birthDate?: Date;
	sex: SexEnum;
	facebookToken?: string;
	appleToken?: string;
	googleToken?: string;
	cityId: number;
	languageId: number;
}

export enum SexEnum {
	man = 0,
	woman = 1,
	other = 2,
}
