import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'dateAgoPipeMinimal',
	pure: false,
})
export class dateAgoPipeMinimal implements PipeTransform, OnDestroy {
	private timer: number | null = null;

	constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {}

	transform(value: string): string {
		this.removeTimer();
		if (value === '0') return '';

		let valueNum = Number.parseInt(value);
		if (valueNum < 100000000000) {
			valueNum = valueNum * 1000;
		}
		const d = new Date(valueNum);

		const now = new Date();
		const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
		const timeToUpdate = Number.isNaN(seconds) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
		this.timer = this.ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.ngZone.run(() => this.changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
		});
		const minutes = Math.round(Math.abs(seconds / 60));
		const hours = Math.round(Math.abs(minutes / 60));
		const days = Math.round(Math.abs(hours / 24));
		const months = Math.round(Math.abs(days / 30.416));
		const years = Math.round(Math.abs(days / 365));
		if (Number.isNaN(seconds)) {
			return '';
		} else if (seconds <= 59) {
			return seconds + 's';
		} else if (seconds <= 119) {
			return '1m';
		} else if (minutes <= 59) {
			return minutes + 'm';
		} else if (minutes <= 119) {
			return '1h';
		} else if (hours <= 23) {
			return hours + 'h';
		} else if (hours <= 36) {
			return '1d';
		} else if (days <= 28) {
			return days + 'd';
		} else if (days <= 45) {
			return '1m';
		} else if (days <= 345) {
			return months + 'm';
		} else if (days <= 545) {
			return '1y';
		} else {
			// (days > 545)
			return years + 'y';
		}
	}

	ngOnDestroy(): void {
		this.removeTimer();
	}

	private removeTimer() {
		if (this.timer) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
	}

	private getSecondsUntilUpdate(seconds: number) {
		const min = 60;
		const hr = min * 60;
		const day = hr * 24;
		if (seconds < min) {
			// less than 1 min, update every 2 secs
			return 2;
		} else if (seconds < hr) {
			// less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) {
			// less then a day, update every 5 mins
			return 300;
		} else {
			// update every hour
			return 3600;
		}
	}
}
