<a (click)="closeModal()" class="modal-close-button">Close</a>
<div class="confirm-title">{{ title }}</div>
<div class="confirm-description">{{ description }}</div>
<div class="confirm-actions">
	<button
		class="btn btn-primary confirm-btn"
		[ngClass]="{
			'btn-danger': confimType === ConfirmType.DELETE
		}"
		(click)="confirm.emit(); closeModal()"
	>
		{{ confirmText }}
	</button>
	<button class="btn btn-secondary cancel-btn" (click)="cancel.emit(); closeModal()">
		{{ cancelText }}
	</button>
</div>
