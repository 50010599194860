import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IPost, PostType } from '../../../../../core/models/IPost';
import { PostCommentsService } from '../../../../../core/services/post-comments.service';
import { UserService } from '../../../../../core/services/user.service';

@Component({
	selector: 'gpe-likes-number-with-users',
	templateUrl: './likes-number-with-users.component.html',
	styleUrls: ['./likes-number-with-users.component.scss'],
})
export class LikesNumberWithUsersComponent implements OnInit {
	@Input()
	post: IPost | undefined;

	myUserId = 0;

	constructor(
		private userService: UserService,
		private postCommentService: PostCommentsService,
		private router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private postCommentsService: PostCommentsService,
	) {}

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();
	}
}
