import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { CreatedMediaUpload, CreatedMediaUploadRes, CreateMedia } from '../../shared/models/CreateMedia';
import { IBgColorGet } from '../models/IBgColor';
import { MediaUsageType } from '../models/MediaUsageType';
import { PostMedia } from '../models/PostMedia';
import { PostMediaType } from '../models/PostMediaType';

import { AuthService } from './auth.service';
import { BlobUploadService } from './blob-upload.service';

@Injectable({
	providedIn: 'root',
})
export class PostMediaService {
	private apiUrl = environment.apiURL;
	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private blobUploadService: BlobUploadService,
		private sanitizer: DomSanitizer,
	) {}

	getColors(): Observable<string[]> {
		const url = `${this.apiUrl}/post/bgcolor`;

		return this.http.get<ApiResponse<IBgColorGet>>(url).pipe(
			map((res) => {
				return res.data.bgcolors;
			}),
		);
	}
	uploadMediaToPost(postId: number, postMedia: CreateMedia): Promise<number> {
		return new Promise<number>((resolve) => {
			this.createMedia(
				postMedia.file.type ? postMedia.file.type : PostMediaType.Image,
				postMedia.file.file.size,
				postMedia.usage_type,
			).subscribe(
				(value) => {
					this.blobUploadService.handleFileSelect(value.upload_url, postMedia).then((value1) => {
						resolve(value.id);
					});
				},
				(error) => {
					resolve(0);
				},
				() => {},
			);
		});
	}

	createMedia(type: PostMediaType, size: number, usageType: MediaUsageType): Observable<CreatedMediaUpload> {
		const url = `${this.apiUrl}/media/post_create_media`;

		const body = new FormData();
		body.append('type', type.toString());
		body.append('size', size.toString());
		body.append('usage_type', usageType.toString());

		return this.http.post<ApiResponse<CreatedMediaUploadRes>>(url, body).pipe(
			map((res) => {
				return res.data.media;
			}),
		);
	}

	joinMedia(ids: number[], postId: number, usageType: MediaUsageType): Observable<boolean> {
		const url = `${this.apiUrl}/post/media/join_to_element`;

		const body = new FormData();
		body.append('media_ids', ids.join(','));
		body.append('element_id', postId.toString());
		body.append('media_usage_type', usageType.toString());

		return this.http.put<ApiResponse<boolean>>(url, body).pipe(
			map((res) => {
				return res.success == 1;
			}),
		);
	}

	mediaUploaded(mediaId: number, thumbnail: string | null): Observable<boolean> {
		const url = `${this.apiUrl}/media/post_uploaded`;

		const body = new FormData();
		body.append('media_id', mediaId.toString());
		if (thumbnail) {
			body.append('thumbnail', thumbnail);
		}

		return this.http.post<ApiResponse<boolean>>(url, body).pipe(
			map((res) => {
				return res.success == 1;
			}),
		);
	}
}
