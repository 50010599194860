<div class="back-btn-container">
	<button class="btn btn-primary" routerLink="/rooms">Go back to main page</button>
</div>
<div class="accordion" id="usersAccordion">
	<div class="users-accordion">
		<div id="usersHeading" class="title-container">
			<h6>Users</h6>
			<img
				src="assets/icons/arrow-right-blue.svg"
				data-bs-toggle="collapse"
				data-bs-target="#collapseUsers"
				aria-expanded="true"
				aria-controls="collapseUsers"
				class="cursor-pointer"
			/>
		</div>
		<div
			id="collapseUsers"
			class="accordion-collapse collapse show"
			aria-labelledby="usersHeading"
			data-bs-parent="#usersAccordion"
		>
			<div class="users-container">
				<div
					class="user-item cursor-pointer"
					*ngFor="let user of usersInRoom"
					(click)="navigateToUser(user.user_id)"
				>
					<img class="avatar-image" [src]="user.data.user_avatar" />
					<p>{{ user.user_first_name }} {{ user.user_last_name }}</p>
					<svg-icon class="three-dot icon" key="more"></svg-icon>
				</div>
			</div>
		</div>
	</div>
</div>
