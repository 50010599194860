<div class="add-btn-container">
	<button class="btn btn-primary" (click)="openCreateNewPost(addRoomModal)">Create new room</button>
</div>
<div class="accordion" id="allRoomsAccordion">
	<div class="rooms-accordion">
		<div id="allRoomsHeading" class="title-container">
			<h6>Recent rooms</h6>
			<img
				src="assets/icons/arrow-right-blue.svg"
				data-bs-toggle="collapse"
				data-bs-target="#collapseAllRooms"
				aria-expanded="true"
				aria-controls="collapseAllRooms"
				class="cursor-pointer"
			/>
		</div>
		<div
			id="collapseAllRooms"
			class="accordion-collapse collapse show"
			aria-labelledby="allRoomsHeading"
			data-bs-parent="#allRoomsAccordion"
		>
			<div class="rooms-container" *ngIf="allRooms$ | async as allRooms; else allRoomsloader">
				<div class="room-item cursor-pointer" *ngFor="let room of allRooms" (click)="navigate(room.id)">
					<img class="avatar-image" [src]="room.coverUrl" />
					<div class="room-item-content">
						<p>{{ room.name }}</p>
						<span class="usercount-text">{{ room.onlinePeople }} online</span>
					</div>
					<img *ngIf="room.isFavorite" class="icon" src="assets/icons/star-full.svg" />
					<img class="icon" src="assets/icons/arrow-right-grey.svg" />
				</div>
				<div class="empty-state" *ngIf="allRooms.length === 0">
					You don't have any recent room, select one from the recommended ones.
				</div>
			</div>
			<ng-template #allRoomsloader>
				<gpe-loading></gpe-loading>
			</ng-template>
		</div>
	</div>
</div>
<div class="accordion" id="favoriteRoomsAccordion">
	<div class="rooms-accordion">
		<div id="favoriteRoomsHeading" class="title-container">
			<h6>Favorite rooms</h6>
			<img
				src="assets/icons/arrow-right-blue.svg"
				data-bs-toggle="collapse"
				data-bs-target="#collapseFavoriteRooms"
				aria-expanded="true"
				aria-controls="collapseFavoriteRooms"
				class="cursor-pointer"
			/>
		</div>
		<div
			id="collapseFavoriteRooms"
			class="accordion-collapse collapse show"
			aria-labelledby="favoriteRoomsHeading"
			data-bs-parent="#favoriteRoomsAccordion"
		>
			<div class="rooms-container" *ngIf="favoriteRooms$ | async as favoriteRooms; else favoriteRoomsloader">
				<div class="room-item cursor-pointer" *ngFor="let room of favoriteRooms" (click)="navigate(room.id)">
					<img class="avatar-image" [src]="room.coverUrl" />
					<div class="room-item-content">
						<p>{{ room.name }}</p>
						<span class="usercount-text">{{ room.onlinePeople }} online</span>
					</div>
					<img *ngIf="room.isFavorite" class="icon" src="assets/icons/star-full.svg" />
					<img class="icon" src="assets/icons/arrow-right-grey.svg" />
				</div>
				<div class="empty-state" *ngIf="favoriteRooms.length === 0">You don't have any favortite room yet.</div>
			</div>
			<ng-template #favoriteRoomsloader>
				<gpe-loading></gpe-loading>
			</ng-template>
		</div>
	</div>
</div>
<div class="accordion" id="myRoomsAccordion">
	<div class="rooms-accordion">
		<div id="myRoomsHeading" class="title-container">
			<h6>My rooms</h6>
			<img
				src="assets/icons/arrow-right-blue.svg"
				data-bs-toggle="collapse"
				data-bs-target="#collapseMyRooms"
				aria-expanded="true"
				aria-controls="collapseMyRooms"
				class="cursor-pointer"
			/>
		</div>
		<div
			id="collapseMyRooms"
			class="accordion-collapse collapse show"
			aria-labelledby="myRoomsHeading"
			data-bs-parent="#myRoomsAccordion"
		>
			<div class="rooms-container" *ngIf="myRooms$ | async as myRooms; else myRoomsLoader">
				<div class="room-item cursor-pointer" *ngFor="let room of myRooms" (click)="navigate(room.id)">
					<img class="avatar-image" [src]="room.coverUrl" />
					<div class="room-item-content">
						<p>{{ room.name }}</p>
						<span class="usercount-text">{{ room.onlinePeople }} online</span>
					</div>
					<img *ngIf="room.isFavorite" class="icon" src="assets/icons/star-full.svg" />
					<img class="icon" src="assets/icons/arrow-right-grey.svg" />
				</div>
				<div class="empty-state" *ngIf="myRooms.length === 0">You didn't create any room yet.</div>
			</div>
			<ng-template #myRoomsLoader>
				<gpe-loading></gpe-loading>
			</ng-template>
		</div>
	</div>
</div>

<ng-template #addRoomModal let-modal>
	<gpe-rooms-modals-container [modalRef]="modal" [profilePic]="currentProfilePic"></gpe-rooms-modals-container>
</ng-template>
