import { IPost, IPostGetList, IPostGetLista } from './../models/IPost';
import { IHiddenPosts } from './../models/IHiddenPosts';
import { map } from 'rxjs/operators';
import { ApiResponse } from './../../shared/models/ApiResponse';
import { ApiService } from './../api/api.service';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class HideService {
	public hideChangedEmitter = new EventEmitter<void>();

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private userService: UserService,
		private apiService: ApiService,
	) {}

	hidePost(postId: number) {
		let url = 'hide/post';

		const data = new FormData();
		data.append('target_id', postId.toString());

		return this.apiService.post<ApiResponse<Boolean>>(url, data).pipe(
			map((res) => {
				this.hideChangedEmitter.emit();
				return res.success == 1;
			}),
		);
	}

	getHiddenPostIds(): Observable<number[]> {
		let url = 'hide/post';

		return this.apiService.get<ApiResponse<IHiddenPosts>>(url).pipe(
			map((res) => {
				let numbers = [];
				for (let n of res.data.list) {
					numbers.push(parseInt(n));
				}
				return numbers;
			}),
		);
	}

	unhidePost(postId: number) {
		let url = 'hide/post/' + postId;

		return this.apiService.delete<ApiResponse<Boolean>>(url).pipe(
			map((res) => {
				this.hideChangedEmitter.emit();
				return res.success == 1;
			}),
		);
	}

	getHiddenPosts(): Observable<IPost[]> {
		let url = 'hide/post_details';

		return this.apiService.get<ApiResponse<IPostGetLista>>(url).pipe(
			map((res) => {
				return res.data.list;
			}),
		);
	}
}
