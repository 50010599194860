import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ApiResponse } from '../../shared/models/ApiResponse';
import { ApiService } from '../api/api.service';
import { IFirebaseToken } from '../models/IFirebaseToken';
import { IProfile, IProfileGet } from '../models/IProfile';
import { IProfileMinimal, IProfileMinimalDeedBuds, IProfileMinimalSearch } from '../models/IProfileMinimal';
import { IUserBase } from '../models/IUserBase';
import { IUserMinimal, IUserMinimalFollowers, IUserMinimalFollows } from '../models/IUserMinimal';

import { IProfileBatchGet, IProfileBatchGetInner } from './../models/IProfile';
import { AuthService } from './auth.service';
import { InterestsService } from './interests.service';
import { UserFollowService } from './user-follow.service';

@Injectable({ providedIn: 'root' })
export class UserService {
	private apiUrl = environment.apiURL;
	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private userFollowService: UserFollowService,
		private interestsService: InterestsService,
		private apiService: ApiService,
	) {}

	getUserToken(): string {
		const currentUser = this.authService.currentUserValue;
		if (currentUser && currentUser.usertoken) {
			return currentUser.usertoken;
		} else {
			return '';
		}
	}

	getFirebaseToken(): Observable<IFirebaseToken> {
		const url = `${this.apiUrl}/auth/firebase_token`;

		return this.http.post<ApiResponse<IFirebaseToken>>(url, {}).pipe(
			map((res) => {
				return res.data;
			}),
		);
	}

	getLoginedUserId(): number {
		if (this.authService.currentUserValue && this.authService.currentUserValue.logined_userid) {
			return this.authService.currentUserValue.logined_userid;
		} else {
			return 0;
		}
	}

	getUser(id: number): Observable<IProfile> {
		let url: string;
		if (id > 0) {
			url = `${this.apiUrl}/user/profile/${id}`;
		} else {
			url = `${this.apiUrl}/user/profile`;
		}

		return this.http.get<ApiResponse<IProfileGet>>(url).pipe(
			map((res) => {
				const p = res.data.user.pop();
				if (p !== undefined) {
					return p;
				}
				throw new Error('Dont got profile object');
			}),
		);
	}

	getUserBatch(ids: number[]): Observable<IProfileBatchGetInner[]> {
		const url = this.apiUrl + '/user/batch?user_ids=' + ids.join(',');

		return this.http.get<ApiResponse<IProfileBatchGet>>(url).pipe(
			map((res) => {
				return res.data.users;
			}),
		);
	}

	getFollowing(userId: number, search: string, page: number, limit = 15): Observable<IUserMinimal[]> {
		if (userId > 0) {
			this.getLoginedUserId();
		}
		let pstr = '';
		if (search.length > 0) {
			const searchEncoded = encodeURIComponent(search);
			pstr = '&keyword=' + searchEncoded;
		}
		const url = `${this.apiUrl}/user/follow?page=${page}&limit=${limit}&user_id=${userId}${pstr}`;

		return this.http.get<ApiResponse<IUserMinimalFollows>>(url).pipe(
			map((res) => {
				return res.data.follows;
			}),
		);
	}

	getFollowers(userId: number, search: string, page: number, limit = 15): Observable<IUserMinimal[]> {
		if (userId > 0) {
			this.getLoginedUserId();
		}
		let pstr = '';
		if (search.length > 0) {
			const searchEncoded = encodeURIComponent(search);
			pstr = '&keyword=' + searchEncoded;
		}
		const url = `${this.apiUrl}/user/followed?page=${page}&limit=${limit}&user_id=${userId}${pstr}`;

		return this.http.get<ApiResponse<IUserMinimalFollowers>>(url).pipe(
			map((res) => {
				return res.data.followers;
			}),
		);
	}
	getDeedbuds(userId: number, search: string, page: number, limit = 15): Observable<IProfileMinimal[]> {
		if (userId > 0) {
			this.getLoginedUserId();
		}
		let pstr = '';
		if (search.length > 0) {
			const searchEncoded = encodeURIComponent(search);
			pstr = '&keyword=' + searchEncoded;
		}
		const url = `${this.apiUrl}/user/deedbud?page=${page}&limit=${limit}&user_id=${userId}${pstr}`;

		return this.http.get<ApiResponse<IProfileMinimalDeedBuds>>(url).pipe(
			map((res) => {
				return res.data.deedbuds;
			}),
		);
	}

	searchUser(searchStr: string, page: number, limit = 15, city_id = 0): Observable<IProfileMinimal[]> {
		if (searchStr.length > 1) {
			const searchEncoded = encodeURIComponent(searchStr);
			const url = `${this.apiUrl}/user/search_v2?search=${searchEncoded}&page=${page}&limit=${limit}&city_id=${city_id}`;

			return this.http.get<ApiResponse<IProfileMinimalSearch>>(url).pipe(
				map((res) => {
					return res.data.peoples;
				}),
			);
		} else {
			return new Observable<IProfileMinimal[]>((subscriber) => {
				subscriber.next([]);
				subscriber.complete();
			});
		}
	}

	searchCommentMention(searchStr: string, page: number, limit = 15): Observable<IProfileMinimal[]> {
		if (searchStr.length > 1) {
			const searchEncoded = encodeURIComponent(searchStr);
			const url = `${this.apiUrl}/user/comment_tag_people?search=${searchEncoded}&page=${page}&limit=${limit}`;

			return this.http.get<ApiResponse<IProfileMinimalSearch>>(url).pipe(
				map((res) => {
					return res.data.peoples;
				}),
			);
		} else {
			return new Observable<IProfileMinimal[]>((subscriber) => {
				subscriber.next([]);
				subscriber.complete();
			});
		}
	}

	searchPossibleOrganizer(searchStr: string, page: number, limit = 15): Observable<IProfileMinimal[]> {
		if (searchStr.length > 1) {
			const searchEncoded = encodeURIComponent(searchStr);
			const url = `${this.apiUrl}/user/post_create_organizer_users?search=${searchEncoded}&page=${page}&limit=${limit}`;

			return this.http.get<ApiResponse<IProfileMinimalSearch>>(url).pipe(
				map((res) => {
					return res.data.peoples;
				}),
			);
		} else {
			return new Observable<IProfileMinimal[]>((subscriber) => {
				subscriber.next([]);
				subscriber.complete();
			});
		}
	}

	searchPossibleInvite(searchStr: string, page: number, limit = 15): Observable<IProfileMinimal[]> {
		if (searchStr.length > 1) {
			const searchEncoded = encodeURIComponent(searchStr);
			const url = `${this.apiUrl}/user/post_create_invite_users?search=${searchEncoded}&page=${page}&limit=${limit}`;

			return this.http.get<ApiResponse<IProfileMinimalSearch>>(url).pipe(
				map((res) => {
					return res.data.peoples;
				}),
			);
		} else {
			return new Observable<IProfileMinimal[]>((subscriber) => {
				subscriber.next([]);
				subscriber.complete();
			});
		}
	}

	searchPostCreateDeedbuds(searchStr: string, page: number, limit = 15): Observable<IProfileMinimal[]> {
		if (searchStr.length > 1) {
			const searchEncoded = encodeURIComponent(searchStr);
			const url = `${this.apiUrl}/user/post_create_deedbud?search=${searchEncoded}&page=${page}&limit=${limit}`;

			return this.http.get<ApiResponse<IProfileMinimalSearch>>(url).pipe(
				map((res) => {
					return res.data.peoples;
				}),
			);
		} else {
			return new Observable<IProfileMinimal[]>((subscriber) => {
				subscriber.next([]);
				subscriber.complete();
			});
		}
	}

	getSeachUserWithFollowData(search: string, page: number, limit = 15, city_id = 0): Observable<IProfileMinimal[]> {
		return new Observable<IProfileMinimal[]>((subscriber) => {
			this.searchUser(search, page, limit, city_id).subscribe((value) => {
				this.userFollowService.addFollowedData(value).subscribe(
					(value1) => {
						subscriber.next(value1 as IProfileMinimal[]);
					},
					(error) => {},
					() => {
						subscriber.complete();
					},
				);
			});
		});
	}
	getFollowingWithFollowData(userId: number, search: string, page: number, limit = 15): Observable<IUserMinimal[]> {
		return new Observable<IUserMinimal[]>((subscriber) => {
			this.getFollowing(userId, search, page, limit).subscribe((value) => {
				this.userFollowService.addFollowedData(value).subscribe(
					(value1) => {
						subscriber.next(value1 as IUserMinimal[]);
					},
					(error) => {},
					() => {
						subscriber.complete();
					},
				);
			});
		});
	}

	getFollowersWithFollowData(userId: number, search: string, page: number, limit = 15): Observable<IUserMinimal[]> {
		return new Observable<IUserMinimal[]>((subscriber) => {
			this.getFollowers(userId, search, page, limit).subscribe((value) => {
				this.userFollowService.addFollowedData(value).subscribe(
					(value1) => {
						subscriber.next(value1 as IUserMinimal[]);
					},
					(error) => {},
					() => {
						subscriber.complete();
					},
				);
			});
		});
	}

	getDeedbudsWithFollowData(userId: number, search: string, page: number, limit = 15): Observable<IProfileMinimal[]> {
		return new Observable<IProfileMinimal[]>((subscriber) => {
			this.getDeedbuds(userId, search, page, limit).subscribe((value) => {
				this.userFollowService.addFollowedData(value).subscribe(
					(value1) => {
						subscriber.next(value1 as IProfileMinimal[]);
					},
					(error) => {},
					() => {
						subscriber.complete();
					},
				);
			});
		});
	}

	getInterestsDataForUsers(users: IUserBase[]): Observable<IUserBase[]> {
		return new Observable<IUserBase[]>((subscriber) => {
			const ids: number[] = [];
			for (const u of users) {
				ids.push(u.user_id);
				u.interests = [];
			}

			this.interestsService.getInterestsBatch(ids).subscribe(
				(value1) => {
					for (const u of users) {
						for (const interestRes of value1.result) {
							if (interestRes.id == u.user_id) {
								u.interests = interestRes.interests;
							}
						}
					}
					subscriber.next(users);
				},
				(error) => {},
				() => {
					subscriber.complete();
				},
			);
		});
	}

	convertIUserMinimalToIProfileMinimal(u: IUserMinimal): IProfileMinimal {
		const o: IProfileMinimal = {
			data: {
				user_avatar: u.user_avatar ? u.user_avatar : '',
				user_about: '',
				user_birthdate: '',
				user_city_id: 0,
				user_country_id: 0,
				user_phone: '',
				user_region_id: 0,
				user_sex: '',
				user_work: '',
				user_zipcode: '',
				user_slogen: '',
			},
			user_first_name: u.user_first_name,
			user_id: u.user_id,
			user_last_name: u.user_last_name,
			user_type: u.user_type,
		};

		return o;
	}
}
