<ng-container *ngIf="post">
	<span>
		<span *ngIf="post.post_like_count == 0"> 0 </span>
		<span *ngIf="post.post_like_count == 1 && post.post_liked"> You </span>
		<span
			*ngIf="
				post.post_like_count == 1 &&
				!post.post_liked &&
				post.top_user_liked &&
				post.top_user_liked.length == 1 &&
				post.top_user_liked[0]
			"
		>
			{{ post.top_user_liked[0].user_first_name }} {{ post.top_user_liked[0].user_last_name }}
		</span>
		<span *ngIf="post.post_like_count > 1 && post.top_user_liked && post.top_user_liked.length > 1">
			<ng-container *ngIf="post.post_liked">You, </ng-container>
			<ng-container *ngIf="post.top_user_liked[0].user_id != myUserId">
				{{ post.top_user_liked[0].user_first_name }} {{ post.top_user_liked[0].user_last_name }}
			</ng-container>
			<ng-container
				*ngIf="post.top_user_liked[0].user_id == myUserId && post.top_user_liked[1].user_id != myUserId"
			>
				{{ post.top_user_liked[1].user_first_name }} {{ post.top_user_liked[1].user_last_name }}
			</ng-container>

			<ng-container *ngIf="post.post_like_count - 1 - (post.post_liked ? 1 : 0) > 0">
				and {{ post.post_like_count - 1 - (post.post_liked ? 1 : 0) }} others
			</ng-container>
		</span>
	</span>
</ng-container>
