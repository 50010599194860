import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'gpe-report-modal',
	templateUrl: './report-modal.component.html',
	styleUrls: ['./report-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportModalComponent {
	@Input() modalRef: any;
	@Input() reportType: string | undefined;
	@Input() reportName: string | undefined;

	@Output() private sendReport = new EventEmitter<string>();

	public reportForm = new FormGroup({
		reason: new FormControl('', Validators.required),
	});

	closeModal(): void {
		this.modalRef.close();
	}

	report(): void {
		if (this.reportForm.valid) {
			this.sendReport.emit(this.reportForm.value.reason);
			this.closeModal();
		}
	}
}
