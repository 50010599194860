<div class="year-picker">
	<img src="assets/icons/left-arrow.svg" class="cursor-pointer" (click)="changeYear(-1)" />
	<div class="year">
		{{ startYear }}
	</div>
	<img src="assets/icons/right-arrow.svg" class="cursor-pointer" (click)="changeYear(1)" />
</div>
<div class="month-select">
	<div class="month-card" *ngFor="let month of months; index as i" (click)="selectMonth(i)">
		{{ month }}
	</div>
</div>
