import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { RoomViewModel } from 'src/app/modules/rooms/models/room.view-model';
import { RoomService } from 'src/app/modules/rooms/services/room.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'gpe-room-list',
	templateUrl: './room-list.component.html',
	styleUrls: ['./room-list.component.scss'],
})
export class RoomListComponent implements OnInit {
	constructor(
		private roomService: RoomService,
		private router: Router,
		private modalService: NgbModal,
		private userProfileService: UserProfileService,
	) {}

	public allRooms$: Observable<RoomViewModel[] | null> = this.roomService.getAllRooms();
	public favoriteRooms$: Observable<RoomViewModel[] | null> = this.roomService.getFavoriteRooms();
	public myRooms$: Observable<RoomViewModel[] | null> = this.roomService.getOwnedRooms();
	public currentProfilePic: string | undefined;

	ngOnInit(): void {
		this.userProfileService
			.getProfile()
			.subscribe((profile) => (this.currentProfilePic = profile.data.user_avatar));
	}

	public navigate(id: string): void {
		this.roomService.selectRoom(id);
		this.router.navigate(['/rooms'], { queryParams: { id } });
	}

	openCreateNewPost(content: any): void {
		this.modalService.open(content, {
			backdropClass: 'xlmodal-backdrop-style',
			size: 'xl',
			centered: true,
			scrollable: false,
			modalDialogClass: 'xlmodal-class',
			beforeDismiss: () => {
				return false;
			},
		});
	}
}
