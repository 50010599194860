import { LY_THEME, LY_THEME_NAME, LyHammerGestureConfig, LyTheme2, StyleRenderer } from '@alyle/ui';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModule, NgbPopoverModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { TagifyModule } from 'ngx-tagify';
import { SwiperModule } from 'swiper/angular';

import { CalendarMonthSelectComponent } from '../modules/calendar/components/calendar-month-select/calendar-month-select.component';

import { CloseButtonComponent } from './components/buttons/close-button/close-button.component';
import { SocialButtonsComponent } from './components/buttons/social-buttons/social-buttons.component';
import { BaseCommentComponent } from './components/comments/base-comment/base-comment.component';
// import { CommentItemComponent } from './components/comments/comment-item/comment-item.component';
import { CompanyTableCheckboxComponent } from './components/company/company-table/company-table-checkbox/company-table-checkbox.component';
import { CompanyTableDropdownComponent } from './components/company/company-table/company-table-dropdown/company-table-dropdown.component';
import { CompanyTableEmailComponent } from './components/company/company-table/company-table-email/company-table-email.component';
import { CompanyTableSearchComponent } from './components/company/company-table/company-table-search/company-table-search.component';
import { EditCompanyInnerComponent } from './components/company/edit-company-inner/edit-company-inner.component';
import { ComplexTextInputComponent } from './components/complex-text-input/complex-text-input.component';
import { CropCircleComponent } from './components/crop-circle/crop-circle.component';
import { EventJoinedMembersModalComponent } from './components/events/event-joined-members-modal/event-joined-members-modal.component';
import { PasswordStrengthComponent } from './components/extras/password-strength/password-strength.component';
import { AuthInputFieldComponent } from './components/inputs/auth-input-field/auth-input-field.component';
import { MessageInputFieldComponent } from './components/inputs/message-input-field/message-input-field.component';
import { MultiSelectWithSearchComponent } from './components/inputs/multi-select-with-search/multi-select-with-search.component';
import { SelectInterestsComponent } from './components/inputs/select-interests/select-interests.component';
import { UploadImageInputComponent } from './components/inputs/upload-image-input/upload-image-input.component';
import { LinkInlineComponent } from './components/link-inline/link-inline.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LocationSelectorComponent } from './components/location-selector/location-selector.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { ReportModalComponent } from './components/modals/report-modal/report-modal.component';
import { PostShareComponent } from './components/post-share/post-share.component';
import { BaseComponentComponent } from './components/posts/create/base-component/base-component.component';
import { EventSelectorComponent } from './components/posts/create/common/event-selector/event-selector.component';
import { MediaSelectorComponent } from './components/posts/create/common/media-selector/media-selector.component';
import { UserSelectorComponent } from './components/posts/create/common/user-selector/user-selector.component';
import { EventComponent } from './components/posts/create/event/event.component';
import { PostComponent } from './components/posts/create/post/post.component';
import { TextComponent } from './components/posts/create/text/text.component';
import { VoiceComponent } from './components/posts/create/voice/voice.component';
import { PostGeneralEventComponent } from './components/posts/event/event-general-frame/post-general-event.component';
import { GeneralPostComponent } from './components/posts/general-post/general-post.component';
import { LikesNumberComponent } from './components/posts/post/likes-number/likes-number.component';
import { LikesNumberWithUsersComponent } from './components/posts/post/likes-number-with-users/likes-number-with-users.component';
import { PostGeneralPostComponent } from './components/posts/post/post-general-frame/post-general-post.component';
import { PostTypeAudioComponent } from './components/posts/post/post-type-audio/post-type-audio.component';
import { PostTypeMediaComponent } from './components/posts/post/post-type-media/post-type-media.component';
import { PostTypeTextComponent } from './components/posts/post/post-type-text/post-type-text.component';
import { EditCoverComponent } from './components/profile/edit-cover/edit-cover.component';
import { ProfilePictureComponent } from './components/profile/profile-picture/profile-picture.component';
import { ReportProblemBaseComponent } from './components/settings/report-problem-base/report-problem-base.component';
import { SuggestIdeaBaseComponent } from './components/settings/suggest-idea-base/suggest-idea-base.component';
import { SidebarDefaultComponent } from './components/sidebar-default/sidebar-default.component';
import { UserInlineComponent } from './components/user-inline/user-inline.component';
import { UserPictureCanvasComponent } from './components/user-picture-canvas/user-picture-canvas.component';
import { FocusOnShowDirective } from './directives/FocusOnShowDirective';
import { ImageDragDirective } from './directives/ImageDragDirective';
import { LifeCycleDirective } from './directives/life-cycle.directive';
import { SortableTableHeaderDirective } from './directives/sortable-table-header.directive';
import { DynamicRouterLinkParser } from './hooks/DynamicRouterLinkParser';
import { GPECardComponent } from './new-components/gpe-card/gpe-card.component';
import { dateAgoPipe } from './pipes/date-ago.pipe';
import { dateAgoPipeMinimal } from './pipes/date-ago-minimal.pipe';
import { genderPipe } from './pipes/gender.pipe';
import { MinuteSecondsPipe } from './pipes/minute-seconds-pipe';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

const componentParsers: Array<HookParserEntry> = [
	DynamicRouterLinkParser,
	{
		component: UserInlineComponent,
	},
];

const components = [
	SocialButtonsComponent,
	AuthInputFieldComponent,
	PasswordStrengthComponent,
	SidebarDefaultComponent,
	GeneralPostComponent,
	PostGeneralPostComponent,
	PostGeneralEventComponent,
	PostTypeAudioComponent,
	PostTypeTextComponent,
	PostTypeMediaComponent,
	BaseCommentComponent,
	// CommentItemComponent,
	MediaSelectorComponent,
	UserInlineComponent,
	LinkInlineComponent,
	ComplexTextInputComponent,
	LikesNumberComponent,
	LikesNumberWithUsersComponent,
	BaseComponentComponent,
	EventComponent,
	PostComponent,
	TextComponent,
	VoiceComponent,
	UserSelectorComponent,
	EventSelectorComponent,
	ProfilePictureComponent,
	LocationSelectorComponent,
	PostShareComponent,
	EditCompanyInnerComponent,
	CropCircleComponent,
	EditCoverComponent,
	EventJoinedMembersModalComponent,
	LoadingComponent,
	UserPictureCanvasComponent,
	ConfirmModalComponent,
	CloseButtonComponent,
	ReportModalComponent,
	CompanyTableSearchComponent,
	CompanyTableDropdownComponent,
	CompanyTableEmailComponent,
	CompanyTableCheckboxComponent,
	CalendarMonthSelectComponent,
	MultiSelectWithSearchComponent,
	MessageInputFieldComponent,
	SuggestIdeaBaseComponent,
	UploadImageInputComponent,
	SelectInterestsComponent,
	ReportProblemBaseComponent,
];

const newComponents: any[] = [GPECardComponent];

const directives = [FocusOnShowDirective, ImageDragDirective, LifeCycleDirective, SortableTableHeaderDirective];

@NgModule({
	declarations: [
		...components,
		...directives,
		genderPipe,
		dateAgoPipe,
		dateAgoPipeMinimal,
		MinuteSecondsPipe,
		TruncatePipe,
		NumberSuffixPipe,
		...newComponents,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		InfiniteScrollModule,
		SwiperModule,
		DynamicHooksModule.forRoot({
			globalParsers: componentParsers,
		}),
		TagifyModule.forRoot(),
		RouterModule,
		FormsModule,
		NgbPopoverModule,
		LottieModule,
		NgbDropdownModule,
		NgbProgressbarModule,
		DlDateTimeDateModule, // <--- Determines the data type of the model
		DlDateTimePickerModule,
		ShareButtonsModule,
		ShareIconsModule,
		NgSelectModule,
		LyImageCropperModule,
		LySliderModule,
		LyButtonModule,
		LyIconModule,
		NgbModule,
	],
	providers: [
		LyTheme2,
		StyleRenderer,
		// Theme that will be applied to this module
		{ provide: LY_THEME_NAME, useValue: 'minima-light' },
		{ provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
		// Gestures
		{ provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }, // Required for <ly-carousel>
	],
	exports: [
		...components,
		...directives,
		genderPipe,
		dateAgoPipe,
		TruncatePipe,
		dateAgoPipeMinimal,
		MinuteSecondsPipe,
		NumberSuffixPipe,
		FormsModule,
		ReactiveFormsModule,
		NgbDropdownModule,
		NgbModule,
		NgSelectModule,
		...newComponents,
		DynamicHooksModule.forRoot({
			globalParsers: componentParsers,
		}),
	],
})
export class SharedModule {}
