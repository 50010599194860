export interface CreateCompanyCommand {
	name: string;
	location: string;
	industry: CompanyData;
	size: CompanyData;
	url: string;
	gpeUrl: string;
	type: CompanyData;
	bio: string;
	story: string;
	profilPicId?: string;
	coverPicId?: string;
}

export interface CompanyData {
	id: string;
	name: string;
}

export const INDUSTRIES: CompanyData[] = [
	{ id: '1', name: 'Tech' },
	{ id: '2', name: 'Health' },
	{ id: '3', name: 'Car' },
	{ id: '4', name: 'Real estate' },
];
export const COMPANYSIZES: CompanyData[] = [
	{ id: '1', name: '1-10' },
	{ id: '2', name: '10-100' },
	{ id: '3', name: '100-1000' },
	{ id: '4', name: '1000+' },
];
export const COMPANYTYPES: CompanyData[] = [
	{ id: '1', name: 'Type 1' },
	{ id: '2', name: 'Type 2' },
	{ id: '3', name: 'Type 3' },
	{ id: '4', name: 'Type 4' },
];
