import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

import { UserPictureCanvasModel, UserPictureCanvasViewModel } from '../../models/user-picture-canvas.model';

@Component({
	selector: 'gpe-user-picture-canvas',
	templateUrl: './user-picture-canvas.component.html',
	styleUrls: ['./user-picture-canvas.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPictureCanvasComponent implements OnChanges {
	constructor(private _elementRef: ElementRef) {}

	@ViewChild('canvas', { static: true })
	canvas?: ElementRef<HTMLCanvasElement>;

	@Input()
	public userPictureCanvasModel: UserPictureCanvasModel[] = [];

	public interval: any;

	ngOnChanges(): void {
		if (this.interval) clearInterval(this.interval);
		this.initialCanvas(this.canvas);
	}

	initialCanvas(canvas?: ElementRef<HTMLCanvasElement>): void {
		const ctx = canvas?.nativeElement.getContext('2d');
		if (!ctx) return;
		if (!canvas) return;
		const padding = 20;
		ctx.canvas.width = this._elementRef.nativeElement.offsetWidth - padding;
		ctx.canvas.height = this._elementRef.nativeElement.offsetHeight - padding;

		const v = 0.1;
		const avgSize = 50;
		const minSize = 25;
		const balls: UserPictureCanvasViewModel[] = this.userPictureCanvasModel.map((picture) => ({
			x: Math.floor(Math.random() * ctx.canvas.width),
			y: Math.floor(Math.random() * ctx.canvas.height),
			r: Math.floor(Math.random() * avgSize) + minSize,
			dir: Math.floor(Math.random() * 300),
			imgSrc: picture.pictureUrl,
			moveX: Math.cos((Math.PI / 180) * Math.floor(Math.random() * 300) + 1) * v,
			moveY: Math.sin((Math.PI / 180) * Math.floor(Math.random() * 300) + 1) * v,
		}));
		const images = balls.map((ball) => {
			const img = new Image();
			img.src = ball.imgSrc;
			return img;
		});

		this.interval = setInterval(() => {
			this.draw(canvas, ctx, balls, images);
		}, 10);
	}

	draw(
		canvas: ElementRef<HTMLCanvasElement>,
		ctx: CanvasRenderingContext2D,
		balls: UserPictureCanvasViewModel[],
		images: HTMLImageElement[],
	): void {
		ctx.clearRect(0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
		balls.forEach((ball, index) => {
			if (ball.x > canvas.nativeElement.width + ball.r || ball.x < -2 * Math.abs(ball.r))
				ball.moveX = -ball.moveX;
			if (ball.y > canvas.nativeElement.height + ball.r || ball.y < -2 * Math.abs(ball.r))
				ball.moveY = -ball.moveY;

			ball.x += ball.moveX;
			ball.y += ball.moveY;

			ctx.save();
			ctx.beginPath();
			ctx.arc(ball.x + ball.r / 2, ball.y + ball.r / 2, ball.r / 2, 0, Math.PI * 2, true);
			ctx.closePath();
			ctx.clip();

			ctx.drawImage(images[index], ball.x, ball.y, ball.r, ball.r);

			ctx.beginPath();
			ctx.arc(ball.x - ball.r / 2, ball.y - ball.r / 2, ball.r / 2, 0, Math.PI * 2, true);
			ctx.clip();
			ctx.closePath();
			ctx.restore();
		});
	}
}
