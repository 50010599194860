<input
	type="text"
	[classList]="appliedClasses"
	id="txtSearchPlaces"
	name="txtSearchPlaces"
	#txtSearchPlaces="ngModel"
	[(ngModel)]="locationStr"
	[placeholder]="placeholder"
	[disabled]="disabled"
/>
