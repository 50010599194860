import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';
import { LocationService } from './../../../../../core/services/location.service';

import { IPost, IPostCreate, PostType } from '../../../../../core/models/IPost';
import { IProfileMinimal } from '../../../../../core/models/IProfileMinimal';
import { PostMediaService } from '../../../../../core/services/post-media.service';
import { PostService } from '../../../../../core/services/post.service';
import { UserService } from '../../../../../core/services/user.service';
import { EventSelectorComponent } from '../common/event-selector/event-selector.component';
import { UserSelectorComponent } from '../common/user-selector/user-selector.component';

@Component({
	selector: 'gpe-create-post-text',
	templateUrl: './text.component.html',
	styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit {
	linkToEvent = false;
	deedbudsSelected: IProfileMinimal[] = [];
	eventSelected: IPost | undefined;

	@Input()
	postId!: number;

	@Input()
	post: IPost | undefined;

	submitted: boolean = false;
	errorColumns: string[] = [];

	locationObject: google.maps.places.PlaceResult | null = null;

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	newPostObj: IPostCreate = {
		bgcolor: '',
		title: '',
		text: '',
		linked_post_id: 0,
		location_id: 0,
		marked_users: '',
		post_type: PostType.Text,
		deedbuds: '',
		city_id: 0,
		has_media: false,
		comment_enabled: true,
	};

	@Input()
	bgColors!: string[];

	@Input()
	closeModal!: () => void;

	@Input()
	is_loading!: boolean;

	@ViewChildren(UserSelectorComponent) userSelectors: QueryList<UserSelectorComponent> | undefined;
	@ViewChildren(EventSelectorComponent) eventSelectors: QueryList<EventSelectorComponent> | undefined;

	constructor(
		private userService: UserService,
		private postService: PostService,
		private postMediaService: PostMediaService,
		private router: Router,
		private locationService: LocationService,
	) {}

	ngOnInit(): void {
		if (this.bgColors.length > 0) {
			this.newPostObj.bgcolor = this.bgColors[0];
		}
		if (this.postId > 0 && this.post) {
			this.newPostObj = {
				bgcolor: this.post.post_content_bgcolor,
				title: this.post.post_content_title,
				text: this.post.post_content_text,
				linked_post_id: this.post.post_link_post_id,
				location_id: this.post.location_id,
				marked_users: '',
				post_type: PostType.Text,
				deedbuds: '',
				city_id: 0,
				has_media: false,
				comment_enabled: true,
			};

			this.linkToEvent = this.post.post_link_post_id > 0;

			for (let u of this.post.marked_users) {
				this.deedbudsSelected.push(this.userService.convertIUserMinimalToIProfileMinimal(u));
			}
			if (this.post.marked_users_invited) {
				for (let u of this.post.marked_users_invited) {
					this.deedbudsSelected.push(this.userService.convertIUserMinimalToIProfileMinimal(u));
				}
			}

			this.postService.getPostById(this.post.post_link_post_id).subscribe(
				(val) => {
					this.eventSelected = val;
				},
				(err) => {
					this.linkToEvent = false;
				},
			);
		}
	}

	toggledEventDropdown(event: boolean, refNumber: number) {
		if (this.eventSelectors) {
			for (const e of this.eventSelectors) {
				if (e.refNumber && e.refNumber == refNumber) {
					if (!event) {
						e.clearInput();
					} else {
						e.focusInput();
					}
				}
			}
		}
	}

	eventSearch = (searchStr: string, page: number, limit = 15): Observable<IPost[]> => {
		return this.postService.getEventsBySearch(searchStr, page, limit);
	};
	eventChanged = (selected: IPost | undefined): void => {
		this.eventSelected = selected;
	};

	deedbudsSearch = (searchStr: string, page: number, limit = 15): Observable<IProfileMinimal[]> => {
		return this.userService.searchPostCreateDeedbuds(searchStr, page, limit);
	};
	deedbudsChanged = (selected: IProfileMinimal[]): void => {
		this.deedbudsSelected = selected;
	};

	toggled(event: boolean, refNumber: number) {
		if (this.userSelectors) {
			for (const e of this.userSelectors) {
				if (e.refNumber && e.refNumber == refNumber) {
					if (!event) {
						e.clearInput();
					} else {
						e.focusInput();
					}
				}
			}
		}
	}
	selectColor(color: string) {
		this.newPostObj.bgcolor = color;
	}
	hasErrorColumn(column: string): boolean {
		return this.errorColumns.includes(column);
	}
	checkRequiredColumns() {
		this.errorColumns = [];
		if (this.newPostObj.title.length < 3) {
			this.errorColumns.push('title');
		}
		if (this.newPostObj.text.length < 3) {
			this.errorColumns.push('text');
		}
	}

	async addPost() {
		this.is_loading = true;

		this.submitted = true;
		this.checkRequiredColumns();
		if (this.errorColumns.length > 0) {
			this.is_loading = false;
			return;
		}

		if (this.locationObject != null) {
			let location = await this.locationService.getLocationId(this.locationObject);
			this.newPostObj.location_id = location.location_id;
		}

		if (this.eventSelected) {
			this.newPostObj.linked_post_id = this.eventSelected.post_id;
		} else {
			this.newPostObj.linked_post_id = 0;
		}
		if (this.deedbudsSelected) {
			let marked = '';
			for (const i of this.deedbudsSelected) {
				marked += i.user_id + ',';
			}
			this.newPostObj.deedbuds = marked;
		} else {
			this.newPostObj.deedbuds = '';
		}

		if (this.postId > 0) {
			this.postService.updatePost(this.postId, this.newPostObj).subscribe(
				(value) => {
					this.closeModal();
					this.router.navigate(['/profile'], {
						queryParams: { postId: this.postId, mod: new Date().getTime() },
					});
				},
				(error) => {},
				() => {
					this.is_loading = false;
				},
			);
		} else {
			this.postService.createPost(this.newPostObj).subscribe(
				(value) => {
					this.closeModal();
					this.router.navigate(['/profile'], { queryParams: { postId: value.post_id } });
				},
				(error) => {},
				() => {
					this.is_loading = false;
				},
			);
		}
	}

	locationChanged = (newLocationObject: google.maps.places.PlaceResult | null) => {
		this.locationObject = newLocationObject;
	};
}
