<div class="row mt-3">
	<div class="col-4">
		<input
			type="text"
			class="form-control"
			[(ngModel)]="company.user_first_name"
			[placeholder]="'profile.editCompany.name' | translate"
		/>
	</div>
	<div class="col-4">
		<input
			type="text"
			class="form-control"
			[(ngModel)]="company.company.website"
			[placeholder]="'profile.editCompany.website' | translate"
		/>
	</div>
	<div class="col-4">
		<input
			type="text"
			class="form-control"
			[(ngModel)]="company.company.gpeUrl"
			[placeholder]="'profile.editCompany.gpeurl' | translate"
		/>
	</div>
</div>
<div class="row mt-2">
	<div class="col-4">
		<ng-select
			[items]="industries"
			[clearable]="false"
			[(ngModel)]="company.company.industry"
			[placeholder]="'profile.editCompany.industry' | translate"
			bindLabel="name"
			bindValue="id"
		>
		</ng-select>
	</div>
	<div class="col-4">
		<ng-select
			[items]="companySizes"
			[clearable]="false"
			[(ngModel)]="company.company.companySize"
			[placeholder]="'profile.editCompany.companySize' | translate"
			bindLabel="name"
			bindValue="id"
		>
		</ng-select>
	</div>
	<div class="col-4">
		<ng-select
			[items]="companyTypes"
			[clearable]="false"
			[(ngModel)]="company.company.companyType"
			[placeholder]="'profile.editCompany.companyType' | translate"
			bindLabel="name"
			bindValue="id"
		>
		</ng-select>
	</div>
</div>

<div class="row mt-3">
	<div class="col-md-6">
		<h2 class="titleh2" translate>profile.editProfile.yourSlogen</h2>

		<textarea
			type="text"
			rows="6"
			class="textbox form-control"
			[(ngModel)]="company.data.user_slogen"
			[placeholder]="'whatever'"
		></textarea>
	</div>
	<div class="col-md-6">
		<h2 class="titleh2" translate>profile.editProfile.yourStory</h2>

		<textarea
			type="text"
			rows="6"
			class="textbox form-control"
			[(ngModel)]="company.data.user_about"
			[placeholder]="'whatever'"
		></textarea>
	</div>
</div>

<ng-container *ngIf="!interestsLoading && interestsTree">
	<ng-container *ngFor="let interest of interestsTree">
		<div class="row mt-3 mb-1">
			<div class="col">
				<h2 class="titleh2">{{ interest.name }}</h2>
			</div>
		</div>

		<div *ngIf="!interest || interest.items.length == 0">
			<div class="empty-list">
				<div class="empty-list-title">There are no selectable items.</div>
			</div>
		</div>
		<ng-container *ngIf="interest.items">
			<ng-container *ngFor="let item of interest.items">
				<div
					class="btn mr-1 post-button-cancel"
					*ngIf="!interestsSelected.includes(item.id)"
					(click)="addInterest(item.id)"
				>
					<img [src]="item.icon_black" /> {{ item.name }}
				</div>

				<div
					class="btn mr-1 post-button-add"
					*ngIf="interestsSelected.includes(item.id)"
					(click)="removeInterest(item.id)"
				>
					<img [src]="item.icon_white" /> {{ item.name }}
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>
<div *ngIf="interestsLoading" class="text-center mt-2">
	<div class="loading-animation-container">
		<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
	</div>
</div>
