import { Component, Input, OnInit } from '@angular/core';

import { IPost, PostType } from '../../../../../core/models/IPost';
import { PostService } from '../../../../../core/services/post.service';
import { UserService } from '../../../../../core/services/user.service';

@Component({
	selector: 'gpe-post-general-frame',
	templateUrl: './post-general-post.component.html',
	styleUrls: ['./post-general-post.component.scss'],
})
export class PostGeneralPostComponent implements OnInit {
	PostType = PostType;
	myUserId = 0;

	@Input()
	post: IPost | undefined;

	@Input()
	showCommentsClicked!: () => void;

	@Input()
	showShareClicked!: () => void;

	@Input() reportPost!: () => void;
	@Input() reportUser!: () => void;
	@Input() hidePost!: () => void;
	@Input() blockUser!: () => void;
	@Input() editPost!: () => void;
	@Input() deletePost!: () => void;
	@Input() navigateToPost!: () => void;

	constructor(private postService: PostService, private userService: UserService) {}

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();
	}

	likePost = (postId: number, nextState: boolean) => {
		if (this.post) {
			this.post.post_liked = nextState;
			this.postService.likePost(postId, nextState).subscribe(
				(value) => {
					if (this.post) {
						if (nextState) {
							this.post.post_like_count++;
							this.post.top_user_liked?.push({
								followed_date: '',
								is_followed: false,
								user_avatar: '',
								user_date_added: '',
								user_email: '',
								user_first_name: '',
								user_last_name: '',
								user_sex: undefined,
								user_id: parseInt(this.myUserId.toString()),
								user_type: 1,
							});
						} else if (this.post.post_like_count > 0) {
							this.post.post_like_count--;
							if (this.post.top_user_liked) {
								this.post.top_user_liked = this.post.top_user_liked.filter((obj) => {
									return obj.user_id != this.myUserId;
								});
							}
						}
					}
				},
				(error) => {
					if (this.post) {
						this.post.post_liked = !nextState;
					}
				},
			);
		}
	};
}
