<div style="width: 500px; height: 250px; text-align: center" appImageDrag (files)="filesDropped($event)">
	<span style="font-size: 25px">Drop your files here</span>

	<div class="text-wrapper">
		<div class="centered">
			<input #fileInput type="file" name="file" id="file" (change)="onFileChange($event)" multiple />
			<label for="file" #fileInputBtn><span class="textLink">Select your file</span> or Drop it here!</label>
		</div>
	</div>
</div>
