<div class="main-box">
	<div class="row title-box">
		<div class="col p-0 title">Just going on</div>
		<div class="col p-0 more-btn" [routerLink]="['/calendar']">See all</div>
	</div>

	<div class="event-container cursor-pointer" *ngFor="let event of upcomingEvents">
		<span [routerLink]="['/home/' + event.post_id]">
			<div class="event-image" [style.background]="getBackground(event)">
				<div class="event-profile">
					<div
						class="event-profile-img"
						[style.background]="'url(' + event.user_data_avatar + ')'"
						[style]="'background-size: cover;'"
					></div>
					<div class="event-profile-name">{{ event.user_first_name }} {{ event.user_last_name }}</div>
				</div>
			</div>
			<div class="event-text-line">
				<div class="event-text-line-left">
					<div class="event-text">
						{{ event.post_event_title }}
					</div>
					<div class="event-date">
						{{ event?.post_event_date_from_epoch | date: 'y.MM.d' | lowercase
						}}<ng-container *ngIf="event?.location_title && event.location_title.length > 0"
							>, {{ event?.location_title }}</ng-container
						>
					</div>
				</div>
				<div class="event-text-line-right">
					{{ getDayLeft(event) }}
				</div>
			</div>
		</span>
	</div>

	<div class="row title-box">
		<div class="col p-0 title">Upcoming events</div>
		<div class="col p-0 more-btn" [routerLink]="['/calendar']">More</div>
	</div>

	<div class="event-container cursor-pointer" *ngFor="let event of topEvents">
		<span [routerLink]="['/home/' + event.post_id]">
			<div class="event-image" [style.background]="getBackground(event)">
				<div class="event-profile">
					<div
						class="event-profile-img"
						[style.background]="'url(' + event.user_data_avatar + ')'"
						[style]="'background-size: cover;'"
					></div>
					<div class="event-profile-name">{{ event.user_first_name }} {{ event.user_last_name }}</div>
				</div>
			</div>
			<div class="event-text-line event-text-line-grey">
				<div class="event-text-line-left">
					<div class="event-text">
						{{ event.post_event_title }}
					</div>
					<div class="event-date">
						{{ event?.post_event_date_from_epoch | date: 'y.MM.d' | lowercase
						}}<ng-container *ngIf="event?.location_title && event.location_title.length > 0"
							>, {{ event?.location_title }}</ng-container
						>
					</div>
				</div>
				<div class="event-text-line-right">
					{{ getDayLeft(event) }}
				</div>
			</div>
		</span>
	</div>
</div>
