<div *ngIf="!selectedRoom; else createRoom" class="edit-list-wrapper">
	<div class="edit-room-card" *ngFor="let room of myRooms; let last = last" [ngClass]="{ 'border-bottom': !last }">
		<gpe-profile-picture [width]="35" [pictureUrl]="room.coverUrl"></gpe-profile-picture>
		<div class="room-name">{{ room.name }}</div>
		<div class="action-wrapper" (click)="editRoom(room)">
			<img src="/assets/icons/edit-room-green.svg" />
			<span class="action-text">Edit room</span>
		</div>
		<div class="action-wrapper" (click)="deleteRoom(room.id)">
			<img src="/assets/icons/trash-red.svg" />
			<span class="action-text">Delete</span>
		</div>
	</div>
</div>
<ng-template #createRoom>
	<gpe-create-room [inputRoomData]="selectedRoom" (cancelEditing)="selectedRoom = undefined"></gpe-create-room>
</ng-template>
<ng-template #confirmModal let-modal>
	<gpe-confirm-modal
		[modalRef]="modal"
		[confimType]="ConfirmType.DELETE"
		[description]="'Are you sure you want to delete the room?'"
		[confirmText]="'Delete'"
		(confirm)="confirmDelete()"
	></gpe-confirm-modal>
</ng-template>
