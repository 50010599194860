export const environment = {
	production: false,
	title: 'Dev Environment Heading',
	apiURL: 'https://gpe-api-dev.web2.s2.exagent.group/api',
	hostUrl: 'https://www.gpe-web-app-dev.exagent.group/',
	apiKey: 'apikey',
	firebase: {
		apiKey: 'AIzaSyAJa95ZcT8rabbVP1mc2BQGBk0AIOiJZb4',
		authDomain: 'gpe-app-f0bec.firebaseapp.com',
		projectId: 'gpe-app-f0bec',
		storageBucket: 'gpe-app-f0bec.appspot.com',
		messagingSenderId: '195970246822',
		appId: '1:195970246822:web:04de2dcf60ba2fc9b56c67',
		measurementId: 'G-2GZGKM7QYE',
	},
	firestoreChat: {
		collectionKeys: {
			chatrooms: 'ChatRoomsV2_DEV',
			users: 'Users_DEV',
			rooms: 'GroupRooms_DEV',
			roomUsers: 'GroupRoomUsers_DEV',
			messages: 'Messages',
		},
		propertyKeys: {
			chatRoomUserIds: 'userIds',
			chatMessageServerTimeStamp: 'serverTimestampInSec',
			chatRoomLastMessageServerTimeStamp: 'lastMessage_timeStampInSec',
			roomName: 'name',
			roomIsPrivate: 'isPrivate',
			roomNumberOfUsersInRoom: 'userIdsLength',
			roomInvites: 'invitedUserIds',
		},
		isOnCustomServer: true,
	},
	googleLocation: {
		apiKey: 'AIzaSyDKshF1vqmLhzpaf8JkFjmwa244s3m40Hw',
	},
};
