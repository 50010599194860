import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmType } from 'src/app/shared/components/modals/confirm-modal/confirm-modal.component';

import { RoomViewModel } from '../../../../../modules/rooms/models/room.view-model';
import { RoomService } from '../../../../../modules/rooms/services/room.service';

@Component({
	selector: 'gpe-edit-room-list',
	templateUrl: './edit-room-list.component.html',
	styleUrls: ['./edit-room-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditRoomListComponent {
	@Input() profilePic: string | undefined;
	@Input() myRooms: RoomViewModel[] | undefined | null;

	@Input() selectedRoom: RoomViewModel | undefined;
	@ViewChild('confirmModal', { read: TemplateRef }) confirmModal!: TemplateRef<any>;

	ConfirmType = ConfirmType;

	private roomToBeDeletetId = '';

	constructor(private modalService: NgbModal, private roomService: RoomService) {}

	editRoom(room: RoomViewModel): void {
		this.selectedRoom = room;
	}

	public deleteRoom(roomId: string): void {
		this.roomToBeDeletetId = roomId;

		this.modalService.open(this.confirmModal, {
			backdropClass: 'smodal-backdrop-style',
			size: 's',
			centered: true,
			scrollable: false,
			modalDialogClass: 'smodal-class',
			beforeDismiss: () => {
				return false;
			},
		});
	}

	public async confirmDelete(): Promise<void> {
		try {
			await this.roomService.deleteRoom(this.roomToBeDeletetId);
		} catch {}
	}
}
