import { Directive, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
	selector: '[gpeLifeCycle]',
})
export class LifeCycleDirective implements OnInit, OnDestroy {
	@Output() initEvent: EventEmitter<any> = new EventEmitter();
	@Output() destroyEvent: EventEmitter<any> = new EventEmitter();

	ngOnInit() {
		this.initEvent.emit();
	}

	ngOnDestroy(): void {
		this.destroyEvent.emit();
	}
}
