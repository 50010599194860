import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'gpe-main-layout',
	styleUrls: ['./main-layout.component.scss'],
	templateUrl: './main-layout.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent {
	public actualBackground = '/assets/images/default-background.svg';

	constructor(public router: Router) {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (event.url.startsWith('/settings')) {
					this.currentSidebar = SideBarTypeEnum.Settings;
				} else if (event.url.startsWith('/rooms')) {
					this.currentSidebar = event.url.includes('?id')
						? SideBarTypeEnum.RoomsOnline
						: SideBarTypeEnum.Rooms;
				} else {
					this.currentSidebar = SideBarTypeEnum.UpcomingEvents;
				}
				if (event.url.startsWith('/home')) {
					this.keepLeftSideBar = true;
				} else {
					this.keepLeftSideBar = false;
				}
			}
		});
	}

	SideBarType = SideBarTypeEnum;
	currentSidebar: SideBarTypeEnum = SideBarTypeEnum.UpcomingEvents;
	keepLeftSideBar = false;
}

enum SideBarTypeEnum {
	UpcomingEvents = '0',
	Settings = '1',
	Rooms = '2',
	RoomsOnline = '3',
}
