import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ComplexTextInputComponent } from '../../complex-text-input/complex-text-input.component';

@Component({
	selector: 'gpe-message-input-field',
	templateUrl: './message-input-field.component.html',
	styleUrls: ['./message-input-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageInputFieldComponent {
	@Input() placeholder = 'message';
	@Input() isComment = false;
	@Output()
	private newMessageEvent = new EventEmitter<string>();

	public message = '';

	@ViewChild(ComplexTextInputComponent) replyText: ComplexTextInputComponent | undefined;

	sendMessage(event: Event): void {
		let msg = this.isComment ? this.replyText?.getClearStr() : this.message;

		event.preventDefault();
		if (msg && msg[msg.length - 1] === '\n') {
			msg = msg.slice(0, msg.length - 1);
		}
		if (msg) {
			this.newMessageEvent.emit(msg);
			this.replyText?.loadDefaultString('', []);
		}
		this.message = '';
	}
}
