import { IInterestType } from './../models/IInterests';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { map } from 'rxjs/operators';
import { IInterestsBatchGet, IInterestsTree, IInterestsTreeGet } from '../models/IInterests';

@Injectable({
	providedIn: 'root',
})
export class InterestsService {
	private apiUrl = environment.apiURL;
	constructor(private authService: AuthService, private http: HttpClient) {}

	saveInterestsBatchToUserId(userId: number, interestIds: Number[]): Observable<Boolean> {
		let url = `${this.apiUrl}/interests/to_user`;

		const data = new FormData();
		data.append('company_id', userId.toString());
		data.append('interest_ids', interestIds.join(','));

		return this.http.post<ApiResponse<Boolean>>(url, data).pipe(
			map((res) => {
				return res.success == 1;
			}),
		);
	}
	saveInterestsBatch(interestIds: Number[]): Observable<Boolean> {
		let url = `${this.apiUrl}/interests/user`;

		const data = new FormData();
		data.append('interest_ids', interestIds.join(','));

		return this.http.post<ApiResponse<Boolean>>(url, data).pipe(
			map((res) => {
				return res.success == 1;
			}),
		);
	}
	getInterestsBatch(userIds: Number[]): Observable<IInterestsBatchGet> {
		let ids = userIds.join(',');
		let url = `${this.apiUrl}/interests/batch_interests?user_ids=${ids}`;

		return this.http.get<ApiResponse<IInterestsBatchGet>>(url).pipe(
			map((res) => {
				return res.data;
			}),
		);
	}

	getInterestsTree(type: IInterestType): Observable<IInterestsTree[]> {
		let url = `${this.apiUrl}/interests/list?type=${type == IInterestType.COMPANY ? 'company' : 'user'}`;

		return this.http.get<ApiResponse<IInterestsTreeGet>>(url).pipe(
			map((res) => {
				return res.data.interests;
			}),
		);
	}
}
