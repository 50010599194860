<div class="multi-select-header">
	<span class="title">{{ title }}</span>
	<form [formGroup]="searchForm" class="search">
		<input formControlName="searchTerm" class="search-input form-control" placeholder="Search" />
		<img src="assets/icons/search-green.svg" />
	</form>
</div>
<div class="selection-container">
	<div class="selection-item" *ngFor="let item of items; index as i">
		<gpe-profile-picture
			class="profile-pic"
			*ngIf="item.pictureUrl"
			[pictureUrl]="item.pictureUrl"
			[width]="35"
		></gpe-profile-picture>
		<span class="selection-item-title">{{ item.title }}</span>
		<input
			class="form-check-input selection-checkbox"
			type="checkbox"
			[id]="item.id"
			(click)="selectItem(item.id)"
		/>
	</div>
</div>
