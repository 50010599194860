import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { CreateMedia } from '../../shared/models/CreateMedia';

import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class BlobUploadService {
	private apiUrl = environment.apiURL;
	constructor(private authService: AuthService, private http: HttpClient) {}

	initialMaxBlockSize = 1 * 1024 * 1024; //Each file will be split in 1 Mb.
	maxBlockSize = this.initialMaxBlockSize;
	numberOfBlocks = 1;
	selectedFile: File | null = null;
	currentFilePointer = 0;
	totalBytesRemaining = 0;
	blockIds = [];
	blockIdPrefix = 'block-';
	submitUri: string | null = null;
	bytesUploaded = 0;
	createMedia: CreateMedia | null = null;

	//Read the file and find out how many blocks we would need to split it.
	handleFileSelect(baseUrl: string, createMediaFile: CreateMedia): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			// maxBlockSize = 1024 * 1024;
			this.currentFilePointer = 0;
			this.totalBytesRemaining = 0;

			this.maxBlockSize = this.initialMaxBlockSize;
			this.numberOfBlocks = 1;
			this.selectedFile = null;
			this.currentFilePointer = 0;
			this.totalBytesRemaining = 0;
			this.blockIds = [];
			this.blockIdPrefix = 'block-';
			this.submitUri = null;
			this.bytesUploaded = 0;

			this.createMedia = createMediaFile;
			this.createMedia.file.process = 0;
			const file = createMediaFile.file.file;
			this.selectedFile = file;
			if (file) {
				const fileSize = file.size;
				if (fileSize < this.maxBlockSize) {
					this.maxBlockSize = fileSize;
				}
				this.totalBytesRemaining = fileSize;
				if (fileSize % this.maxBlockSize == 0) {
					this.numberOfBlocks = fileSize / this.maxBlockSize;
				} else {
					this.numberOfBlocks = Math.ceil(fileSize / this.maxBlockSize);
				}

				this.submitUri = baseUrl;

				resolve(this.uploadFileInBlocksPublic());
			} else {
				resolve(false);
			}
		});
	}

	makeReaderResolverLoad(resolve: (value: boolean | PromiseLike<boolean>) => void): FileReader {
		const reader = new FileReader();

		reader.onloadend = (evt) => {
			if (evt.target && evt.target.readyState == FileReader.DONE) {
				// DONE == 2
				const uri = this.submitUri + '&comp=block&blockid=' + this.blockIds[this.blockIds.length - 1];
				if (evt.target && evt.target.result) {
					// @ts-ignore
					const requestData = new Uint8Array(evt.target.result);

					this.http
						.put<any>(uri, evt.target.result, {
							headers: {
								'content-type': 'application/octet-stream',
								'x-ms-date': '2012-02-12',
								'x-ms-blob-content-type': 'BlockBlob',
								//'Content-Length': requestData.length.toString()
							},
							observe: 'response',
							responseType: 'text' as 'json',
						})
						.subscribe(
							(value) => {
								this.bytesUploaded += requestData.length;

								// @ts-ignore
								const percentComplete = (this.bytesUploaded / this.selectedFile.size) * 100;
								if (this.createMedia) {
									if (!this.createMedia.file.process) {
										this.createMedia.file.process = 0;
									}

									this.createMedia.file.process = percentComplete;
								}

								resolve(this._uploadFileInBlocks());
							},
							(error) => {
								resolve(false);
							},
							() => {},
						);
				}
			}
		};
		return reader;
	}

	uploadFileInBlocksPublic(): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
			await this._uploadFileInBlocks();

			setTimeout(() => {
				resolve(this.commitBlockList());
			}, 1500);
		});
	}

	private _uploadFileInBlocks(): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
			if (this.totalBytesRemaining > 0) {
				if (this.selectedFile) {
					// @ts-ignore
					const fileContent = this.selectedFile.slice(
						this.currentFilePointer,
						this.currentFilePointer + this.maxBlockSize,
					);
					const blockId = this.blockIdPrefix + this.pad(this.blockIds.length, 6);

					// @ts-ignore
					this.blockIds.push(btoa(blockId));

					this.currentFilePointer += this.maxBlockSize;
					this.totalBytesRemaining -= this.maxBlockSize;
					if (this.totalBytesRemaining < this.maxBlockSize) {
						this.maxBlockSize = this.totalBytesRemaining;
					}

					await new Promise((resolve1) => {
						this.makeReaderResolverLoad(resolve1).readAsArrayBuffer(fileContent);
					});

					resolve(true);
				}
			} else {
				resolve(true);
			}
		});
	}

	commitBlockList(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const uri = this.submitUri + '&comp=blocklist';
			let requestBody = '<' + '?xml version="1.0" encoding="utf-8"?' + '><BlockList>';
			for (let i = 0; i < this.blockIds.length; i++) {
				requestBody += '<Latest>' + this.blockIds[i] + '</Latest>';
			}
			requestBody += '</BlockList>';

			if (this.selectedFile) {
				this.http
					.put(uri, requestBody, {
						headers: {
							// @ts-ignore
							'x-ms-blob-content-type': this.selectedFile.type,
						},
						observe: 'response',
						responseType: 'text' as 'json',
					})
					.subscribe(
						(value) => {
							resolve(value.status == 201);
						},
						(error) => {
							resolve(false);
						},
					);
			} else {
				resolve(false);
			}
		});
	}

	pad(number: number, length: number) {
		let str = '' + number;
		while (str.length < length) {
			str = '0' + str;
		}
		return str;
	}

	bytesToSize(bytes: number) {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 Byte';
		const i = Math.floor(Math.log(bytes) / Math.log(1024));
		return Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + ' ' + sizes[i];
	}
}
