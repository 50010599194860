import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'gpe-post-share',
	templateUrl: './post-share.component.html',
	styleUrls: ['./post-share.component.scss'],
})
export class PostShareComponent implements OnInit {
	constructor() {}

	@Input()
	url: string = window.location.href;

	ngOnInit(): void {}
}
