import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { INotification, INotificationList } from '../models/INotification';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	private apiUrl = environment.apiURL;
	private limitPerPage = 10;

	private notifications$ = new BehaviorSubject<INotification[]>([]);

	constructor(private http: HttpClient) {}

	getLimitPerPage(): number {
		return this.limitPerPage;
	}

	getNotifications(): Observable<INotification[]> {
		return this.notifications$.asObservable();
	}

	loadNotifications(page: number): Observable<void> {
		if (page === 1) this.notifications$.next([]);
		return this.http
			.get<ApiResponse<INotificationList>>(`${this.apiUrl}/user/notify?page=${page}&limit=${this.limitPerPage}`)
			.pipe(
				map((res) => {
					return res.data.notifies;
				}),
				tap((noties) => this.notifications$.next(this.notifications$.value.concat(noties))),
				map(() => {
					return;
				}),
			);
	}

	reactForNotification(notificationId: number, buttonId: number): Observable<ApiResponse<boolean>> {
		const body = new FormData();
		body.append('button_id', buttonId.toString());

		return this.http.put<ApiResponse<boolean>>(`${this.apiUrl}/user/notify/${notificationId}`, body).pipe(
			map((res) => {
				return res;
			}),
		);
	}
}
