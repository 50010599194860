import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';

import { ApiService } from '../api/api.service';

@Injectable({
	providedIn: 'root',
})
export class MediaService {
	constructor(private apiService: ApiService) {}

	public getMediaUrl(mediaId: number): Observable<string> {
		return this.apiService
			.get<ApiResponse<{ media: { uploaded_url: string } }>>(`media/${mediaId}`)
			.pipe(map((resp) => resp.data.media.uploaded_url));
	}
}
