<span class="title">{{ dropdownInfo.title }}</span>

<div ngbDropdown class="d-inline-block">
	<button class="dropdown-container" id="dropdownBasic1" ngbDropdownToggle>
		<span class="dropdown-text">
			{{ selectedItem?.displayValue }}
		</span>
		<img src="assets/icons/arrow-down-green.svg" />
	</button>
	<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
		<button ngbDropdownItem *ngFor="let item of dropdownItems; index as i" (click)="itemSelected(i)">
			{{ item.displayValue }}
		</button>
	</div>
</div>
