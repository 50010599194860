import { gpeApproveIcon } from './result/approve';
import { gpeAwardIcon } from './result/award';
import { gpeEventCantGoIcon } from './result/event-cant-go';
import { gpeEventGoingIcon } from './result/event-going';
import { gpeEventInterestedIcon } from './result/event-interested';
import { gpeFilledCommentIcon } from './result/filled_comment';
import { gpeFilledShareIcon } from './result/filled_share';
import { gpeMoreIcon } from './result/more';
import { gpeReactionIcon } from './result/reaction';

export const icons = [
	gpeAwardIcon,
	gpeFilledCommentIcon,
	gpeFilledShareIcon,
	gpeMoreIcon,
	gpeReactionIcon,
	gpeApproveIcon,
	gpeEventGoingIcon,
	gpeEventCantGoIcon,
	gpeEventInterestedIcon,
];
