import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { IFollowBatch, IFollowBatchList } from '../models/IFollowBatch';
import { IProfileMinimal } from '../models/IProfileMinimal';
import { IUserBase } from '../models/IUserBase';
import { IUserMinimal } from '../models/IUserMinimal';

import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class UserFollowService {
	private apiUrl = environment.apiURL;
	constructor(private authService: AuthService, private http: HttpClient) {}

	followUser(toUserId: number, nextState: boolean): Observable<boolean> {
		const url = `${this.apiUrl}/user/follow/${toUserId}`;

		if (nextState) {
			return this.http.post<ApiResponse<null>>(url, {}).pipe(
				map((res) => {
					return res.success == 1;
				}),
			);
		} else {
			return this.http.delete<ApiResponse<null>>(url).pipe(
				map((res) => {
					return res.success == 1;
				}),
			);
		}
	}

	getIsFollowedBatch(userIds: number[]): Observable<IFollowBatch[]> {
		const ids = userIds.join(',');
		const url = `${this.apiUrl}/user/follow_batch?user_ids=${ids}`;

		return this.http.get<ApiResponse<IFollowBatchList>>(url).pipe(
			map((res) => {
				return res.data.follows;
			}),
		);
	}

	addFollowedData(users: IUserBase[]): Observable<IUserBase[]> {
		return new Observable<IUserBase[]>((subscriber) => {
			const ids = [];
			for (const va of users) {
				ids.push(va.user_id);
			}
			if (ids.length > 0) {
				//get user followed pairs
				this.getIsFollowedBatch(ids).subscribe(
					(value1) => {
						for (const user of users) {
							for (const followPair of value1) {
								if (user.user_id === followPair.id) {
									user.is_followed = followPair.is_followed;
								}
							}
						}

						subscriber.next(users);
					},
					(error) => {},
					() => {
						subscriber.complete();
					},
				);
			} else {
				subscriber.next(users);
				subscriber.complete();
			}
		});
	}
}
