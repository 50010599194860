<a *ngIf="modalRef" (click)="closeModal()" class="modal-close-button">Bezár</a>
<h1 class="title">Report problem</h1>
<div class="text mt-3">
	You have a problem while using our app? You've noticed somebody seriously violating our policies? Submit a ticket to
	our team, and we will help you!
	<br />
	<input type="text" class="form-control mt-5" placeholder="Subject" [(ngModel)]="subject" />
	<input
		type="text"
		class="form-control mt-2"
		placeholder="Where did you experience this issue?"
		[(ngModel)]="where"
	/>
	<textarea class="form-control mt-2" placeholder="Description" rows="6" [(ngModel)]="description"></textarea>

	<span class="btn post-button-add cursor-pointer mt-2" (click)="send()">Send</span>
</div>
