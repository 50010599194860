import { not } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import Swal from 'sweetalert2';

import { INotification } from '../../../../models/INotification';
import { NotificationsService } from '../../../../services/notifications.service';

@Component({
	selector: 'gpe-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
	constructor(private router: Router, private notificationsService: NotificationsService) {}

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	notifications$ = this.notificationsService.getNotifications();

	actualPage = 1;
	limitPerPage = 15;
	loading = false;

	ngOnInit(): void {
		this.loadNotifications();
	}

	loadNotifications(page = 0): void {
		if (page == 0) {
			page = this.actualPage;
		}
		this.limitPerPage = this.notificationsService.getLimitPerPage();
		// this.loading = true;
		this.notificationsService.loadNotifications(page).subscribe();
	}

	changeActualPage(modify: number): void {
		this.actualPage += modify;
		this.loadNotifications();
	}

	reactNotify(notificationId: number, buttonId: number): void {
		this.notificationsService.reactForNotification(notificationId, buttonId).subscribe((value) => {
			if (value.success) {
				this.loadNotifications();
			} else {
				Swal.fire({
					icon: 'error',
					title: 'TODO: FAILED TO RESPONSE',
				});
			}
		});
	}

	navigateToUser(userId: number): void {
		this.router.navigate(['/profile/' + userId]);
	}

	notoficationAction(notification: INotification): void {
		switch (notification.notify_type.id) {
			case 1: // Like your Post
			case 2: // Comment on your post
			case 4: // comment to yout comment
			case 10: // Marked in comment
			case 13: // Liked your comment
				this.navigateToPost(notification.post.id, true);
				break;
			case 3: // Marked you in post
			case 5: // Join event
			case 6: // Accept join event
			case 7: // Invite event
			case 8: // Marked organizer event
			case 9: // Coming event
			case 11: // Shared post
			case 16: // Subscribers post
				this.navigateToPost(notification.post.id);
				break;
			case 12: // Followed you
			case 15: // Ban lifted
				this.navigateToUser(notification.sender.id);
				break;
			case 14: // Banned you
			case 17: // Earn badge
			default:
				break;
		}
	}

	private navigateToPost(postId: number, isCommentSection = false): void {
		this.router.navigate([`home/${postId}`], {
			queryParams: { openComments: isCommentSection ? 'TRUE' : 'FALSE' },
		});
	}
}
