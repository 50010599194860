<div [class]="isComment ? 'comment-wrap' : 'message-wrap'">
	<gpe-complex-text-input *ngIf="isComment" class="message-input" [contentId]="0"></gpe-complex-text-input>
	<textarea
		*ngIf="!isComment"
		[(ngModel)]="message"
		type="text"
		class="message-input"
		[rows]="1"
		(keydown.enter)="sendMessage($event)"
		[placeholder]="placeholder"
		onInput="this.parentNode.dataset.replicatedValue = this.value"
	></textarea>
	<div class="message-button" (click)="sendMessage($event)">
		<img src="assets/icons/send-green.svg" class="icon" />
	</div>
</div>
